import { actions } from '../app/store'
import { Collections, unsubscribeList } from '../config/constants'
import { db } from '../config/firebase'
import { isMissingPermissionError, Sort } from '../config/utils'

import { updationData } from './common'
import { isLoggedIn } from './user'

const UpdateServiceStatusHelper = async (
  departmentId,
  serviceId,
  batch,
  subServices,
  active
) => {
  try {
    const serviceRef = db
      .collection(Collections.DEFAULTDEPARTMENTS)
      .doc(departmentId)
      .collection(Collections.SERVICES)
      .doc(serviceId)
    batch.update(serviceRef, { active, ...updationData() })
    if (subServices) {
      const subServiceSnapshot = await serviceRef
        .collection(Collections.SERVICES)
        .get()
      for (const subService of subServiceSnapshot.docs) {
        const subServiceRef = serviceRef
          .collection(Collections.SERVICES)
          .doc(subService.id)
        batch.update(subServiceRef, { active, ...updationData() })
      }
    }
    return true
  } catch (error) {
    console.log({ error })
    console.log(error)
    return false
  }
}

const GetServices = async (
  departmentRef,
  serviceSnapshot,
  getSubServices,
  departmentId,
  orderBy
) => {
  const services = []
  try {
    for (const serviceDoc of serviceSnapshot.docs) {
      const serviceId = serviceDoc.id
      const service = { id: serviceId, ...serviceDoc.data() }

      if (getSubServices && service.services) {
        service.services = []

        const subServiceSnapshot = await departmentRef
          .doc(departmentId)
          .collection(Collections.SERVICES)
          .doc(serviceId)
          .collection(Collections.SERVICES)
          .orderBy(orderBy)
          .get()
        for (const subServiceDoc of subServiceSnapshot.docs) {
          const subServiceId = subServiceDoc.id
          const subService = { id: subServiceId, ...subServiceDoc.data() }
          service.services.push(subService)
        }
      }
      services.push(service)
    }
  } catch (error) {
    if (!isMissingPermissionError(error)) {
      console.log({ error })
      console.log(error)
    }
  }
  return services
}

export const GetDepartments = async (
  orderBy = 'index',
  getServices = false,
  getSubServices = false
) => {
  let departments = []
  try {
    if (isLoggedIn()) {
      const departmentRef = db.collection(Collections.DEFAULTDEPARTMENTS)
      const departmentSnapshot = await departmentRef.orderBy(orderBy).get()
      if (departmentSnapshot) {
        for (const departmentDoc of departmentSnapshot.docs) {
          const departmentId = departmentDoc.id
          let department = { id: departmentId, ...departmentDoc.data() }

          if (getServices && department.services) {
            const serviceSnapshot = await departmentRef
              .doc(departmentId)
              .collection(Collections.SERVICES)
              .orderBy(orderBy)
              .get()
            const services = await GetServices(
              departmentRef,
              serviceSnapshot,
              getSubServices,
              departmentId,
              orderBy
            )
            department = { ...department, services }
          }
          departments.push(department)
        }
      }
    }
  } catch (error) {
    if (!isMissingPermissionError(error)) {
      console.log({ error })
      console.log(error)
    }
  }
  return departments
}

export const FetchDepartments = async (departments, dispatch) => {
  if (departments?.length) return

  const departmentsList = await GetDepartments('index', true, true)
  dispatch(actions.setDepartments(departmentsList))
}

export const UpdateServiceStatus = async (departmentId, serviceId, active) => {
  try {
    const batch = db.batch()

    const serviceRef = db
      .collection(Collections.DEFAULTDEPARTMENTS)
      .doc(departmentId)
      .collection(Collections.SERVICES)
      .doc(serviceId)
    const servicesDoc = await serviceRef.get()
    batch.update(serviceRef, { active, ...updationData() })
    const subServices = servicesDoc.data().services

    await UpdateServiceStatusHelper(
      departmentId,
      servicesDoc.id,
      batch,
      subServices,
      active
    )

    await batch.commit()
    return true
  } catch (error) {
    console.log({ error })
    console.log(error)
    return false
  }
}

export const UpdateSubServiceStatus = async (
  departmentId,
  serviceId,
  subServiceId,
  active
) => {
  try {
    await db
      .collection(Collections.DEFAULTDEPARTMENTS)
      .doc(departmentId)
      .collection(Collections.SERVICES)
      .doc(serviceId)
      .collection(Collections.SERVICES)
      .doc(subServiceId)
      .update({ active, ...updationData() })
    return true
  } catch (error) {
    console.log({ error })
    console.log(error)
    return false
  }
}

// Adding multiple listeners for getting departments > services > sub services
// -----------------START---------------------------
const AddServiceListener = ({ dispatch, docId, serviceId }) => {
  try {
    const key = `${docId}_${serviceId ?? ''}`
    if (unsubscribeList[key] || !isLoggedIn()) return

    let collectionRef = db.collection(Collections.DEFAULTDEPARTMENTS)
    if (serviceId)
      collectionRef = collectionRef
        .doc(serviceId)
        .collection(Collections.SERVICES)

    const unsub = collectionRef
      .doc(docId)
      .collection(Collections.SERVICES)
      .onSnapshot(serviceSnapshot => {
        let services = []
        for (const service of serviceSnapshot.docs) {
          services.push({ id: service.id, ...service.data() })
        }
        services = Sort(services, 'index')

        AddServiceSubServiceListeners(services, dispatch, docId)

        dispatch(actions.setServicesNew({ id: docId, data: services }))
      })
    unsubscribeList[key] = unsub
  } catch (error) {
    console.log(error)
  }
}

export const AddDepartmentListener = (dispatch, departmentListenerAdded) => {
  if (!isLoggedIn()) return
  if (
    !departmentListenerAdded &&
    !unsubscribeList[Collections.DEFAULTDEPARTMENTS]
  ) {
    dispatch(actions.setDepartmentListenerAdded(true))

    const unsub = db
      .collection(Collections.DEFAULTDEPARTMENTS)
      .onSnapshot(departmentSnapshot => {
        let departments = []
        let customDepartment = []
        for (const department of departmentSnapshot.docs) {
          const deptData = { id: department.id, ...department.data() }
          if (deptData.default) {
            departments.push(deptData)
          } else {
            customDepartment.push(deptData)
          }
        }
        departments = Sort(departments, 'index')
        customDepartment = Sort(customDepartment, 'index')

        // Add service listeners
        AddServiceSubServiceListeners(departments, dispatch)
        AddServiceSubServiceListeners(customDepartment, dispatch)

        dispatch(actions.setDepartmentsNew(departments))
        dispatch(actions.setCustomDepartmentsNew(customDepartment))
      })
    unsubscribeList[Collections.DEFAULTDEPARTMENTS] = unsub
  }
}

const AddServiceSubServiceListeners = (data, dispatch, serviceId) => {
  data.forEach(i => {
    if (i.services) AddServiceListener({ dispatch, docId: i.id, serviceId })
  })
}
// -----------------END---------------------------

export async function FetchCustomDepartments(hotelId) {
  const customDeptSnapshot = await db
    .collection(Collections.DEPARTMENTS)
    .where('hotelId', '==', hotelId)
    .where('default', '==', false)
    .where('predefined', '==', false)
    .where('isDelete', '==', false)
    .orderBy('index')
    .get()
  const deptList = []
  let services = {}
  const servicePromises = []
  for (const custDept of customDeptSnapshot.docs) {
    const deptId = custDept.id
    const deptData = { id: deptId, ...custDept.data() }
    deptList.push(deptData)
    servicePromises.push(FetchServices(deptId))
  }
  const serviceList = await Promise.all(servicePromises)
  deptList.forEach((dept, index) => {
    services[dept.id] = serviceList[index]
  })
  return { deptList, services }
}

async function FetchServices(departmentId) {
  const serviceSnapshot = await db
    .collection(Collections.DEPARTMENTS)
    .doc(departmentId)
    .collection(Collections.SERVICES)
    .orderBy('index')
    .get()
  let services = []
  for (const serviceDoc of serviceSnapshot.docs) {
    services.push({ id: serviceDoc.id, ...serviceDoc.data() })
  }
  if (services.length) {
    services = Sort(services, 'index')
  }
  return services
}
