/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Menu, Dropdown, Modal } from 'antd'
import { CaretDownOutlined, MenuOutlined } from '@ant-design/icons'

import { Logout } from '../../../services/user'
import LogoutIcon from '../Icons/Logouticon'
import ProfileIcon from '../Icons/Profileicon'

import { useHistory } from 'react-router'
import ChangePasswordComp from '../../Pages/Authentication/ConfirmPassword/ChangePasswordComp'
import SuccessModal from '../Modals/SuccessModal'
import UserModal from '../../Pages/Users/UserModal'
import { useDispatch, useSelector } from 'react-redux'
import { unsubscribeList } from '../../../config/constants'
import { DefaultLocationTypeListerner } from '../../../services/locationType'
import { defaultOverallFeedbackQuestionListener } from '../../../services/hotel'

const Header = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { userInfo } = useSelector(state => state)
  const [showModal, setShowModal] = useState({
    modalName: '',
    status: false,
    modalTitle: '',
  })

  function closeModal() {
    setShowModal({ name: '', status: false, title: '' })
  }

  useEffect(() => {
    DefaultLocationTypeListerner(dispatch)
    defaultOverallFeedbackQuestionListener(dispatch)
  }, [dispatch])

  const menu = (
    <Menu>
      <Menu.Item
        key='1'
        icon={<ProfileIcon></ProfileIcon>}
        onClick={() =>
          setShowModal({
            name: 'My Profile',
            status: true,
            title: 'Edit Profile',
            data: userInfo,
          })
        }
      >
        My Profile
      </Menu.Item>
      <Menu.Item
        key='2'
        icon={<ProfileIcon></ProfileIcon>}
        onClick={() =>
          setShowModal({
            name: 'Change Password',
            status: true,
            title: 'Change Password',
          })
        }
      >
        Change Password
      </Menu.Item>
      <Menu.Item
        key='3'
        icon={<LogoutIcon></LogoutIcon>}
        onClick={() => {
          const success = () => {
            Object.entries(unsubscribeList).forEach(([key, func]) => {
              if (typeof func === 'function') {
                func()
              }
              unsubscribeList[key] = null
            })
            history.push('/')
          }
          Logout(success)
        }}
      >
        Log Out
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <header>
        <div className='container-wrp'>
          <div className='row align-items-center justify-content-between'>
            <div className='col-auto'>
              <div className='logo'>
                <img src='images/logo.svg'></img>
              </div>
            </div>
            <div className='col-auto '>
              <div className='headericons-grp'>
                <ul className='list-unstyled'>
                  <li>
                    <div className='headerUser'>
                      {/* <figure>
                        <img src={PlaceHolder}></img>
                      </figure> */}
                      <div>
                        <Dropdown
                          className='dropdown-wrp'
                          overlay={menu}
                          placement='bottomRight'
                        >
                          <a
                            className='ant-dropdown-link'
                            onClick={e => e.preventDefault()}
                          >
                            <span className='usernamespan'>
                              {userInfo?.name}
                            </span>
                            <CaretDownOutlined />
                          </a>
                        </Dropdown>
                      </div>
                    </div>
                  </li>
                  <li className='d-md-none mobmenuicon'>
                    <MenuOutlined />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      {showModal.name === 'Change Password' && (
        <Modal
          title={showModal.title}
          visible={showModal.status}
          onCancel={closeModal}
          className='addUsermodal cmnModal'
          footer={null}
          centered
        >
          <ChangePasswordComp
            closeModal={closeModal}
            setShowModal={setShowModal}
          ></ChangePasswordComp>
        </Modal>
      )}

      {showModal.name === 'Success' && (
        <SuccessModal
          showSuccessModal={showModal.status}
          title={showModal.title}
          setShowSuccessModal={closeModal}
        ></SuccessModal>
      )}

      {showModal.name === 'My Profile' && (
        <UserModal
          isModalVisible={showModal.status}
          setIsModalVisible={setShowModal}
          title={showModal.title}
          data={showModal.data}
          closeModal={closeModal}
        ></UserModal>
      )}
    </>
  )
}
export default Header
