/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { Button } from 'antd'
import { DeleteOutlined  } from '@ant-design/icons'

import { formatPrice } from '../../../config/utils'

const SubscriptionBox = ({
  styleName,
  headtext,
  price,
  currency,
  onClick,
  isDefault,
  onDeleteClick,
}) => {
  return (
    <>
      <div className={`subscriptionBox ${styleName}`}>
        <div className='subscriptionBox-head'>
          <h3>
            {headtext}
            {isDefault ? null : <DeleteOutlined  onClick={onDeleteClick} />}
          </h3>
        </div>
        <div className='subscriptionBox-body'>
          <img src='images/medal2.svg'></img>
          <h4>
            {currency} {formatPrice(price)}
          </h4>
          <h5>Hotel Subscriptions</h5>

          <div className='subscriptionBoxbtn-wrp'>
            <Button className='cmnBtn'>View List</Button>
            <Button className='cmnBtn' onClick={onClick}>
              Know More
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default SubscriptionBox
