import React, { useEffect, useState } from 'react'
import {
  Input,
  Table,
  Button,
  Switch,
  Upload,
  Modal,
  Collapse,
  Form,
  Popover,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import Header from '../../Common/Header/Header'
import SideMenu from '../../Common/Sidemenu/Sidemenu'
import SubscriptionCard from '../../Common/SubscriptionCard/SubscriptionCard'
import PageNamecard from '../../Common/PageNameCard/Pagenamecard'
import {
  AddDepartmentListener,
  UpdateServiceStatus,
  UpdateSubServiceStatus,
} from '../../../services/department'
import { useDispatch, useSelector } from 'react-redux'
import { AddServicesListener } from '../../../services/service'

const { Panel } = Collapse

function onChange(event) {
  event.stopPropagation()
}

const serviceColumns = [
  {
    title: '#',
    dataIndex: 'index',
    width: 80,
  },
  {
    title: 'Popular Services',
    dataIndex: 'name',
    width: 420,
  },
]

const Departments = () => {
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const {
    loadingServices,
    servicesListenerAdded,
    services: serviceList,
    departmentListenerAdded,
    departmentsNew,
    customDepartmentsNew,
    servicesNew,
  } = useSelector(state => state)

  useEffect(() => {
    AddDepartmentListener(dispatch, departmentListenerAdded)
  }, [dispatch, departmentListenerAdded])

  useEffect(() => {
    AddServicesListener(dispatch, servicesListenerAdded)
  }, [dispatch, servicesListenerAdded])

  const handleServiceSwitchClick = async (departmentId, serviceId, active) => {
    try {
      setLoading(true)
      await UpdateServiceStatus(departmentId, serviceId, active)
    } catch (error) {
      console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  const handleSubServiceSwitchClick = async (
    departmentId,
    serviceId,
    subServiceId,
    active
  ) => {
    try {
      setLoading(true)
      await UpdateSubServiceStatus(
        departmentId,
        serviceId,
        subServiceId,
        active
      )
    } catch (error) {
      console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  const content2 = (serviceId, departmentId, _serviceEnabled) => {
    return (
      <ul className="list-unstyled">
        {servicesNew[serviceId]?.map(({ id, name, active }) => (
          <li key={id} value={id}>
            {name}
            <div className="customSwitch">
              <Switch
                //disabled={!serviceEnabled}
                disabled={true}
                checked={active}
                onClick={() =>
                  handleSubServiceSwitchClick(
                    departmentId,
                    serviceId,
                    id,
                    active
                  )
                }
              />
            </div>
          </li>
        ))}
      </ul>
    )
  }

  const showPopover = (services, data) => {
    if (!services) return null
    return (
      <Popover
        className="tablepopover"
        overlayClassName={'tableDropdown'}
        trigger="click"
        placement="bottomRight"
        content={() => content(data.id, data.departmentEnabled)}
      >
        <Button>
          <img alt=" " src="images/arrow-right.svg"></img>
        </Button>
      </Popover>
    )
  }

  const content = (departmentId, _departmentEnabled) => {
    return (
      <ul className="list-unstyled">
        {servicesNew[departmentId]?.map(
          ({ id, name, active: serviceEnabled, services: subServices }) => (
            <li key={id} value={id}>
              {name}
              <div className="customSwitch">
                <Switch
                  //disabled={!departmentEnabled}
                  disabled={true}
                  checked={serviceEnabled}
                  onClick={() =>
                    handleServiceSwitchClick(departmentId, id, serviceEnabled)
                  }
                />
              </div>
              {subServices && (
                <Popover
                  className="tablepopover subtablepopover"
                  overlayClassName={'tableDropdown'}
                  trigger="click"
                  placement="bottomLeft"
                  content={() => content2(id, departmentId, serviceEnabled)}
                >
                  <Button>
                    <img alt=" " src="images/arrow-right.svg"></img>
                  </Button>
                </Popover>
              )}
            </li>
          )
        )}
      </ul>
    )
  }

  const departmentcolumns = [
    {
      title: '#',
      dataIndex: 'index',
      width: 80,
    },
    {
      title: 'Department',
      dataIndex: 'name',
      width: 420,
    },
    {
      title: 'Action',
      dataIndex: 'services',
      width: 60,
      render: (services, { id, active: departmentEnabled }) =>
        showPopover(services, { id, active: departmentEnabled }),
    },
  ]

  const customDepartmentColumns = [
    {
      title: '#',
      dataIndex: 'index',
      width: 80,
    },
    {
      title: 'Staff Department',
      dataIndex: 'name',
      width: 420,
    },
    {
      title: 'Action',
      dataIndex: 'services',
      width: 60,
      render: (services, { id, active: departmentEnabled }) =>
        showPopover(services, { id, active: departmentEnabled }),
    },
  ]

  const [isDepartmentModalVisible, setIsDepartmentModalVisible] =
    useState(false)

  const [isServiceModalVisible, setIsServiceModalVisible] = useState(false)
  const showServiceModal = () => {
    setIsDepartmentModalVisible(false)
    setIsServiceModalVisible(true)
  }

  const [isSubServiceModalVisible, setIsSubServiceModalVisible] =
    useState(false)
  const showSubServiceModal = () => {
    setIsDepartmentModalVisible(false)
    setIsServiceModalVisible(false)
    setIsSubServiceModalVisible(true)
  }

  const [isModalVisible3, setIsModalVisible3] = useState(false)

  const hideAll = () => {
    setIsDepartmentModalVisible(false)
    setIsServiceModalVisible(false)
    setIsSubServiceModalVisible(false)
    setIsModalVisible3(false)
  }

  return (
    <>
      <Header></Header>
      <SideMenu></SideMenu>
      <section className="mainContent department-wrp">
        <div className="mainContent-in">
          <div className="row">
            <div className="col-12">
              <PageNamecard
                title="Departments"
                breadcrumb={['Super Admin', 'Departments']}
              />
            </div>

            <div className="col-12 col-md-12 col-lg-8">
              <div className="table-wrp departmentTable mb-30">
                <Table
                  columns={departmentcolumns}
                  dataSource={departmentsNew}
                  pagination={false}
                  scroll={{ y: 220 }}
                  rowKey="id"
                  loading={loading}
                />
              </div>

              <div className="table-wrp departmentTable mb-30">
                <Table
                  columns={customDepartmentColumns}
                  dataSource={customDepartmentsNew}
                  pagination={false}
                  scroll={{ y: 220 }}
                  rowKey="id"
                  loading={loading}
                />
              </div>

              <div className="table-wrp departmentTable">
                <Table
                  columns={serviceColumns}
                  dataSource={serviceList}
                  pagination={false}
                  scroll={{ y: 220 }}
                  rowKey="id"
                  loading={loadingServices}
                />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-4">
              <SubscriptionCard></SubscriptionCard>
            </div>
          </div>
        </div>
      </section>

      <Modal
        title="Add Department"
        visible={isDepartmentModalVisible}
        onOk={hideAll}
        onCancel={hideAll}
        className="addDepartmentModal cmnModal"
        centered
        footer={null}
      >
        <div className="imageUpload-wrp" id="dptimg">
          <figure>
            <div className="upload-figin">
              <img src="images/adddepartment.png" alt="dept"></img>
            </div>
            <button className="removebtn">
              <img src="images/close.svg" alt="close"></img>
            </button>
          </figure>
          <div className="uploadbtn-wrp" id="upload">
            <Upload>
              <button>Upload Photo</button>
            </Upload>
            <p>Please upload At least 256 x 256px PNG or JPEG File</p>
          </div>
        </div>

        <Form layout="vertical" id="custom-form-dept">
          <div className="row">
            <div className="col-12" id="cus-col">
              <div className="form-group cmn-input">
                <Form.Item label="Department Name" id="dept-name">
                  <Input placeholder="Department Name" />
                </Form.Item>
              </div>
            </div>

            <div className="col-12">
              <label>Sub Departments</label>
              <div className="subdepartmentCollapse-wrp">
                <Collapse>
                  <Panel
                    className="noSubservice"
                    header={
                      <div className="d-inline-flex align-items-center">
                        <span className="subdepimg">
                          <img src="/images/sm-department.png" alt=""></img>
                        </span>
                        Room Cleaning
                      </div>
                    }
                    key="1"
                    extra={
                      <div className="tableactionbtn-grp" onClick={onChange}>
                        <button onClick={showSubServiceModal}>
                          <img src="/images/sa-tadd.svg" alt=""></img>
                        </button>
                        <button>
                          <img src="/images/sa-tdelete.svg" alt=""></img>
                        </button>
                      </div>
                    }
                  ></Panel>
                  <Panel
                    header={
                      <div className="d-inline-flex align-items-center">
                        <span className="subdepimg">
                          <img src="/images/sm-department.png" alt=""></img>
                        </span>
                        Pick Laundry
                      </div>
                    }
                    key="2"
                    extra={
                      <div className="tableactionbtn-grp" onClick={onChange}>
                        <button onClick={showSubServiceModal}>
                          <img src="/images/sa-tadd.svg" alt=""></img>
                        </button>
                        <button>
                          <img src="/images/sa-tdelete.svg" alt=""></img>
                        </button>
                      </div>
                    }
                  >
                    <ul className="list-unstyled ">
                      <li>
                        <div className="d-inline-flex align-items-center">
                          <span className="subdepimg">
                            <img src="/images/sm-department.png" alt=""></img>
                          </span>
                          Air Conditioner
                        </div>
                        <div className="tableactionbtn-grp">
                          <button>
                            <img src="/images/sa-tdelete.svg" alt=""></img>
                          </button>
                        </div>
                      </li>
                      <li>
                        <div className="d-inline-flex align-items-center">
                          <span className="subdepimg">
                            <img src="/images/sm-department.png" alt=""></img>
                          </span>
                          Light
                        </div>
                        <div className="tableactionbtn-grp">
                          <button>
                            <img src="/images/sa-tdelete.svg" alt=""></img>
                          </button>
                        </div>
                      </li>
                    </ul>
                  </Panel>
                  <Panel
                    className="noSubservice"
                    header={
                      <div className="d-inline-flex align-items-center">
                        <span className="subdepimg">
                          <img src="/images/sm-department.png" alt=""></img>
                        </span>
                        Maintanance
                      </div>
                    }
                    key="3"
                    extra={
                      <div className="tableactionbtn-grp">
                        <button onClick={showSubServiceModal}>
                          <img src="/images/sa-tadd.svg" alt=""></img>
                        </button>
                        <button>
                          <img src="/images/sa-tdelete.svg" alt=""></img>
                        </button>
                      </div>
                    }
                  ></Panel>
                </Collapse>
              </div>
            </div>
            <div className="col-12">
              <Button className="addnewLink mt-3" onClick={showServiceModal}>
                <PlusOutlined /> Add Sub Department
              </Button>
            </div>
          </div>
        </Form>

        <div className="modalFooter">
          <Button className="grayBtn" key="back" onClick={hideAll}>
            Cancel
          </Button>

          <Button className="blueBtn ml-4" key="submit" onClick={hideAll}>
            Submit
          </Button>
        </div>
      </Modal>

      <Modal
        title="Add Sub Department"
        visible={isServiceModalVisible}
        onOk={hideAll}
        onCancel={hideAll}
        className="addSubDepartmentModal cmnModal"
        centered
        footer={null}
      >
        <div className="imageUpload-wrp" id="sub-dept">
          <figure>
            <div className="upload-figin">
              <img src="images/adddepartment.png" alt="sub-dept-img"></img>
            </div>
            <button className="removebtn">
              <img src="images/close.svg" alt="sub-dept-close"></img>
            </button>
          </figure>
          <div className="uploadbtn-wrp">
            <Upload id="sub-dept-upload">
              <button>Upload Photo</button>
            </Upload>
            <p>Please upload At least 256 x 256px PNG or JPEG File</p>
          </div>
        </div>

        <Form layout="vertical">
          <div className="row">
            <div className="col-12">
              <div className="form-group cmn-input">
                <Form.Item label="Sub  Department  Name">
                  <Input placeholder="Sub  Department  Name" />
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>

        <div className="modalFooter">
          <Button className="grayBtn" key="back" onClick={hideAll}>
            Cancel
          </Button>

          <Button className="blueBtn ml-4" key="submit" onClick={hideAll}>
            Submit
          </Button>
        </div>
      </Modal>

      <Modal
        title="Add Sub Department Service"
        visible={isSubServiceModalVisible}
        onOk={hideAll}
        onCancel={hideAll}
        className="addSubDepartmentServiceModal cmnModal"
        centered
        footer={null}
      >
        <div className="imageUpload-wrp">
          <figure>
            <div className="upload-figin">
              <img src="images/adddepartment.png" alt="addSubDepartment"></img>
            </div>
            <button className="removebtn">
              <img src="images/close.svg" alt="addSubDepartment-close"></img>
            </button>
          </figure>
          <div className="uploadbtn-wrp">
            <Upload id="addSubDepartment-upload">
              <button>Upload Photo</button>
            </Upload>
            <p>Please upload At least 256 x 256px PNG or JPEG File</p>
          </div>
        </div>

        <Form layout="vertical">
          <div className="row">
            <div className="col-12" id="addSubDepartment-col">
              <div className="form-group cmn-input">
                <Form.Item label="Sub Department Service Name" id="subDepartment-serviceName">
                  <Input placeholder="Sub Department Service Name" />
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>

        <div className="modalFooter">
          <Button className="grayBtn" key="back" onClick={hideAll}>
            Cancel
          </Button>

          <Button className="blueBtn ml-4" key="submit" onClick={hideAll}>
            Submit
          </Button>
        </div>
      </Modal>

      <Modal
        title="Add Information & Services"
        visible={isModalVisible3}
        onOk={hideAll}
        onCancel={hideAll}
        className="addinfoServiceModal cmnModal"
        footer={null}
        centered
      >
        <div className="imageUpload-wrp">
          <figure>
            <div className="upload-figin">
              <img src="images/adddepartment.png" alt=""></img>
            </div>
            <button className="removebtn">
              <img src="images/close.svg" alt=""></img>
            </button>
          </figure>
          <div className="uploadbtn-wrp">
            <Upload>
              <button>Upload Photo</button>
            </Upload>
            <p>Please upload At least 256 x 256px PNG or JPEG File</p>
          </div>
        </div>

        <Form layout="vertical">
          <div className="row">
            <div className="col-12">
              <div className="form-group cmn-input">
                <Form.Item label="Department  Name">
                  <Input placeholder="Department  Name" />
                </Form.Item>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group cmn-input">
                <Form.Item label="Service   Name">
                  <Input placeholder="Service   Name" />
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>

        <div className="modalFooter">
          <Button className="grayBtn" key="back" onClick={hideAll}>
            Cancel
          </Button>

          <Button className="blueBtn ml-4" key="submit" onClick={hideAll}>
            Submit
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default Departments
