/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import { Alert, Form, Input, Button } from 'antd'

import { SendResetPasswordEmail } from '../../../../services/user'

const ForgotPassword = () => {
  const [state, setState] = useState({
    email: '',
    disabledBtn: true,
    errorMsg: false,
    successMsg: false,
    errorMessageText: '',
  })
  const [showLoader, setShowLoader] = useState(false)

  const handleForgotPasswordClick = async () => {
    try {
      setState({ ...state, disabledBtn: true, successMsg: false, errorMsg: false })
      setShowLoader(true)

      const { success, message } = await SendResetPasswordEmail(state.email)
      if (success)
        setState({
          ...state,
          successMsg: true,
          errorMsg: false,
          disabledBtn: true,
          errorMessageText: '',
        })
      else
        setState({
          ...state,
          successMsg: false,
          errorMsg: true,
          disabledBtn: false,
          errorMessageText: message,
        })
    } catch (error) {
      setShowLoader(false)
    } finally {
      setShowLoader(false)
    }
  }

  const handleEmailChange = e => {
    let email = e.target.value
    let disabledBtn = true
    const emailRegEx = /^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/i
    if (email && emailRegEx.test(email)) disabledBtn = false

    setState({ ...state, email, disabledBtn })
  }
  return (
    <>
      <div className='formLayout'>
        <div className='signin-wrp forgotpassword-wrp'>
          <div className='bgPart'>
            <figure>
              <img className='img-fluid' src='images/inplasslogo.svg'></img>
            </figure>
          </div>
          <div className='formPart'>
            <h1>Forgot password</h1>
            <h6>
              Enter your email address and we will send you a link to reset your password.
            </h6>
            <Form layout='vertical' validateTrigger='' onFinish={handleForgotPasswordClick}>
              <div className='row'>
                <div className='col-12'>
                  <div className='form-group sign-field'>
                    <Form.Item
                      name='email'
                      required
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your Email ID',
                        },
                        {
                          pattern: /^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/i,
                          message: 'Your Email ID must be in the format name@domain.com',
                        },
                      ]}
                    >
                      <Input
                        placeholder='name@domain.com'
                        value={state.email}
                        onChange={handleEmailChange}
                        maxLength='50'
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <Form.Item>
                <Button
                  htmlType='submit'
                  block
                  disabled={state.disabledBtn}
                  className='continuebtn'
                  loading={showLoader}
                >
                  Send Reset Link
                </Button>
                {state.successMsg && (
                  <Alert
                    message='Please check your inbox and follow further instructions to reset your password'
                    type='success'
                    showIcon
                    className='mt-2'
                  />
                )}
                {state.errorMsg && (
                  <Alert message={state.errorMessageText} type='error' showIcon className='mt-2' />
                )}
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword
