/* eslint-disable no-useless-escape */
import { Select, Input } from 'antd'

export const { Option } = Select
export const { Search, TextArea } = Input
export const secondsToShowAlert = 3000

export const APIs = {
  CREATE_USER: '/api/v1/user/create',
  DELETE_USER: '/DeleteUser',
  HARD_DELETE_USER: '/api/v1/user/delete',
  RESET_PASSWORD: '/api/v1/staff/password-reset',
  CREATE_SEGMENT: '/api/v1/onesignal/create-segment',
  DELETE_HOTEL: '/api/v1/hotel/delete',
  SENDONESIGNALEMAILTOUSER: '/api/v1/staff/sendOneSignalEmailToUser',
  UPDATE_PASSWORD: '/api/v1/staff/update-Password-With-Token',
  GUEST_BULK_UPLOAD: '/api/v1/guest/guestBulkUpload',
  EXCEL_FILE_FORMATTER: '/api/v1/guest/excelFileFormatter',
  UPDATE_STAFF_PASSWORD: '/api/v1/hotel/updateStaffPasswordByHotel',
}

export const Collections = {
  SERVICES: 'services',
  DEPARTMENTS: 'departments',
  DEFAULTDEPARTMENTS: 'defaultDepartments',
  HOTELS: 'hotels',
  ORDERS: 'orders',
  ROLES: 'roles',
  USERS: 'users',
  USERHOTELS: 'userHotels',
  SUBSCRIPTION: 'subscription',
  DEFAULTSERVICES: 'defaultServices',
  DEFAULTCUISINES: 'defaultCuisines',
  CUISINES: 'cuisines',
  DOMAIN: 'domain',
  MOBILE_DASHBOARD: 'mobile_dashboard',
  DEPARTMENTREQUESTSTAT: 'departmentRequestStat',
  GUESTREQUESTS: 'guestRequest',
  DEPARTMENTREQUEST: 'departmentRequest',
  HOTELADMINDASHBOARD: 'hotelAdminDashboard',
  CONFIG: 'config',
  RELEASE: 'release',
  HOTEL_GROUPS: 'hotelGroups',
  DEFAULTLOCATIONTYPES: 'defaultLocationTypes',
  LOCATIONTYPES: 'locationTypes',
  HOTELLOCATIONTYPES: 'hotelLocationTypes',
  DEFAULTOVERALLFEEDBACKQUESTION: 'defaultOverallFeedbackQuestion',
  OVERALLFEEDBACKQUESTIONS: 'overallFeedbackQuestions',
  HOTELOVERALLFEEDBACKQUESTIONS: 'hotelOverallFeedbackQuestions',
  SUPERADMINSETTINGS: 'superAdminSettings',
  SUPERADMIN_GUESTSETTINGS: 'superAdmin_GuestSettings',
}

export const validateAlphaNumeric = (_fieldProps, message) => ({
  validator(_, value) {
    const first = /[A-Za-z]/.test(value)
    const second = /[A-Za-z0-9 ]/.test(value)

    if ((first && second) || value.length === 0) return Promise.resolve()
    return Promise.reject(new Error(message))
  },
})

export const defaultKey = 'default'
export const predefinedKey = 'predefined'
export const SuperAdminRole = 'SuperAdmin'

export let unsubscribeList = {}

export const patterns = {
  password: {
    regex:
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*\-+\_\=\(\)\[\]\{\}\;\:\"\\\|\,\<\.\>\/]).{8,}$/,
    message:
      'Password must contain a minimum of 8 characters including number, symbol, upper and lower case character',
  },
}

export const transliterateLanguageIds = [
  'en',
  'ar',
  'hi',
  'ml',
  'ru',
  'ur',
  'zh',
  'en',
  'fr',
  'de',
  'it',
  'es',
  'tl',
]

export const languageIdToDocName = {
  en: 'English',
  fr: 'French',
  hi: 'Hindi',
  ur: 'Urdu',
  ar: 'Arabic',
  ml: 'Malayalam',
  ru: 'Russian',
  de: 'German',
  it: 'Italian',
  es: 'Spanish',
  tl: 'Tagalog',
  zh: 'Chinese',
}
