/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React from 'react'
import { Input, Table, Select, Button, DatePicker } from 'antd'

import Header from '../../Common/Header/Header'
import SideMenu from '../../Common/Sidemenu/Sidemenu'
import PageNamecard from '../../Common/PageNameCard/Pagenamecard'

import SubscriptionCard from '../../Common/SubscriptionCard/SubscriptionCard'

const { Search } = Input
const { Option } = Select

const subscriptioncolumns = [
  {
    title: 'Hotel Name',
    dataIndex: 'HotelName',
    width: 160,
    render: () => (
      <div className='tableuser sa-tableuser'>
        <figure>
          <img className='img-fluid' src='images/hotelLogo.svg'></img>
        </figure>
        <span>Armani Hotel </span>
      </div>
    ),
  },
  {
    title: 'Subscription',
    dataIndex: 'Subscription',
    className: 'text-center',
    width: 100,
  },
  {
    title: 'Start Date',
    dataIndex: 'StartDate',
    width: 110,
  },
  {
    title: 'End Date',
    dataIndex: 'EndDate',
    width: 110,
  },
  {
    title: 'Subscription Due',
    dataIndex: 'SubscriptionDue',
    width: 110,
  },

  {
    title: 'Status',
    dataIndex: 'Action',
    width: 80,
    render: () => (
      <>
        <span className='activeStatus'>Active</span>
        {/* <span className="inactiveStatus">Inactive</span> */}
      </>
    ),
  },
]
const subscriptionData = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'].map(a => ({
  key: a,
  Subscription: 'Gold',
  StartDate: '23 Apr 2021',
  EndDate: '23 Apr 2021',
  SubscriptionDue: '--  --  --',
}))

const SubscrptionsList = () => {
  return (
    <>
      <Header></Header>
      <SideMenu></SideMenu>
      <section className='mainContent'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNamecard></PageNamecard>
            </div>

            <div className='col-12 col-md-12 col-lg-8'>
              <div className='tablefilter-wrp'>
                <div className='form-row'>
                  <div className='col-4 col-md'>
                    <div className='cmnSelect-form'>
                      <Select defaultValue='Hotel'>
                        <Option value='Hotel'>Hotel</Option>
                        <Option value='lucy'>Lucy</Option>
                      </Select>
                    </div>
                  </div>
                  <div className='col-4 col-md'>
                    <div className='cmnSelect-form'>
                      <Select defaultValue='Status'>
                        <Option value='Status'>Status</Option>
                        <Option value='lucy'>Lucy</Option>
                      </Select>
                    </div>
                  </div>
                  <div className='col-6 col-md-4 col-lg'>
                    <div className='cmnSelect-form'>
                      <DatePicker placeholder='Date' />
                    </div>
                  </div>
                  <div className='col-4 col-md-auto'>
                    <Button className='adduserbtn'>
                      <img src='images/sa-clearicon.svg'></img>
                    </Button>
                  </div>
                </div>
              </div>

              <div className='table-wrp'>
                <Table
                  columns={subscriptioncolumns}
                  dataSource={subscriptionData}
                  pagination={false}
                  scroll={{ y: 496 }}
                />
              </div>
            </div>
            <div className='col-12 col-md-12 col-lg-4'>
              <SubscriptionCard></SubscriptionCard>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SubscrptionsList
