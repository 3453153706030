import React from "react";

const DeleteModalContent = ({ title = 'Delete Restaurant', message = 'Are you sure want to delete the Restaurant ?' }) => {
    return (
        <>
            <div className="deletetext-wrp">
                <h2>{title}</h2>
                <h6>{message}</h6>
            </div>
        </>
    );
};

export default DeleteModalContent;
