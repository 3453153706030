import React from 'react'
import { Collapse, Checkbox } from 'antd'
import { Ternary } from '../../../config/utils'

const { Panel } = Collapse

const Services = ({
  departmentId,
  serviceId,
  data,
  onServiceCheckboxClick,
  openSubServiceKeys,
  setOpenSubServiceKeys,
  showCheckBoxes = true,
  ...rest
}) => {
  const subServices = (
    <li id={data.id} {...rest}>
      {data.name}
      {Ternary(
        showCheckBoxes,
        <Checkbox
          checked={data.checked}
          onClick={e => {
            if (onServiceCheckboxClick) {
              onServiceCheckboxClick({
                e,
                departmentId,
                serviceId: serviceId || data.id,
                subServiceId: serviceId ? data.id : null,
              })
            }
          }}
        />,
        null
      )}
    </li>
  )

  if (!data.services) return subServices

  return (
    <div className='departmentCollapse-wrp cmnCollapse-wrp'>
      <Collapse activeKey={openSubServiceKeys} onChange={e => setOpenSubServiceKeys(e)}>
        <Panel
          header={data.name}
          key={data.id}
          extra={
            <Checkbox
              onClick={e => {
                if (onServiceCheckboxClick) {
                  onServiceCheckboxClick({ e, departmentId, serviceId: data.id })
                }
              }}
              checked={data.checked}
            />
          }
          {...rest}
        >
          <>
            <ul className='list-unstyled checkList' key={data.id}>
              {data?.services?.map(s => (
                <Services
                  data={s}
                  departmentId={departmentId}
                  key={s.id}
                  serviceId={data.id}
                  onServiceCheckboxClick={onServiceCheckboxClick}
                  openSubServiceKeys={openSubServiceKeys}
                  setOpenSubServiceKeys={setOpenSubServiceKeys}
                />
              ))}
            </ul>
          </>
        </Panel>
      </Collapse>
    </div>
  )
}

export default Services
