import React from 'react'
import { Progress } from 'antd'

const GetColor = name => {
  if (name.toLowerCase() === 'gold') return 'Green'
  if (name.toLowerCase() === 'bronze') return '#FF9900'
  if (name.toLowerCase() === 'silver') return 'red'
  return '#372AEC'
}

const ProgressBar = ({ name, percentage }) => {
  return (
    <>
      <div className='progressbar-wrp'>
        <div className='row'>
          <div className='col'>
            <h5>
              {name} <span>{percentage}%</span>
            </h5>
            <Progress strokeColor={GetColor(name)} percent={percentage} showInfo={false} />
          </div>
          <div className='col-auto'>
            <div className='medal-wrp'>
              <img src='images/medal.svg' alt=''></img>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProgressBar
