import React, { useContext } from 'react'
import { Tabs } from 'antd'
import Header from '../../Common/Header/Header'
import SideMenu from '../../Common/Sidemenu/Sidemenu'
import PageNamecard from '../../Common/PageNameCard/Pagenamecard'
import { SettingsState } from './model'
import GuestOnboarding from './Components/GuestOnboarding'
import SuccessModal from '../../Common/Modals/SuccessModal'
import GuestBulkUpload from './Components/GuestBulkUpload'
import HotelSettings from './Components/HotelSettings'

const { TabPane } = Tabs

export default function SettingsLayout() {
  const {
    tabKey,
    handleTabChange,
    getSuccessModalMessage,
    showSuccessModal,
    setShowSuccessModal,
  } = useContext(SettingsState)
  return (
    <>
      <Header></Header>
      <SideMenu></SideMenu>
      <section className='mainContent hotelmgmt-wrp'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNamecard
                title='Settings'
                breadcrumb={['Super Admin', 'Settings']}
              />
            </div>
            <div className='col-12 col-xl-12'>
              <div className='row'>
                <Tabs
                  activeKey={tabKey}
                  onChange={handleTabChange}
                  className='col-12 col-12'
                >
                  <TabPane tab='Guest' key='1'>
                    <GuestOnboarding />
                  </TabPane>
                  <TabPane tab='Guest Bulk Upload' key='2'>
                    <GuestBulkUpload />
                  </TabPane>
                  <TabPane tab='Hotel Settings' key='3'>
                    <HotelSettings />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SuccessModal
        title={getSuccessModalMessage()}
        showSuccessModal={showSuccessModal}
        setShowSuccessModal={() => setShowSuccessModal(false)}
      />
    </>
  )
}
