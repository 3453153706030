/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { Alert, Input, Select, Modal, Button, Form } from 'antd'

import {
  CreateNewUser,
  EditUserProfile,
  GetSuperAdminDetailsFromUid,
  GetUserUidFromEmail,
} from '../../../services/user'

import {
  GetAxiosHeaders,
  removeDuplicates,
  SanitizeNumber,
  SetAutoClearProp,
  Sort,
  Ternary,
} from '../../../config/utils'

import {
  APIs,
  Option,
  SuperAdminRole,
  validateAlphaNumeric,
} from '../../../config/constants'

import { countryList } from '../../../config/countryList'
import axios from 'axios'
import { useSelector } from 'react-redux'
const generator = require('generate-password')

let currCountryList = countryList.map(country => {
  let number = SanitizeNumber(country.code)
  number = +number || 0
  return {
    ...country,
    lablecode: isNaN(number) ? '0' : number,
  }
})

currCountryList = Sort(currCountryList, 'lablecode')
currCountryList = removeDuplicates(currCountryList, 'code')

function isValidFunction(functionName, data) {
  if (typeof functionName === 'function') {
    return functionName(data)
  }
}

async function SaveEdit(data) {
  const {
    showLoader,
    validatePhoneOrEmail,
    setShowLoader,
    setCreateUserError,
    contactNumber,
    contactNumberPrefix,
    email,
    fullName,
    designation,
    editingUserProfile,
    setSuccessMessage,
    closeModal,
    setShowSuccessModal,
    setLoadingData,
    userid,
    setIsModalVisible,
  } = data
  try {
    if (showLoader) return
    if (!validatePhoneOrEmail()) return
    setShowLoader(true)
    setCreateUserError('')

    let password = generator.generate({
      length: 8,
      uppercase: true,
      lowercase: true,
    })

    password = `T${password}@1`

    const user = {
      contactNumber: contactNumber ?? '',
      contactNumberPrefix: contactNumber ? contactNumberPrefix : '',
      email,
      name: fullName,
      designation: designation ?? '',
      roles: [SuperAdminRole],
      password,
    }

    isValidFunction(setLoadingData, true)
    if (editingUserProfile) {
      const { success: editSuccess, message: editErrorMessage } =
        await EditUserProfile(user, userid)
      if (!editSuccess) {
        setCreateUserError(editErrorMessage)
        return
      }
      isValidFunction(setLoadingData, false)
      SetAutoClearProp(setIsModalVisible, {
        name: 'Success',
        status: true,
        title: editErrorMessage,
      })
    } else {
      const uid = await GetUserUidFromEmail(email)
      let userExist = ''
      let createUserMessage = ''
      if (uid) {
        userExist = await GetSuperAdminDetailsFromUid(uid)
      }
      if (userExist && userExist?.isDelete) {
        const existUser = {
          ...user,
          isDelete: false,
        }
        const { success: editSuccess, message: editErrorMessage } =
          await EditUserProfile(existUser, uid)
        if (!editSuccess) {
          setCreateUserError(editErrorMessage)
          return
        }
        createUserMessage = 'User added successfully.'
      } else {
        const response = await CreateNewUser(user)
        if (!response.success) {
          setCreateUserError(response.message)
          return
        }
        createUserMessage = response.message
      }

      const url = process.env.REACT_APP_SUPER_ADMIN_URL.concat('/SignIn')

      let headers = await GetAxiosHeaders()

      axios
        .post(
          APIs.SENDONESIGNALEMAILTOUSER,
          {
            project_id: 1,
            email: user.email,
            template_id: '11',
            subject: 'Welcome to Inplass.',
            variables: {
              '%name%': user.name,
              '%hotelurl%': url,
              '%hotelemail%': user.email,
              '%password%': user.password,
            },
          },
          { headers }
        )
        .then(response => console.log(response))

      closeModal()
      SetAutoClearProp(setSuccessMessage, createUserMessage)
      SetAutoClearProp(setShowSuccessModal, true)
      isValidFunction(setLoadingData, false)
    }
  } catch (error) {
    console.log({ error })
    console.log(error?.message)
  } finally {
    setShowLoader(false)
    isValidFunction(setLoadingData, false)
  }
}

const UserModal = props => {
  const {
    isModalVisible,
    setLoadingData,
    title,
    data,
    setShowSuccessModal,
    closeModal,
    setSuccessMessage,
    setIsModalVisible,
  } = props

  let { superAdminUsers } = useSelector(state => state)

  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [contactNumber, setContactNumber] = useState('')
  const [contactNumberPrefix, setContactNumberPrefix] = useState(
    currCountryList[0].code
  )
  const [designation, setDesignation] = useState('')

  const [form] = Form.useForm()
  const [showLoader, setShowLoader] = useState(false)
  const [createUserError, setCreateUserError] = useState('')
  const [editingUserProfile, setEditingUserProfile] = useState(false)

  const validatePhoneOrEmail = () => {
    let flag = !email && !contactNumber
    if (flag) {
      setCreateUserError('Email Id/Contact Number required')
    }
    return !flag
  }

  const onFinish = async () => {
    SaveEdit({
      showLoader,
      validatePhoneOrEmail,
      setShowLoader,
      setCreateUserError,
      contactNumber,
      contactNumberPrefix,
      email,
      fullName,
      designation,
      editingUserProfile,
      setSuccessMessage,
      closeModal,
      setShowSuccessModal,
      setLoadingData,
      userid: data?.uid,
      setIsModalVisible,
    })
  }

  const handleCancel = () => {
    if (showLoader) return
    closeModal()
  }

  const handleContactChange = e => {
    let contactNumberValue = SanitizeNumber(e.target.value)
    setContactNumber(contactNumberValue)
    form.setFieldsValue({
      contactNumber: contactNumberValue,
    })
  }

  const prefixSelector = (
    <Form.Item
      name='contactNumberPrefix'
      rules={[
        {
          required: contactNumber?.length > 0 && contactNumberPrefix === '',
          message: 'Please select a country code',
        },
      ]}
      noStyle
    >
      <Select
        style={{ width: 100 }}
        value={contactNumberPrefix}
        id={'prefix'}
        onChange={prefix => setContactNumberPrefix(prefix)}
      >
        {currCountryList.map((country, idx) => (
          <Option value={country.code} key={idx}>
            {country.code}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )

  useEffect(() => {
    if (data) {
      setFullName(data?.name)
      setEmail(data?.email)
      setContactNumber(data?.contactNumber)

      const contPrefix = Ternary(
        data?.contactNumberPrefix,
        data?.contactNumberPrefix,
        currCountryList[0].code
      )
      setContactNumberPrefix(contPrefix)

      setDesignation(data?.designation)
      setEditingUserProfile(true)
      form.setFieldsValue({
        fullName: data.name,
        email: data.email,
        contactNumber: data.contactNumber,
        contactNumberPrefix: contPrefix,
        designation: data.designation,
      })
    }
    return () => {
      clearData()
    }
  }, [isModalVisible, data])

  function clearData() {
    setFullName('')
    setEmail('')
    setContactNumber('')
    setContactNumberPrefix('')
    setDesignation('')
    setShowLoader(false)
    setCreateUserError('')
    setEditingUserProfile(false)

    form.resetFields()
  }

  return (
    <>
      <Modal
        title={title}
        visible={isModalVisible}
        onCancel={handleCancel}
        className='addUsermodal cmnModal'
        footer={null}
        centered
      >
        <Form
          layout='vertical'
          onFinish={onFinish}
          form={form}
          validateTrigger
          initialValues={{
            contactNumber,
            contactNumberPrefix,
            email,
            fullName: fullName,
            designation,
          }}
        >
          <div className='row'>
            <div className='col-12'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label={'Full Name'}
                  name='fullName'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter full name',
                    },
                    fieldProps =>
                      validateAlphaNumeric(
                        fieldProps,
                        'Please enter valid name'
                      ),
                  ]}
                  value={fullName}
                >
                  <Input
                    maxLength={50}
                    value={fullName}
                    onChange={e => setFullName(e.target.value)}
                  />
                </Form.Item>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-12'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label={'Email ID'}
                  name='email'
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Email ID must be in the format name@domain.com',
                    },
                  ]}
                >
                  <Input
                    disabled={editingUserProfile}
                    placeholder='name@domain.com'
                    value={email}
                    onChange={e => {
                      const lowerEmail = e.target.value.toLowerCase()
                      setEmail(lowerEmail)
                      form.setFieldsValue({ email: lowerEmail })
                    }}
                  />
                </Form.Item>
              </div>
            </div>
            <div className='col-12 col-sm-6 col-md-6'>
              <div className='form-group contact-input'>
                <Form.Item
                  name='contactNumber'
                  label={'Contact Number'}
                  rules={[
                    {
                      min: 6,
                      message:
                        'Contact number should be minimum of 6 Characters long',
                    },
                    () => ({
                      validator(_, value) {
                        if (data) {
                          superAdminUsers = superAdminUsers?.filter(
                            item => item.id !== data.uid
                          )
                        }

                        if (
                          !value ||
                          !superAdminUsers?.find(
                            u =>
                              u.contactNumberPrefix === contactNumberPrefix &&
                              u.contactNumber === value
                          )
                        ) {
                          return Promise.resolve()
                        }

                        return Promise.reject(
                          new Error(
                            `Contact number is associated with another account.`
                          )
                        )
                      },
                    }),
                  ]}
                >
                  <Input
                    addonBefore={prefixSelector}
                    maxLength={10}
                    value={contactNumber}
                    onChange={handleContactChange}
                  />
                </Form.Item>
              </div>
            </div>
            <div className='col-12 col-md-6 col-sm-6'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label={'Designation'}
                  name='designation'
                  rules={[
                    fieldProps =>
                      validateAlphaNumeric(
                        fieldProps,
                        'Please enter valid designation'
                      ),
                  ]}
                  value={designation}
                >
                  <Input
                    maxLength={50}
                    value={designation}
                    onChange={e => setDesignation(e.target.value)}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          {createUserError && (
            <Alert id='alrt' message={createUserError} type='error' showIcon />
          )}
          <div className='modalFooter'>
            <Button
              id='btnGray'
              className='grayBtn'
              key='back'
              onClick={closeModal}
            >
              {'Cancel'}
            </Button>

            <Button
              className='blueBtn ml-3 ml-lg-4'
              key='submit'
              htmlType='submit'
              loading={showLoader}
              id='btnSubmit'
            >
              {'Submit'}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default UserModal
