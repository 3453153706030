const countryListString = `[{"name":"Afghanistan","code":"+93","currency":"Afghani","currencyCode":"AFN"},
{"name":"Albania","code":"+355","currency":"Lek","currencyCode":"ALL"},
{"name":"Algeria","code":"+213","currency":"Algerian Dinar","currencyCode":"DZD"},
{"name":"American Samoa","code":"+1-684","currency":"US Dollar","currencyCode":"USD"},
{"name":"Andorra","code":"+376","currency":"Euro","currencyCode":"EUR"},
{"name":"Angola","code":"+244","currency":"Kwanza","currencyCode":"AOA"},
{"name":"Anguilla","code":"+1-264","currency":"East Caribbean Dollar","currencyCode":"XCD"},
{"name":"Antarctica","code":"+672","currency":"No universal currency","currencyCode":""},
{"name":"Antigua and Barbuda","code":"+1-268","currency":"East Caribbean Dollar","currencyCode":"XCD"},
{"name":"Argentina","code":"+54","currency":"Argentine Peso","currencyCode":"ARS"},
{"name":"Armenia","code":"+374","currency":"Armenian Dram","currencyCode":"AMD"},
{"name":"Aruba","code":"+297","currency":"Aruban Florin","currencyCode":"AWG"},
{"name":"Australia","code":"+61","currency":"Australian Dollar","currencyCode":"AUD"},
{"name":"Austria","code":"+43","currency":"Euro","currencyCode":"EUR"},
{"name":"Azerbaijan","code":"+994","currency":"Azerbaijanian Manat","currencyCode":"AZN"},
{"name":"Bahamas","code":"+1-242","currency":"Bahamian Dollar","currencyCode":"BSD"},
{"name":"Bahrain","code":"+973","currency":"Bahraini Dinar","currencyCode":"BHD"},
{"name":"Bangladesh","code":"+880","currency":"Taka","currencyCode":"BDT"},
{"name":"Barbados","code":"+1-246","currency":"Barbados Dollar","currencyCode":"BBD"},
{"name":"Belarus","code":"+375","currency":"Belarussian Ruble","currencyCode":"BYN"},
{"name":"Belgium","code":"+32","currency":"Euro","currencyCode":"EUR"},
{"name":"Belize","code":"+501","currency":"Belize Dollar","currencyCode":"BZD"},
{"name":"Benin","code":"+229","currency":"CFA Franc BCEAO","currencyCode":"XOF"},
{"name":"Bermuda","code":"+1-441","currency":"Bermudian Dollar","currencyCode":"BMD"},
{"name":"Bhutan","code":"+975","currency":"Ngultrum","currencyCode":"BTN"},
{"name":"Bolivia","code":"+591","currency":"Boliviano","currencyCode":"BOB"},
{"name":"Bosnia and Herzegovina","code":"+387","currency":"Convertible Mark","currencyCode":"BAM"},
{"name":"Botswana","code":"+267","currency":"Pula","currencyCode":"BWP"},
{"name":"Brazil","code":"+55","currency":"Brazilian Real","currencyCode":"BRL"},
{"name":"British Indian Ocean Territory","code":"+246","currency":"US Dollar","currencyCode":"USD"},
{"name":"British Virgin Islands","code":"+1-284","currency":"US Dollar","currencyCode":"USD"},
{"name":"Brunei","code":"+673","currency":"Brunei Dollar","currencyCode":"BND"},
{"name":"Bulgaria","code":"+359","currency":"Bulgarian Lev","currencyCode":"BGN"},
{"name":"Burkina Faso","code":"+226","currency":"CFA Franc BCEAO","currencyCode":"XOF"},
{"name":"Burundi","code":"+257","currency":"Burundi Franc","currencyCode":"BIF"},
{"name":"Cambodia","code":"+855","currency":"Riel","currencyCode":"KHR"},
{"name":"Cameroon","code":"+237","currency":"CFA Franc BEAC","currencyCode":"XAF"},
{"name":"Canada","code":"+1","currency":"Canadian Dollar","currencyCode":"CAD"},
{"name":"Cape Verde","code":"+238","currency":"Cabo Verde Escudo","currencyCode":"CVE"},
{"name":"Cayman Islands","code":"+1-345","currency":"Cayman Islands Dollar","currencyCode":"KYD"},
{"name":"Central African Republic","code":"+236","currency":"CFA Franc BEAC","currencyCode":"XAF"},
{"name":"Chad","code":"+235","currency":"CFA Franc BEAC","currencyCode":"XAF"},
{"name":"Chile","code":"+56","currency":"Unidad de Fomento","currencyCode":"CLF"},
{"name":"China","code":"+86","currency":"Yuan Renminbi","currencyCode":"CNY"},
{"name":"Christmas Island","code":"+61","currency":"Australian Dollar","currencyCode":"AUD"},
{"name":"Cocos Islands","code":"+61","currency":"Australian Dollar","currencyCode":"AUD"},
{"name":"Colombia","code":"+57","currency":"Colombian Peso","currencyCode":"COP"},
{"name":"Comoros","code":"+269","currency":"Comoro Franc","currencyCode":"KMF"},
{"name":"Cook Islands","code":"+682","currency":"New Zealand Dollar","currencyCode":"NZD"},
{"name":"Costa Rica","code":"+506","currency":"Costa Rican Colon","currencyCode":"CRC"},
{"name":"Croatia","code":"+385","currency":"Kuna","currencyCode":"HRK"},
{"name":"Cuba","code":"+53","currency":"Peso Convertible","currencyCode":"CUC"},
{"name":"Cyprus","code":"+357","currency":"Euro","currencyCode":"EUR"},
{"name":"Czech Republic","code":"+420","currency":"Czech Koruna","currencyCode":"CZK"},
{"name":"Democratic Republic of the Congo","code":"+243","currency":"Congolese Franc","currencyCode":"CDF"},
{"name":"Denmark","code":"+45","currency":"Danish Krone","currencyCode":"DKK"},
{"name":"Djibouti","code":"+253","currency":"Djibouti Franc","currencyCode":"DJF"},
{"name":"Dominica","code":"+1-767","currency":"East Caribbean Dollar","currencyCode":"XCD"},
{"name":"Dominican Republic","code":"+1-809, 1-829, 1-849","currency":"Dominican Peso","currencyCode":"DOP"},
{"name":"East Timor","code":"+670","currency":"US Dollar","currencyCode":"USD"},
{"name":"Ecuador","code":"+593","currency":"US Dollar","currencyCode":"USD"},
{"name":"Egypt","code":"+20","currency":"Egyptian Pound","currencyCode":"EGP"},
{"name":"El Salvador","code":"+503","currency":"El Salvador Colon","currencyCode":"SVC"},
{"name":"Equatorial Guinea","code":"+240","currency":"CFA Franc BEAC","currencyCode":"XAF"},
{"name":"Eritrea","code":"+291","currency":"Nakfa","currencyCode":"ERN"},
{"name":"Estonia","code":"+372","currency":"Euro","currencyCode":"EUR"},
{"name":"Ethiopia","code":"+251","currency":"Ethiopian Birr","currencyCode":"ETB"},
{"name":"Falkland Islands","code":"+500","currency":"Falkland Islands Pound","currencyCode":"FKP"},
{"name":"Faroe Islands","code":"+298","currency":"Danish Krone","currencyCode":"DKK"},
{"name":"Fiji","code":"+679","currency":"Fiji Dollar","currencyCode":"FJD"},
{"name":"Finland","code":"+358","currency":"Euro","currencyCode":"EUR"},
{"name":"France","code":"+33","currency":"Euro","currencyCode":"EUR"},
{"name":"French Polynesia","code":"+689","currency":"CFP Franc","currencyCode":"XPF"},
{"name":"Gabon","code":"+241","currency":"CFA Franc BEAC","currencyCode":"XAF"},
{"name":"Gambia","code":"+220","currency":"Dalasi","currencyCode":"GMD"},
{"name":"Georgia","code":"+995","currency":"Lari","currencyCode":"GEL"},
{"name":"Germany","code":"+49","currency":"Euro","currencyCode":"EUR"},
{"name":"Ghana","code":"+233","currency":"Ghana Cedi","currencyCode":"GHS"},
{"name":"Gibraltar","code":"+350","currency":"Gibraltar Pound","currencyCode":"GIP"},
{"name":"Greece","code":"+30","currency":"Euro","currencyCode":"EUR"},
{"name":"Greenland","code":"+299","currency":"Danish Krone","currencyCode":"DKK"},
{"name":"Grenada","code":"+1-473","currency":"East Caribbean Dollar","currencyCode":"XCD"},
{"name":"Guam","code":"+1-671","currency":"US Dollar","currencyCode":"USD"},
{"name":"Guatemala","code":"+502","currency":"Quetzal","currencyCode":"GTQ"},
{"name":"Guernsey","code":"+44-1481","currency":"Pound Sterling","currencyCode":"GBP"},
{"name":"Guinea","code":"+224","currency":"Guinea Franc","currencyCode":"GNF"},
{"name":"Guinea-Bissau","code":"+245","currency":"CFA Franc BCEAO","currencyCode":"XOF"},
{"name":"Guyana","code":"+592","currency":"Guyana Dollar","currencyCode":"GYD"},
{"name":"Haiti","code":"+509","currency":"Gourde","currencyCode":"HTG"},
{"name":"Honduras","code":"+504","currency":"Lempira","currencyCode":"HNL"},
{"name":"Hong Kong","code":"+852","currency":"Hong Kong Dollar","currencyCode":"HKD"},
{"name":"Hungary","code":"+36","currency":"Forint","currencyCode":"HUF"},
{"name":"Iceland","code":"+354","currency":"Iceland Krona","currencyCode":"ISK"},
{"name":"India","code":"+91","currency":"Indian Rupee","currencyCode":"INR"},
{"name":"Indonesia","code":"+62","currency":"Rupiah","currencyCode":"IDR"},
{"name":"Iran","code":"+98","currency":"Iranian Rial","currencyCode":"IRR"},
{"name":"Iraq","code":"+964","currency":"Iraqi Dinar","currencyCode":"IQD"},
{"name":"Ireland","code":"+353","currency":"Euro","currencyCode":"EUR"},
{"name":"Isle of Man","code":"+44-1624","currency":"Pound Sterling","currencyCode":"GBP"},
{"name":"Israel","code":"+972","currency":"New Israeli Sheqel","currencyCode":"ILS"},
{"name":"Italy","code":"+39","currency":"Euro","currencyCode":"EUR"},
{"name":"Ivory Coast","code":"+225","currency":"CFA Franc BCEAO","currencyCode":"XOF"},
{"name":"Jamaica","code":"+1-876","currency":"Jamaican Dollar","currencyCode":"JMD"},
{"name":"Japan","code":"+81","currency":"Yen","currencyCode":"JPY"},
{"name":"Jersey","code":"+44-1534","currency":"Pound Sterling","currencyCode":"GBP"},
{"name":"Jordan","code":"+962","currency":"Jordanian Dinar","currencyCode":"JOD"},
{"name":"Kazakhstan","code":"+7","currency":"Tenge","currencyCode":"KZT"},
{"name":"Kenya","code":"+254","currency":"Kenyan Shilling","currencyCode":"KES"},
{"name":"Kiribati","code":"+686","currency":"Australian Dollar","currencyCode":"AUD"},
{"name":"Kosovo","code":"+383","currency":"Euro","currencyCode":"EUR"},
{"name":"Kuwait","code":"+965","currency":"Kuwaiti Dinar","currencyCode":"KWD"},
{"name":"Kyrgyzstan","code":"+996","currency":"Som","currencyCode":"KGS"},
{"name":"Laos","code":"+856","currency":"Kip","currencyCode":"LAK"},
{"name":"Latvia","code":"+371","currency":"Euro","currencyCode":"EUR"},
{"name":"Lebanon","code":"+961","currency":"Lebanese Pound","currencyCode":"LBP"},
{"name":"Lesotho","code":"+266","currency":"Loti","currencyCode":"LSL"},
{"name":"Liberia","code":"+231","currency":"Liberian Dollar","currencyCode":"LRD"},
{"name":"Libya","code":"+218","currency":"Libyan Dinar","currencyCode":"LYD"},
{"name":"Liechtenstein","code":"+423","currency":"Swiss Franc","currencyCode":"CHF"},
{"name":"Lithuania","code":"+370","currency":"Euro","currencyCode":"EUR"},
{"name":"Luxembourg","code":"+352","currency":"Euro","currencyCode":"EUR"},
{"name":"Macau","code":"+853","currency":"Pataca","currencyCode":"MOP"},
{"name":"Macedonia","code":"+389","currency":"Denar","currencyCode":"MKD"},
{"name":"Madagascar","code":"+261","currency":"Malagasy Ariary","currencyCode":"MGA"},
{"name":"Malawi","code":"+265","currency":"Kwacha","currencyCode":"MWK"},
{"name":"Malaysia","code":"+60","currency":"Malaysian Ringgit","currencyCode":"MYR"},
{"name":"Maldives","code":"+960","currency":"Rufiyaa","currencyCode":"MVR"},
{"name":"Mali","code":"+223","currency":"CFA Franc BCEAO","currencyCode":"XOF"},
{"name":"Malta","code":"+356","currency":"Euro","currencyCode":"EUR"},
{"name":"Marshall Islands","code":"+692","currency":"US Dollar","currencyCode":"USD"},
{"name":"Mauritania","code":"+222","currency":"Ouguiya","currencyCode":"MRU"},
{"name":"Mauritius","code":"+230","currency":"Mauritius Rupee","currencyCode":"MUR"},
{"name":"Mayotte","code":"+262","currency":"Euro","currencyCode":"EUR"},
{"name":"Mexico","code":"+52","currency":"Mexican Peso","currencyCode":"MXN"},
{"name":"Micronesia","code":"+691","currency":"US Dollar","currencyCode":"USD"},
{"name":"Moldova","code":"+373","currency":"Moldovan Leu","currencyCode":"MDL"},
{"name":"Monaco","code":"+377","currency":"Euro","currencyCode":"EUR"},
{"name":"Mongolia","code":"+976","currency":"Tugrik","currencyCode":"MNT"},
{"name":"Montenegro","code":"+382","currency":"Euro","currencyCode":"EUR"},
{"name":"Montserrat","code":"+1-664","currency":"East Caribbean Dollar","currencyCode":"XCD"},
{"name":"Morocco","code":"+212","currency":"Moroccan Dirham","currencyCode":"MAD"},
{"name":"Mozambique","code":"+258","currency":"Mozambique Metical","currencyCode":"MZN"},
{"name":"Myanmar","code":"+95","currency":"Kyat","currencyCode":"MMK"},
{"name":"Namibia","code":"+264","currency":"Namibia Dollar","currencyCode":"NAD"},
{"name":"Nauru","code":"+674","currency":"Australian Dollar","currencyCode":"AUD"},
{"name":"Nepal","code":"+977","currency":"Nepalese Rupee","currencyCode":"NPR"},
{"name":"Netherlands","code":"+31","currency":"Euro","currencyCode":"EUR"},
{"name":"Netherlands Antilles","code":"+599","currency":"Netherlands Antillean Guilder","currencyCode":"ANG"},
{"name":"New Caledonia","code":"+687","currency":"CFP Franc","currencyCode":"XPF"},
{"name":"New Zealand","code":"+64","currency":"New Zealand Dollar","currencyCode":"NZD"},
{"name":"Nicaragua","code":"+505","currency":"Cordoba Oro","currencyCode":"NIO"},
{"name":"Niger","code":"+227","currency":"CFA Franc BCEAO","currencyCode":"XOF"},
{"name":"Nigeria","code":"+234","currency":"Naira","currencyCode":"NGN"},
{"name":"Niue","code":"+683","currency":"New Zealand Dollar","currencyCode":"NZD"},
{"name":"North Korea","code":"+850","currency":"North Korean Won","currencyCode":"KPW"},
{"name":"Northern Mariana Islands","code":"+1-670","currency":"US Dollar","currencyCode":"USD"},
{"name":"Norway","code":"+47","currency":"Norwegian Krone","currencyCode":"NOK"},
{"name":"Oman","code":"+968","currency":"Rial Omani","currencyCode":"OMR"},
{"name":"Pakistan","code":"+92","currency":"Pakistan Rupee","currencyCode":"PKR"},
{"name":"Palau","code":"+680","currency":"US Dollar","currencyCode":"USD"},
{"name":"Palestine","code":"+970","currency":"No universal currency","currencyCode":""},
{"name":"Panama","code":"+507","currency":"Balboa","currencyCode":"PAB"},
{"name":"Papua New Guinea","code":"+675","currency":"Kina","currencyCode":"PGK"},
{"name":"Paraguay","code":"+595","currency":"Guarani","currencyCode":"PYG"},
{"name":"Peru","code":"+51","currency":"Nuevo Sol","currencyCode":"PEN"},
{"name":"Philippines","code":"+63","currency":"Philippine Peso","currencyCode":"PHP"},
{"name":"Pitcairn","code":"+64","currency":"New Zealand Dollar","currencyCode":"NZD"},
{"name":"Poland","code":"+48","currency":"Zloty","currencyCode":"PLN"},
{"name":"Portugal","code":"+351","currency":"Euro","currencyCode":"EUR"},
{"name":"Puerto Rico","code":"+1-787, 1-939","currency":"US Dollar","currencyCode":"USD"},
{"name":"Qatar","code":"+974","currency":"Qatari Rial","currencyCode":"QAR"},
{"name":"Republic of the Congo","code":"+242","currency":"CFA Franc BEAC","currencyCode":"XAF"},
{"name":"Reunion","code":"+262","currency":"Euro","currencyCode":"EUR"},
{"name":"Romania","code":"+40","currency":"Romanian Leu","currencyCode":"RON"},
{"name":"Russia","code":"+7","currency":"Russian Ruble","currencyCode":"RUB"},
{"name":"Rwanda","code":"+250","currency":"Rwanda Franc","currencyCode":"RWF"},
{"name":"Saint Barthelemy","code":"+590","currency":"Euro","currencyCode":"EUR"},
{"name":"Saint Helena","code":"+290","currency":"Saint Helena Pound","currencyCode":"SHP"},
{"name":"Saint Kitts and Nevis","code":"+1-869","currency":"East Caribbean Dollar","currencyCode":"XCD"},
{"name":"Saint Lucia","code":"+1-758","currency":"East Caribbean Dollar","currencyCode":"XCD"},
{"name":"Saint Martin","code":"+590","currency":"Euro","currencyCode":"EUR"},
{"name":"Saint Pierre and Miquelon","code":"+508","currency":"Euro","currencyCode":"EUR"},
{"name":"Saint Vincent and the Grenadines","code":"+1-784","currency":"East Caribbean Dollar","currencyCode":"XCD"},
{"name":"Samoa","code":"+685","currency":"Tala","currencyCode":"WST"},
{"name":"San Marino","code":"+378","currency":"Euro","currencyCode":"EUR"},
{"name":"Sao Tome and Principe","code":"+239","currency":"Dobra","currencyCode":"STN"},
{"name":"Saudi Arabia","code":"+966","currency":"Saudi Riyal","currencyCode":"SAR"},
{"name":"Senegal","code":"+221","currency":"CFA Franc BCEAO","currencyCode":"XOF"},
{"name":"Serbia","code":"+381","currency":"Serbian Dinar","currencyCode":"RSD"},
{"name":"Seychelles","code":"+248","currency":"Seychelles Rupee","currencyCode":"SCR"},
{"name":"Sierra Leone","code":"+232","currency":"Leone","currencyCode":"SLL"},
{"name":"Singapore","code":"+65","currency":"Singapore Dollar","currencyCode":"SGD"},
{"name":"Sint Maarten","code":"+1-721","currency":"Netherlands Antillean Guilder","currencyCode":"ANG"},
{"name":"Slovakia","code":"+421","currency":"Euro","currencyCode":"EUR"},
{"name":"Slovenia","code":"+386","currency":"Euro","currencyCode":"EUR"},
{"name":"Solomon Islands","code":"+677","currency":"Solomon Islands Dollar","currencyCode":"SBD"},
{"name":"Somalia","code":"+252","currency":"Somali Shilling","currencyCode":"SOS"},
{"name":"South Africa","code":"+27","currency":"Rand","currencyCode":"ZAR"},
{"name":"South Korea","code":"+82","currency":"Won","currencyCode":"KRW"},
{"name":"South Sudan","code":"+211","currency":"South Sudanese Pound","currencyCode":"SSP"},
{"name":"Spain","code":"+34","currency":"Euro","currencyCode":"EUR"},
{"name":"Sri Lanka","code":"+94","currency":"Sri Lanka Rupee","currencyCode":"LKR"},
{"name":"Sudan","code":"+249","currency":"Sudanese Pound","currencyCode":"SDG"},
{"name":"Suriname","code":"+597","currency":"Surinam Dollar","currencyCode":"SRD"},
{"name":"Svalbard and Jan Mayen","code":"+47","currency":"Norwegian Krone","currencyCode":"NOK"},
{"name":"Swaziland","code":"+268","currency":"Lilangeni","currencyCode":"SZL"},
{"name":"Sweden","code":"+46","currency":"Swedish Krona","currencyCode":"SEK"},
{"name":"Switzerland","code":"+41","currency":"WIR Euro","currencyCode":"CHF"},
{"name":"Syria","code":"+963","currency":"Syrian Pound","currencyCode":"SYP"},
{"name":"Taiwan","code":"+886","currency":"New Taiwan Dollar","currencyCode":"TWD"},
{"name":"Tajikistan","code":"+992","currency":"Somoni","currencyCode":"TJS"},
{"name":"Tanzania","code":"+255","currency":"Tanzanian Shilling","currencyCode":"TZS"},
{"name":"Thailand","code":"+66","currency":"Baht","currencyCode":"THB"},
{"name":"Togo","code":"+228","currency":"CFA Franc BCEAO","currencyCode":"XOF"},
{"name":"Tokelau","code":"+690","currency":"New Zealand Dollar","currencyCode":"NZD"},
{"name":"Tonga","code":"+676","currency":"Pa’anga","currencyCode":"TOP"},
{"name":"Trinidad and Tobago","code":"+1-868","currency":"Trinidad and Tobago Dollar","currencyCode":"TTD"},
{"name":"Tunisia","code":"+216","currency":"Tunisian Dinar","currencyCode":"TND"},
{"name":"Turkey","code":"+90","currency":"Turkish Lira","currencyCode":"TRY"},
{"name":"Turkmenistan","code":"+993","currency":"Turkmenistan New Manat","currencyCode":"TMT"},
{"name":"Turks and Caicos Islands","code":"+1-649","currency":"US Dollar","currencyCode":"USD"},
{"name":"Tuvalu","code":"+688","currency":"Australian Dollar","currencyCode":"AUD"},
{"name":"U.S. Virgin Islands","code":"+1-340","currency":"US Dollar","currencyCode":"USD"},
{"name":"Uganda","code":"+256","currency":"Uganda Shilling","currencyCode":"UGX"},
{"name":"Ukraine","code":"+380","currency":"Hryvnia","currencyCode":"UAH"},
{"name":"United Arab Emirates","code":"+971","currency":"UAE Dirham","currencyCode":"AED"},
{"name":"United Kingdom","code":"+44","currency":"Pound Sterling","currencyCode":"GBP"},
{"name":"United States","code":"+1","currency":"US Dollar","currencyCode":"USD"},
{"name":"Uruguay","code":"+598","currency":"Uruguay Peso en Unidades Indexadas (URUIURUI)","currencyCode":"UYI"},
{"name":"Uzbekistan","code":"+998","currency":"Uzbekistan Sum","currencyCode":"UZS"},
{"name":"Vanuatu","code":"+678","currency":"Vatu","currencyCode":"VUV"},
{"name":"Vatican","code":"+379","currency":"Euro","currencyCode":"EUR"},
{"name":"Venezuela","code":"+58","currency":"Bolivar","currencyCode":"VEF"},
{"name":"Vietnam","code":"+84","currency":"Dong","currencyCode":"VND"},
{"name":"Wallis and Futuna","code":"+681","currency":"CFP Franc","currencyCode":"XPF"},
{"name":"Western Sahara","code":"+212","currency":"Moroccan Dirham","currencyCode":"MAD"},
{"name":"Yemen","code":"+967","currency":"Yemeni Rial","currencyCode":"YER"},
{"name":"Zambia","code":"+260","currency":"Zambian Kwacha","currencyCode":"ZMW"},
{"name":"Zimbabwe","code":"+263","currency":"Zimbabwe Dollar","currencyCode":"ZWL"}]`

const countryList = JSON.parse(countryListString)

const countryListAlpha3 = {
  ABW: 'Aruba',
  AFG: 'Afghanistan',
  AGO: 'Angola',
  AIA: 'Anguilla',
  ALA: 'Åland Islands',
  ALB: 'Albania',
  AND: 'Andorra',
  ANT: 'Netherlands Antilles',
  ARE: 'United Arab Emirates',
  ARG: 'Argentina',
  ARM: 'Armenia',
  ASM: 'American Samoa',
  ATA: 'Antarctica',
  ATF: 'French Southern Territories',
  ATG: 'Antigua and Barbuda',
  AUS: 'Australia',
  AUT: 'Austria',
  AZE: 'Azerbaijan',
  BDI: 'Burundi',
  BEL: 'Belgium',
  BEN: 'Benin',
  BES: 'Bonaire, Sint Eustatius and Saba',
  BFA: 'Burkina Faso',
  BGD: 'Bangladesh',
  BGR: 'Bulgaria',
  BHR: 'Bahrain',
  BHS: 'Bahamas',
  BIH: 'Bosnia and Herzegovina',
  BLM: 'Saint Barthelemy',
  BLR: 'Belarus',
  BLZ: 'Belize',
  BMU: 'Bermuda',
  BOL: 'Bolivia',
  BRA: 'Brazil',
  BRB: 'Barbados',
  BRN: 'Brunei',
  BTN: 'Bhutan',
  BVT: 'Bouvet Island',
  BWA: 'Botswana',
  CAF: 'Central African Republic',
  CAN: 'Canada',
  CCK: 'Cocos Islands',
  CHF: 'Switzerland',
  CHL: 'Chile',
  CHN: 'China',
  CIV: 'Ivory Coast',
  CMR: 'Cameroon',
  COD: 'Democratic Republic of the Congo',
  COG: 'Republic of the Congo',
  COK: 'Cook Islands',
  COL: 'Colombia',
  COM: 'Comoros',
  CPV: 'Cape Verde',
  CRI: 'Costa Rica',
  CUB: 'Cuba',
  CUW: 'Curaçao',
  CXR: 'Christmas Island',
  CYM: 'Cayman Islands',
  CYP: 'Cyprus',
  CZE: 'Czech Republic',
  DEU: 'Germany',
  DJI: 'Djibouti',
  DMA: 'Dominica',
  DNK: 'Denmark',
  DOM: 'Dominican Republic',
  DZA: 'Algeria',
  ECU: 'Ecuador',
  EGY: 'Egypt',
  ERI: 'Eritrea',
  ESH: 'Western Sahara',
  ESP: 'Spain',
  EST: 'Estonia',
  ETH: 'Ethiopia',
  FIN: 'Finland',
  FJI: 'Fiji',
  FLK: 'Falkland Islands',
  FRA: 'France',
  FRO: 'Faroe Islands',
  FSM: 'Micronesia',
  GAB: 'Gabon',
  GBR: 'United Kingdom',
  GEO: 'Georgia',
  GGY: 'Guernsey',
  GHA: 'Ghana',
  GIB: 'Gibraltar',
  GIN: 'Guinea',
  GLP: 'Guadeloupe',
  GMB: 'Gambia',
  GNB: 'Guinea-Bissau',
  GNQ: 'Equatorial Guinea',
  GRC: 'Greece',
  GRD: 'Grenada',
  GRL: 'Greenland',
  GTM: 'Guatemala',
  GUF: 'French Guiana',
  GUM: 'Guam',
  GUY: 'Guyana',
  HKG: 'Hong Kong',
  HMD: 'Heard Island and McDonald Islands',
  HND: 'Honduras',
  HRV: 'Croatia',
  HTI: 'Haiti',
  HUN: 'Hungary',
  IDN: 'Indonesia',
  IMN: 'Isle of Man',
  IND: 'India',
  IOT: 'British Indian Ocean Territory',
  IRL: 'Ireland',
  IRN: 'Iran',
  IRQ: 'Iraq',
  ISL: 'Iceland',
  ISR: 'Israel',
  ITA: 'Italy',
  JAM: 'Jamaica',
  JEY: 'Jersey',
  JOR: 'Jordan',
  JPN: 'Japan',
  KAZ: 'Kazakhstan',
  KEN: 'Kenya',
  KGZ: 'Kyrgyzstan',
  KHM: 'Cambodia',
  KIR: 'Kiribati',
  KNA: 'Saint Kitts and Nevis',
  KOR: 'South Korea',
  KWT: 'Kuwait',
  LAO: 'Laos',
  LBN: 'Lebanon',
  LBR: 'Liberia',
  LBY: 'Libya',
  LCA: 'Saint Lucia',
  LIE: 'Liechtenstein',
  LKA: 'Sri Lanka',
  LSO: 'Lesotho',
  LTU: 'Lithuania',
  LUX: 'Luxembourg',
  LVA: 'Latvia',
  MAC: 'Macau',
  MAF: 'Saint Martin',
  MAR: 'Morocco',
  MCO: 'Monaco',
  MDA: 'Moldova',
  MDG: 'Madagascar',
  MDV: 'Maldives',
  MEX: 'Mexico',
  MHL: 'Marshall Islands',
  MKD: 'Macedonia',
  MLI: 'Mali',
  MLT: 'Malta',
  MMR: 'Myanmar',
  MNE: 'Montenegro',
  MNG: 'Mongolia',
  MNP: 'Northern Mariana Islands',
  MOZ: 'Mozambique',
  MRT: 'Mauritania',
  MSR: 'Montserrat',
  MTQ: 'Martinique',
  MUS: 'Mauritius',
  MWI: 'Malawi',
  MYS: 'Malaysia',
  MYT: 'Mayotte',
  NAM: 'Namibia',
  NCL: 'New Caledonia',
  NER: 'Niger',
  NFK: 'Norfolk Island',
  NGA: 'Nigeria',
  NIC: 'Nicaragua',
  NIU: 'Niue',
  NLD: 'Netherlands',
  NOR: 'Norway',
  NPL: 'Nepal',
  NRU: 'Nauru',
  NZL: 'New Zealand',
  OMN: 'Oman',
  PAK: 'Pakistan',
  PAN: 'Panama',
  PCN: 'Pitcairn',
  PER: 'Peru',
  PHL: 'Philippines',
  PLW: 'Palau',
  PNG: 'Papua New Guinea',
  POL: 'Poland',
  PRI: 'Puerto Rico',
  PRK: 'North Korea',
  PRT: 'Portugal',
  PRY: 'Paraguay',
  PSE: 'Palestine',
  PYF: 'French Polynesia',
  QAT: 'Qatar',
  REU: 'Reunion',
  ROU: 'Romania',
  RUS: 'Russia',
  RWA: 'Rwanda',
  SAU: 'Saudi Arabia',
  SDN: 'Sudan',
  SEN: 'Senegal',
  SGP: 'Singapore',
  SGS: 'South Georgia and the South Sandwich Islands',
  SHN: 'Saint Helena',
  SJM: 'Svalbard and Jan Mayen',
  SLB: 'Solomon Islands',
  SLE: 'Sierra Leone',
  SLV: 'El Salvador',
  SMR: 'San Marino',
  SOM: 'Somalia',
  SPM: 'Saint Pierre and Miquelon',
  SRB: 'Serbia',
  SSD: 'South Sudan',
  STP: 'Sao Tome and Principe',
  SUR: 'Suriname',
  SVK: 'Slovakia',
  SVN: 'Slovenia',
  SWE: 'Sweden',
  SWZ: 'Swaziland',
  SXM: 'Sint Maarten',
  SYC: 'Seychelles',
  SYR: 'Syria',
  TCA: 'Turks and Caicos Islands',
  TCD: 'Chad',
  TGO: 'Togo',
  THA: 'Thailand',
  TJK: 'Tajikistan',
  TKL: 'Tokelau',
  TKM: 'Turkmenistan',
  TLS: 'East Timor',
  TON: 'Tonga',
  TTO: 'Trinidad and Tobago',
  TUN: 'Tunisia',
  TUR: 'Turkey',
  TUV: 'Tuvalu',
  TWN: 'Taiwan',
  TZA: 'Tanzania',
  UGA: 'Uganda',
  UKR: 'Ukraine',
  UMI: 'United States Minor Outlying Islands',
  URY: 'Uruguay',
  USA: 'United States',
  UZB: 'Uzbekistan',
  VAT: 'Holy See',
  VCT: 'Saint Vincent and the Grenadines',
  VEN: 'Venezuela',
  VGB: 'Vatican',
  VIR: 'U.S. Virgin Islands',
  VNM: 'Vietnam',
  VUT: 'Vanuatu',
  WLF: 'Wallis and Futuna',
  WSM: 'Samoa',
  XKX: 'Kosovo',
  YEM: 'Yemen',
  ZAF: 'South Africa',
  ZMB: 'Zambia',
  ZWE: 'Zimbabwe',
}

export { countryList, countryListAlpha3 }
