import React from 'react'
import { Collapse, Checkbox } from 'antd'
import Services from '../Services/Services'
import { Ternary } from '../../../config/utils'

const { Panel } = Collapse

const Department = ({
  data,
  onCheckboxClick,
  onServiceCheckboxClick,
  openSubServiceKeys,
  setOpenSubServiceKeys,
  showCheckBoxes = true,
  ...rest
}) => {
  return (
    <Panel
      {...rest}
      collapsible='header'
      header={data.name}
      key={data.id}
      extra={Ternary(
        showCheckBoxes,
        <Checkbox
          checked={data.checked}
          onClick={e => {
            if (onCheckboxClick) {
              onCheckboxClick(e, data.id)
            }
          }}
        />,
        null
      )}
    >
      <>
        <ul className='list-unstyled checkList' key={data.id}>
          {Array.isArray(data?.services) &&
            data?.services?.map(s => (
              <Services
                showCheckBoxes={showCheckBoxes}
                data={s}
                departmentId={data.id}
                key={s.id}
                onServiceCheckboxClick={onServiceCheckboxClick}
                openSubServiceKeys={openSubServiceKeys}
                setOpenSubServiceKeys={setOpenSubServiceKeys}
              />
            ))}
        </ul>
      </>
    </Panel>
  )
}

export default Department
