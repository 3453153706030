import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Checkbox, Alert } from 'antd'
import { useHistory } from 'react-router'
import {
  GetCurrentUser,
  Login,
  Logout,
  GetSuperAdminDetailsFromUid,
} from '../../../../services/user'
import { useDispatch } from 'react-redux'
import { actions } from '../../../../app/store'
import { SuperAdminRole, patterns } from '../../../../config/constants'

const tailLayout = {
  wrapperCol: {
    offset: 0,
    span: 24,
  },
}
const SignIn = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false)
  const [errorMesssage, setErrorMessage] = useState('')
  const [showLoader, setShowLoader] = useState(false)
  const [loginSuccess, setLoginSuccess] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  if (loginSuccess && GetCurrentUser()) history.push('/HotelManagement')

  useEffect(() => {
    if (errorMesssage) setTimeout(() => setErrorMessage(''), 3000)
  }, [errorMesssage])

  const handleLoginClick = () => {
    setShowLoader(true)

    const success = async () => {
      const uid = GetCurrentUser()?.uid
      if (uid) {
        const userInfo = await GetSuperAdminDetailsFromUid(uid)

        const isDeleted = userInfo.isDelete || false
        const isSuperUser = userInfo?.isSuperUser || false
        const isSuperAdminRole =
          !userInfo?.roles?.includes(SuperAdminRole) || false

        let errorLogin = false

        errorLogin = isSuperUser

        errorLogin = isSuperAdminRole

        errorLogin = errorLogin || isDeleted

        if (errorLogin) {
          setErrorMessage(
            'This email is not associated with the Inplass Admin Login'
          )
          Logout()
          setShowLoader(false)
          return
        } else {
          dispatch(actions.setUserInfo(userInfo))
          setLoginSuccess(true)
        }
      }

      setShowLoader(false)
      dispatch(actions.setLoading(false))
    }

    const error = err => {
      dispatch(actions.setLoading(false))
      const code = err?.code || ''
      let message = 'Incorrect Email ID/Password'
      if (code === 'auth/user-disabled')
        message = 'User account disabled by administrator'
      setShowLoader(false)
      setErrorMessage(message)
    }
    dispatch(actions.setLoading(true))
    Login(rememberMe, email, password, success, error)
  }
  return (
    <>
      <div className='signin-wrp'>
        <div className='bgPart'>
          <figure>
            <img
              className='img-fluid'
              src='/images/inplasslogo.svg'
              alt=''
            ></img>
          </figure>
        </div>
        <div className='formPart'>
          <div className='form-wrap'>
            <h1>Log In</h1>
            <Form
              layout='vertical'
              validateTrigger=''
              onFinish={handleLoginClick}
            >
              <div className='row'>
                <div className='col-12'>
                  <div className='form-group sign-field'>
                    <Form.Item
                      name='email'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your Email ID',
                        },
                        {
                          type: 'email',
                          message:
                            'Your Email ID  must be in the format name@domain.com',
                        },
                      ]}
                    >
                      <Input
                        placeholder='name@domain.com'
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        onKeyUp={() => setErrorMessage('')}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='form-group sign-field'>
                    <Form.Item
                      name='password'
                      rules={[
                        {
                          required: true,
                          message: 'Enter your password',
                        },
                        {
                          pattern: patterns.password.regex,
                          message: 'Incorrect password',
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder='Password'
                        value={password}
                        onKeyUp={() => setErrorMessage('')}
                        onChange={e => setPassword(e.target.value)}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className='col-12 d-flex justify-content-between'>
                  <Form.Item
                    {...tailLayout}
                    name='remember'
                    onChange={e => setRememberMe(e.target.checked)}
                    valuePropName='checked'
                  >
                    <Checkbox value={rememberMe}>Remember me</Checkbox>
                  </Form.Item>
                  <a className='forgotlink' href='/ForgotPassword'>
                    Forgot Password?
                  </a>
                </div>
              </div>

              <Form.Item>
                <Button
                  className='continuebtn'
                  block
                  loading={showLoader}
                  htmlType='submit'
                >
                  Log In
                </Button>
              </Form.Item>
              {errorMesssage && (
                <Alert
                  message={errorMesssage}
                  type='error'
                  showIcon
                  className='mt-2'
                />
              )}
            </Form>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignIn
