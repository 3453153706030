import React from 'react'
import SuccessModalContent from './SuccessModalContent'
import { Modal } from 'antd'

const SuccessModal = ({ showSuccessModal = false, title, setShowSuccessModal }) => {
  return (
    <>
      <Modal
        visible={showSuccessModal}
        onCancel={setShowSuccessModal}
        className='successModal'
        footer={null}
        centered
      >
        <SuccessModalContent title={title} />
      </Modal>
    </>
  )
}

export default SuccessModal
