/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import { Input, Upload, Select, Modal, Button, Form, Breadcrumb } from 'antd'

import Header from '../../Common/Header/Header'

import SideMenu from '../../Common/Sidemenu/Sidemenu'

const { Option } = Select
const MyProfile = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const prefixSelector = (
    <Form.Item name='prefix' noStyle>
      <Select style={{ width: 70 }}>
        <Option value='86'>+86</Option>
        <Option value='87'>+87</Option>
      </Select>
    </Form.Item>
  )

  return (
    <>
      <Header></Header>
      <SideMenu></SideMenu>
      <section className='mainContent profile-wrp'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              {/* Use this "PageNamecard" component with current page name */}
              {/* <PageNamecard></PageNamecard> */}
              <div className='Pagenamecard-wrp'>
                <div>
                  <h1>My Profile</h1>
                  <Breadcrumb>
                    <Breadcrumb.Item>Hotel Admin</Breadcrumb.Item>
                    <Breadcrumb.Item>My Profile</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
                <figure>
                  <img src='images/pageheadimg.svg'></img>
                </figure>
              </div>
            </div>
            <div className='col-12 col-xl-12'>
              <div className='tablefilter-wrp'>
                <div className='form-row justify-content-end'>
                  <div className='col-auto'>
                    <Button onClick={showModal} className='cmnBtn'>
                      Edit Profile
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='profileCard'>
            <div className='row'>
              <div className='col-12 col-lg-auto'>
                <div className='profileMainDetails'>
                  <div>
                    <figure>
                      <img className='img-fluid' src='images/profile.png'></img>
                    </figure>
                    <h4>Jina Martin</h4>
                    <h5>servicename@implass.com</h5>
                  </div>
                  <p>
                    Short bio of hotel admin..... Lorem ipsum dolor sit amet, consectetur adipiscing
                    eli.
                  </p>
                </div>
              </div>
              <div className='col-12 col-lg'>
                <div className='profileSubDetails'>
                  <ul className='list-unstyled'>
                    <li>
                      <h6>Email ID</h6>
                      <span>servicename@implass.com</span>
                    </li>
                    <li>
                      <h6>Title</h6>
                      <span>Le Meridian RAK Administration Head</span>
                    </li>
                    <li>
                      <h6>Phone Number</h6>
                      <span>+86 542145 544 00</span>
                    </li>
                    <li>
                      <h6>Password</h6>
                      <span>*******************</span>
                    </li>
                    <li>
                      <h6>Country</h6>
                      <span>Maradu, Kochi, Kerala</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        title='Edit Profile'
        visible={isModalVisible}
        centered
        onOk={handleOk}
        onCancel={handleCancel}
        className='addUsermodal cmnModal'
        footer={null}
      >
        <div className='imageUpload-wrp'>
          <figure>
            <div className='upload-figin'>
              <img src='images/profile.png'></img>
            </div>
            <button className='removebtn'>
              <img src='images/close.svg'></img>
            </button>
          </figure>
          <div className='uploadbtn-wrp'>
            <Upload>
              <Button>Upload Photo</Button>
            </Upload>
            <p>Please upload At least 256 x 256px PNG or JPEG File</p>
          </div>
        </div>

        <Form
          layout='vertical'
          initialValues={{
            prefix: '86',
          }}
          footer={null}
        >
          <div className='row'>
            <div className='col-12 col-md-6'>
              <div className='form-group cmn-input' id='fullname'>
                <Form.Item label='Full Name'>
                  <Input placeholder='Full Name' />
                </Form.Item>
              </div>
            </div>
            <div className='col-12 col-md-6' id='email'>
              <div className='form-group cmn-input'>
                <Form.Item label='Email'>
                  <Input placeholder='Email' />
                </Form.Item>
              </div>
            </div>

            <div className='col-12 col-md-6' id='title'>
              <div className='form-group cmn-input'>
                <Form.Item label='Title'>
                  <Input placeholder='Title' />
                </Form.Item>
              </div>
            </div>

            <div className='col-12 col-md-6' id='country'>
              <div className='form-group cmn-input'>
                <Form.Item label='Country'>
                  <Input placeholder='Country' />
                </Form.Item>
              </div>
            </div>

            <div className='col-12 col-md-6' id='phone'>
              <div className='form-group contact-input'>
                <Form.Item name='phone' label='Contact Number'>
                  <Input addonBefore={prefixSelector} />
                </Form.Item>
              </div>
            </div>
            <div className='col-12 col-md-6'>
              <div className='form-group contact-input'>
                <Form.Item label='Password'>
                  <Input.Password placeholder='Password' />
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>

        <div className='modalFooter'>
          <Button className='grayBtn' key='back' onClick={handleCancel}>
            Cancel
          </Button>

          <Button className='blueBtn ml-3 ml-lg-4' key='submit' onClick={handleOk}>
            Submit
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default MyProfile
