/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import {
  Input,
  Table,
  Select,
  Modal,
  Button,
  Upload,
  Form,
  Alert,
  message,
  Space,
  Collapse,
  Skeleton,
  Empty,
  Checkbox,
} from 'antd'
import { EyeOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'

import {
  AddNewHotel,
  UpdateHotel,
  DeleteHotelProfile,
  filteredHotelByName,
  filteredHotelByCountry,
  filteredHotelByCity,
  filteredHotelByStatus,
  EditHotel,
  GetHotelListener,
  filteredHotelByGroup,
  GetHotelDomain,
} from '../../../services/hotel'

import Header from '../../Common/Header/Header'
import SideMenu from '../../Common/Sidemenu/Sidemenu'
import PageNamecard from '../../Common/PageNameCard/Pagenamecard'

import CountGreenCard from '../../Common/CountCard/CountGreenCard/CountGreenCard'

import SubscriptionCard from '../../Common/SubscriptionCard/SubscriptionCard'
import {
  departmentList,
  staffDepartmentList,
  SanitizeNumber,
  Ternary,
  modalTypeConstant,
  isValidUrl,
  eIntergationType,
  Sort,
  GetAxiosHeaders,
  ePmsType,
} from '../../../config/utils'

import stateList from '../../../config/stateList'
import { countryList } from '../../../config/countryList'
import DeleteModal from '../../Common/Modals/DeleteModal'
import SuccessModal from '../../Common/Modals/SuccessModal'
import { APIs, patterns, validateAlphaNumeric } from '../../../config/constants'
import axios from 'axios'
import { AddSubscriptionListener } from '../../../services/subscription'
import Department from '../../Common/Department/Department'
import { FetchCustomDepartments } from '../../../services/department'
import AddEditHotelGroup from './AddEditHotelGroup'
import { hotelGroupListerner } from '../../../services/hotelGroup'
const { Search } = Input
const { Option } = Select

const numericValidation = [
  {
    pattern: '[0-9.]+',
    message: 'Please enter a numeric value',
  },
]

const statusList = [
  { id: 'all', name: 'All' },
  { id: 'active', name: 'Active' },
  { id: 'inactive', name: 'Inactive' },
]

const classList = [
  { id: 'oneStar', name: '1 Star' },
  { id: 'twoStar', name: '2 Star' },
  { id: 'threeStar', name: '3 Star' },
  { id: 'fourStar', name: '4 Star' },
  { id: 'fiveStar', name: '5 Star' },
]

const statusFilterLabel = 'Status'
const countryFilterLabel = 'Country '
const cityFilterLabel = 'City'
const groupFilterLabel = 'Group'

const filterHotels = ({
  hotels,
  filteredName,
  filteredCountry,
  filteredCity,
  filteredGroup,
  filteredStatus,
  setFilteredHotels,
}) => {
  let filteredHotel = [...hotels]

  if (filteredName !== '') {
    filteredHotel = filteredHotelByName(filteredHotel, filteredName)
  }

  if (
    filteredCountry !== countryFilterLabel &&
    filteredCountry.toLowerCase() !== 'all'
  ) {
    filteredHotel = filteredHotelByCountry(filteredHotel, filteredCountry)
  }

  if (
    filteredCity !== cityFilterLabel &&
    filteredCity.toLowerCase() !== 'all'
  ) {
    filteredHotel = filteredHotelByCity(filteredHotel, filteredCity)
  }

  if (
    filteredGroup !== groupFilterLabel &&
    filteredGroup.toLowerCase() !== 'all'
  ) {
    filteredHotel = filteredHotelByGroup(filteredHotel, filteredGroup)
  }

  if (
    filteredStatus !== statusFilterLabel &&
    filteredStatus.toLowerCase() !== 'all'
  ) {
    filteredHotel = filteredHotelByStatus(filteredHotel, filteredStatus)
  }

  setFilteredHotels(filteredHotel)
}

const GetCustomDepartmentModalTitle = data => {
  if (Array.isArray(data) && data.length) {
    return 'Custom Departments & Services'
  }
  return ''
}

const LocationTypeCount = ({ defaultLocationTypes }) => (
  <Input type='number' value={defaultLocationTypes?.length || 0} disabled />
)

const HotelManagement = () => {
  const [loadingData, setLoadingData] = useState(false)

  const [form] = Form.useForm()
  const [showLoader, setShowLoader] = useState(false)
  const [createUserError, setCreateUserError] = useState('')

  const [hotelLogo, setHotelLogo] = useState()
  const [hotelLogoUrl, setHotelLogoUrl] = useState()
  const [hotelLogoError, setHotelLogoError] = useState('')

  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [hotelName, setHotelName] = useState('')
  const [hotelId, setHotelId] = useState('')
  const [emailId, setEmailId] = useState('')
  const [adminEmail, setAdminEmail] = useState('')
  const [adminPassword, setAdminPassword] = useState('')
  const [adminName, setAdminName] = useState('')
  const [website, setWebsite] = useState('')
  const [city, setCity] = useState('')
  const [countryName, setCountry] = useState('')
  const [className, setClass] = useState('')
  const [address, setAddress] = useState('')
  const [departmentCount, setDepartmentCount] = useState('6')
  const [staffDepartmentCount, setStaffDepartmentCount] = useState('15')
  const [contactNumber, setContactNumber] = useState('')
  const [roomCount, setRoomCount] = useState('')
  const [subscription, setSubscription] = useState('')
  const [countryCode, setCountryCode] = useState('')
  const [status, setStatus] = useState('')
  const [custDeptNo, setCustDeptNo] = useState('')

  const [filteredName, setFilteredName] = useState('')
  const [filteredCountry, setFilteredCountry] = useState(countryFilterLabel)
  const [filteredStatus, setFilteredStatus] = useState(statusFilterLabel)
  const [filteredHotels, setFilteredHotels] = useState([])
  const [countrylist, setCountrylist] = useState([])
  const [stateName, setState] = useState('')
  const [filteredCity, setFilteredCity] = useState(cityFilterLabel)
  const [filteredGroup, setFilteredGroup] = useState(groupFilterLabel)
  const [citylist, setCitylist] = useState([])
  const [editingHotel, setEditingHotel] = useState(false)
  const [hotelImageName, setHotelImageName] = useState(false)
  const [deleteProfileImage, setDeleteProfileImage] = useState(false)
  const [showCustomDeptList, setShowCustomDeptList] = useState(false)
  const [hotelData, setHotelData] = useState({})
  const [hotelGroupId, setHotelGroupId] = useState('')
  const [userAction, setUserAction] = useState('')

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [hotelToDelete, setHotelToDelete] = useState()
  const [filteredState, setFilteredState] = useState([])

  const [loadingHotelCustomDepartments, setLoadingHotelCustomDepartments] =
    useState(false)
  const [hotelCustomDepartments, setHotelCustomDepartments] = useState([])
  const [hotelCustomServices, setHotelCustomServices] = useState({})

  const [existingHotelAdmin, setExistingHotelAdmin] = useState(null)
  const [noOfRecurringTask, setNoOfRecurringTask] = useState(null)
  const [hotelGroupModal, setHotelGroupModal] = useState({
    status: false,
    data: null,
    type: '',
  })

  const [integrationType, setIntegrationType] = useState(eIntergationType.NONE)
  const [pmsType, setPmsType] = useState(ePmsType.NONE)
  const [gatewayIP, setGatewayIP] = useState('')
  const [propertyId, setPropertyId] = useState('')
  const [apiKey, setApiKey] = useState('')
  const [enableGuestAppLogoSetting, setEnableGuestAppLogoSetting] =
    useState(false)
  const [enablePseudoBrowserCheckSetting, setEnablePseudoBrowserCheckSetting] =
    useState(false)
  const [enableDepartmentCustomImage, setEnableDepartmentCustomImage] =
    useState(false)
  const [enableRecurringTaskScheduler, setEnableRecurringTaskScheduler] =
    useState(false)
  const [enableIva, setEnableIva] = useState(false)
  const [hotelDomain, setHotelDomain] = useState(null)

  const {
    subscription: subscriptionList,
    subscriptionListenerAdded,
    hotelListenerAdded,
    hotels,
    activeHotelCount,
    hotelGroups,
    defaultLocationTypes,
  } = useSelector(state => state)
  const dispatch = useDispatch()

  const getData = async () => {
    try {
      let newCountryList = []
      let newCityList = []
      hotels.forEach(h => {
        newCountryList.push(h.countryName)
        newCityList.push(h.city)
      })

      newCountryList = [...new Set(newCountryList)]
      newCountryList.sort()
      newCountryList.unshift('All')
      setCountrylist(newCountryList)

      newCityList = [...new Set(newCityList)]
      newCityList.sort()
      newCityList.unshift('All')
      setCitylist(newCityList)
    } catch (error) {
      console.log({ error })
    }
  }

  const hideDeptList = () => setShowCustomDeptList(false)

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotels])

  useEffect(() => {
    if (countryName) {
      const selectedCountryValue = countryList.find(c => c.name === countryName)
      if (selectedCountryValue) setCountryCode(selectedCountryValue.code)
    }
    let filterState = [...stateList]
    filterState = filterState.filter(user =>
      user.country?.includes(countryName)
    )
    if (filterState[0]) setFilteredState(filterState[0].states)
    else {
      setFilteredState([])
    }
  }, [countryName])

  useEffect(() => {
    filterHotels({
      hotels,
      filteredName,
      filteredCountry,
      filteredCity,
      filteredGroup,
      filteredStatus,
      setFilteredHotels,
    })
  }, [
    hotels,
    filteredName,
    filteredCountry,
    filteredGroup,
    filteredCity,
    filteredStatus,
    setFilteredHotels,
  ])

  useEffect(() => {
    if (createUserError) setTimeout(() => setCreateUserError(''), 3000)
    if (hotelLogoError) setTimeout(() => setHotelLogoError(''), 3000)
  }, [createUserError, hotelLogoError])

  useEffect(() => {
    hotelGroupListerner(dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFinish = async () => {
    try {
      setShowLoader(true)
      setCreateUserError('')

      const groupInfoObj = {
        groupId: hotelGroupId,
        groupName: hotelGroups?.hotelGroupById?.[hotelGroupId]?.name || '',
      }

      const hotel = {
        hotelName,
        website,
        adminEmail,
        emailId,
        adminName,
        city,
        countryName,
        stateName,
        className,
        roomCount,
        custDeptNo: custDeptNo ? custDeptNo : 0,
        address,
        contactNumber,
        departmentCount,
        staffDepartmentCount,
        subscription: subscription ? subscription : '',
        countryCode,
        status: status ? status : 'active',
        integrationType,
        pmsType,
        apiKey,
        NoOfRecurringTask: noOfRecurringTask,
        gatewayIP,
        propertyId,
        enableGuestAppLogoSetting,
        enablePseudoBrowserCheckSetting,
        enableDepartmentCustomImage,
        enableRecurringTaskScheduler,
        enableIva,
        ...groupInfoObj,
      }

      if (!enableGuestAppLogoSetting) {
        hotel['hotelLogoOrName'] = 'name'
        hotel['hotelLogoOrNameAlignment'] = 'centre'
      }

      const user = {
        contactNumber: '',
        contactNumberPrefix: '',
        email: adminEmail,
        name: adminName,
        city,
        countryName,
        stateName,
        ...groupInfoObj,
      }

      const userHotel = {
        ...user,
        status: userAction ? status : 'active',
        roles: ['HotelAdmin'],
        departmentId: '',
        emailVerified: true,
        hotelName: hotelName,
        hotelStatus: userAction ? status : 'active',
      }

      if (editingHotel) {
        const { success: editSuccess, message: editErrorMessage } =
          await EditHotel(
            hotelLogo,
            hotel,
            hotelLogoUrl,
            hotelImageName,
            deleteProfileImage,
            hotelId
          )
        if (!editSuccess) {
          setCreateUserError(editErrorMessage)
          return
        }
      } else {
        const {
          success,
          message: addNewHotelMessage,
          subDomain,
        } = await AddNewHotel(
          adminEmail,
          adminPassword,
          hotelLogo,
          hotel,
          user,
          userHotel
        )

        if (!success) {
          setCreateUserError(addNewHotelMessage)
          return
        } else {
          const hotelUrl =
            process.env.REACT_APP_HOTEL_ADMIN_URL + '/SignIn/' + subDomain

          const dynamicUserPwd = existingHotelAdmin
            ? 'Use your existing password to login'
            : adminPassword

          let headers = await GetAxiosHeaders()

          axios
            .post(
              APIs.SENDONESIGNALEMAILTOUSER,
              {
                project_id: 1,
                email: adminEmail,
                template_id: '1',
                subject: 'Inplass welcomes you',
                variables: {
                  '%name%': adminName,
                  '%hotelurl%': hotelUrl,
                  '%hotelemail%': adminEmail,
                  '%password%': dynamicUserPwd,
                },
              },
              { headers }
            )
            .then(response => console.log(response))
        }
      }

      setIsModalVisible(false)
      setShowSuccessModal(true)
      setCountry('')
    } catch (error) {
      console.log({ error })
      console.log(error?.message)
    } finally {
      setShowLoader(false)
    }
  }

  const allowedRatios = ['512x512', '512x288']
  const validateProfileImage = file => {
    const allowedImageSizeMb = 1
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    const lessThan3Mb = file.size / 1024 <= 1024 * allowedImageSizeMb

    if (!isJpgOrPng) {
      setHotelLogoError('Invalid format, please upload JPEG or PNG')
    } else if (!lessThan3Mb) {
      setHotelLogoError('Maximum upload size is 1mb')
    } else {
      setHotelLogoError('')
    }

    if (isJpgOrPng && lessThan3Mb) {
      setHotelLogo(file)

      const fileReader = new FileReader()
      fileReader.addEventListener('load', e => {
        let image = new Image()
        image.src = e.target.result
        image.onload = function () {
          const imageRatio = `${this.width}x${this.height}`
          if (allowedRatios.length && !allowedRatios.includes(imageRatio)) {
            setHotelLogoError(
              `Image ratio should be ${allowedRatios.join(' or ')}`
            )
          } else {
            setHotelLogoUrl(fileReader.result)
          }
        }
      })
      fileReader.readAsDataURL(file)
    } else {
      setHotelLogo(null)
      setHotelLogoUrl(null)
    }

    return isJpgOrPng && lessThan3Mb ? true : Upload.LIST_IGNORE
  }

  const CustomDepartmentNumberValidation = (
    _fieldProps,
    deptNo,
    errormessage
  ) => ({
    validator(_, value) {
      if (!value && deptNo > 0) return Promise.reject(new Error(errormessage))
      if (!value || value === undefined) return Promise.resolve()
      value = +value
      if (value >= deptNo) {
        return Promise.resolve()
      }
      return Promise.reject(new Error(errormessage))
    },
  })

  const clearProfileImage = () => {
    setHotelLogo(null)
    setHotelLogoUrl(null)
  }

  useEffect(() => {
    setUserAction(editingHotel)
    if (!isModalVisible) setEditingHotel(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible])

  useEffect(() => {
    AddSubscriptionListener(dispatch, subscriptionListenerAdded)
  }, [dispatch, subscriptionListenerAdded])

  useEffect(() => {
    GetHotelListener(dispatch, hotelListenerAdded)
  }, [dispatch, hotelListenerAdded])

  const showModal = () => {
    setIsModalVisible(true)
    setExistingHotelAdmin(null)
    clearProfileImage()
    setContactNumber('')
    setSubscription('')
    setCountryCode('')
    setHotelData({ custDeptNo: 0 })
    setEnableGuestAppLogoSetting(false)
    setEnablePseudoBrowserCheckSetting(false)
    setEnableDepartmentCustomImage(false)
    setEnableRecurringTaskScheduler(false)
    setEnableIva(false)
    form.resetFields()
  }

  const handleContactChange = e => {
    let contactNumberValue = SanitizeNumber(e.target.value)
    setContactNumber(contactNumberValue)
    form.setFieldsValue({
      contactNumber: contactNumberValue,
    })
  }

  const handleStatusChange = async (selectedStatus, id) => {
    try {
      setLoadingData(true)
      const { success } = await UpdateHotel(id, { status: selectedStatus })
      setLoadingData(false)

      if (!success) message.error(`Problem updating user's status`)
      else {
        setStatus(selectedStatus)
      }
    } catch (error) {
      console.log({ error })
      setLoadingData(false)
    }
  }

  const resetFilter = () => {
    setFilteredName('')
    setFilteredCity(cityFilterLabel)
    setFilteredGroup(groupFilterLabel)
    setFilteredCountry(countryFilterLabel)
    setFilteredStatus(statusFilterLabel)
  }

  const editHotelProfile = async hotel => {
    const res = await GetHotelDomain(hotel.id)
    setHotelDomain(res)
    setHotelData(hotel)
    setHotelName(hotel.hotelName)
    setEmailId(hotel.emailId)
    setAdminName(hotel.adminName)
    setAdminEmail(hotel.adminEmail)
    setContactNumber(hotel.contactNumber)
    setWebsite(hotel.website)
    setHotelLogoUrl(hotel.hotelLogo)
    setHotelId(hotel.id)
    setCity(hotel.city)
    setCountry(hotel.countryName)
    setState(hotel.stateName)
    setCountryCode(hotel.countryCode)
    setClass(hotel.className)
    setDepartmentCount(hotel.departmentCount)
    setStaffDepartmentCount('15')
    setRoomCount(hotel.roomCount)
    setCustDeptNo(hotel.custDeptNo ? hotel.custDeptNo : 0)
    setSubscription(hotel.subscription)
    setAddress(hotel.address)
    setStatus(hotel.status)

    setHotelLogo(null)
    setHotelImageName(hotel.hotelLogoImageName)
    setEditingHotel(true)
    setDeleteProfileImage(false)
    setIsModalVisible(true)
    setHotelGroupId(hotel.groupId)
    setNoOfRecurringTask(hotel.NoOfRecurringTask)
    setIntegrationType(
      hotel.integrationType ? hotel.integrationType : eIntergationType.NONE
    )
    setPmsType(hotel.pmsType ? hotel.pmsType : ePmsType.NONE)
    setApiKey(hotel?.apiKey || '')
    setGatewayIP(hotel?.gatewayIP || '')
    setPropertyId(hotel?.propertyId || '')
    setEnableGuestAppLogoSetting(hotel?.enableGuestAppLogoSetting || false)
    setEnablePseudoBrowserCheckSetting(
      hotel?.enablePseudoBrowserCheckSetting || false
    )
    setEnableDepartmentCustomImage(hotel?.enableDepartmentCustomImage || false)
    setEnableRecurringTaskScheduler(
      hotel?.enableRecurringTaskScheduler || false
    )
    setEnableIva(hotel?.enableIva || false)
    form.setFieldsValue({
      enableGuestAppLogoSetting: hotel?.enableGuestAppLogoSetting || false,
      enablePseudoBrowserCheckSetting:
        hotel?.enablePseudoBrowserCheckSetting || false,
      enableDepartmentCustomImage: hotel?.enableDepartmentCustomImage || false,
      enableRecurringTaskScheduler:
        hotel?.enableRecurringTaskScheduler || false,
      enableIva: hotel?.enableIva || false,
      hotelName: hotel.hotelName,
      emailId: hotel.emailId,
      adminName: hotel.adminName,
      adminEmail: hotel.adminEmail,
      contactNumber: hotel.contactNumber,
      website: hotel.website,
      city: hotel.city,
      countryName: hotel.countryName,
      stateName: hotel.stateName,
      NoOfRecurringTask: hotel.NoOfRecurringTask,
      countryCode: hotel.countryCode,
      className: hotel.className,
      departmentCount: hotel.departmentCount,
      staffDepartmentCount: hotel.staffDepartmentCount
        ? hotel.staffDepartmentCount
        : '15',
      roomCount: hotel.roomCount,
      custDeptNo: hotel.custDeptNo ? hotel.custDeptNo : 0,
      subscription: hotel.subscription,
      address: hotel.address,
      status: hotel.status,
      adminPassword: 'Dummy@123',
      hotelGroupId: hotel.groupId,
      intergationType: hotel.integrationType
        ? hotel.integrationType
        : eIntergationType.NONE,
      pmsType: hotel.pmsType ? hotel.pmsType : ePmsType.NONE,
      apiKey: hotel?.apiKey || '',
      gatewayIP: hotel?.gatewayIP || '',
      propertyId: hotel?.propertyId || '',
    })
  }

  const deleteHotelProfile = async () => {
    try {
      setShowDeleteConfirmation(false)
      setLoadingData(true)
      const { success, message: deleteUserProfileMessage } =
        await DeleteHotelProfile(hotelToDelete.id)
      setLoadingData(false)
      if (success) {
        setSuccessMessage('Deleted successfully')
        setShowSuccessModal(true)
        setTimeout(() => {
          setShowSuccessModal(false)
          setSuccessMessage('')
        }, 3000)
      } else {
        message.error(deleteUserProfileMessage)
      }
    } catch (error) {
      console.log(error)
      message.error('Somwthing went wrong! Please try again!')
    }
  }

  const handleCustomDepartmentEyeClick = async id => {
    setLoadingHotelCustomDepartments(true)
    setShowCustomDeptList(true)

    const { deptList, services } = await FetchCustomDepartments(id)
    setHotelCustomDepartments(deptList)
    setHotelCustomServices(services)

    setLoadingHotelCustomDepartments(false)
  }

  const columns = [
    {
      title: 'Hotel Name',
      dataIndex: 'hotelName',
      width: 160,
      render: (_hotelName, row) => (
        <div className='tableuser sa-tableuser'>
          <figure>
            <img
              className='hotelLogo'
              src={row.hotelLogo ? row.hotelLogo : 'images/cam.png'}
              height={25}
              width={25}
              alt=''
            ></img>
          </figure>
          <span>{_hotelName} </span>
        </div>
      ),
    },
    {
      title: 'Group',
      dataIndex: 'groupName',
      width: 100,
    },
    {
      title: 'Country',
      dataIndex: 'countryName',
      width: 100,
    },
    {
      title: 'City',
      dataIndex: 'city',
      width: 100,
    },
    {
      title: 'Guest Department',
      dataIndex: 'departmentCount',
      width: 120,
    },
    {
      title: 'Staff Department',
      dataIndex: 'staffDepartmentCount',
      width: 110,
    },
    {
      title: 'Cust. Dept.',
      dataIndex: 'custDeptNo',
      width: 100,
      render: (noOfCustDepts, row) => {
        return (
          <div className='departmentcount-wrp'>
            <span className='depcount'>
              {row.customDepartmentUsed ? row.customDepartmentUsed : 0}/
              {noOfCustDepts}
            </span>
            <Space onClick={() => handleCustomDepartmentEyeClick(row.id)}>
              <EyeOutlined />
            </Space>
          </div>
        )
      },
    },
    {
      title: 'Rooms',
      dataIndex: 'roomCount',
      width: 100,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 90,
      render: (_status, row) => {
        return (
          <>
            <Select
              value={_status}
              bordered={false}
              onChange={selectedStatus =>
                handleStatusChange(selectedStatus, row.id)
              }
              className={`${_status}Status`}
            >
              <Option value={statusList[1].id}>{statusList[1].name}</Option>
              <Option value={statusList[2].id}>{statusList[2].name}</Option>
            </Select>
          </>
        )
      },
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      width: 80,
      render: (_id, hotel) => (
        <div className='tableactionbtn-grp'>
          <button onClick={() => editHotelProfile(hotel)}>
            <img src='/images/sa-tedit.svg'></img>
          </button>
          <button
            onClick={e => {
              e.preventDefault()
              setShowDeleteConfirmation(true)
              setHotelToDelete(hotel)
            }}
          >
            <img src='/images/sa-tdelete.svg'></img>
          </button>
        </div>
      ),
    },
  ].sort()

  const handleCancel = () => {
    setIsModalVisible(false)
    setCountry('')
  }

  const hideDeleteConfirmation = () => setShowDeleteConfirmation(false)

  const selectedCountry = countryList.find(c => c.name === countryName)

  const prefixSelector = (
    <Form.Item name='prefix' noStyle>
      {selectedCountry ? selectedCountry.code : null}
    </Form.Item>
  )

  const getSuccessModalMessage = () => {
    if (successMessage) return successMessage
    return userAction ? 'Changes saved sucessfully' : 'Hotel added successfully'
  }

  const CustomDepartmentList = () => {
    if (hotelCustomDepartments.length) {
      return (
        <Form
          layout='vertical'
          onFinish={hideDeptList}
          form={form}
          validateTrigger
        >
          <div className='row'>
            <div className='col-12'>
              <div className='departmentCollapse-wrp cmnCollapse-wrp'>
                <Collapse>
                  {hotelCustomDepartments?.map(d => (
                    <Department
                      showCheckBoxes={false}
                      data={{ ...d, services: hotelCustomServices[d.id] }}
                      key={d.id}
                    />
                  ))}
                </Collapse>
              </div>
            </div>
          </div>
          <div className='modalFooter'>
            <Button className='blueBtn ml-4' key='submit' htmlType='submit'>
              OK
            </Button>
          </div>
        </Form>
      )
    }

    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="This hotel haven't added any custom department"
      />
    )
  }

  const DepartmentList = ({ data, staffDepartment }) => {
    return (
      <>
        <Select
          value={staffDepartment ? staffDepartmentCount : departmentCount}
          disabled={true}
          onChange={department =>
            staffDepartment
              ? setStaffDepartmentCount(department)
              : setDepartmentCount(department)
          }
        >
          {data.map(s => (
            <Option value={s} key={s}>
              {s}
            </Option>
          ))}
        </Select>
      </>
    )
  }

  function closeHotelGroupModal() {
    setHotelGroupModal({
      status: false,
      data: null,
      type: '',
    })
  }

  let hotelGroupModaData = {
    ...hotelGroupModal,
    showLoader,
    setShowLoader,
    closeHotelGroupModal,
  }

  let hotelGroupModalData = Sort(
    Object.values(hotelGroups.hotelGroupById),
    'name'
  )

  function handleAdminEmailChange(e) {
    const adminEmailId = e.target.value.toLowerCase()
    setAdminEmail(adminEmailId)
    form.setFieldsValue({ adminEmail: adminEmailId })
    const foundAdmin = hotels.find(h => h.adminEmail === adminEmailId)

    if (foundAdmin) {
      setExistingHotelAdmin(foundAdmin)
    } else {
      setExistingHotelAdmin(null)
    }
  }

  useEffect(() => {
    if (existingHotelAdmin) {
      setAdminName(existingHotelAdmin.adminName)

      form.setFieldsValue({
        adminName: existingHotelAdmin.adminName,
      })
    }
  }, [existingHotelAdmin, form])

  return (
    <>
      <Header></Header>
      <SideMenu></SideMenu>
      <section className='mainContent hotelmgmt-wrp'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNamecard
                title='Hotel Management'
                breadcrumb={['Super Admin', 'Hotel Management']}
              />
            </div>

            <div className='col-12 col-md-12 col-lg-8'>
              <div className='tablefilter-wrp'>
                <div className='form-row'>
                  <div className='col-4 col-xl col-xl-3 mb-2'>
                    <div className='searchbox'>
                      <Search
                        placeholder='Search by Hotel Name'
                        value={filteredName}
                        onChange={e => setFilteredName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='col-4 col-xl'>
                    <div className='cmnSelect-form'>
                      <Select
                        showSearch={false}
                        value={filteredGroup}
                        onChange={selectedGroupName =>
                          setFilteredGroup(selectedGroupName)
                        }
                      >
                        <Option value={'All'} key='0'>
                          All
                        </Option>
                        {hotelGroupModalData.map(grp => (
                          <Option value={grp.name} key={grp.id}>
                            {grp.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className='col-4 col-xl'>
                    <div className='cmnSelect-form'>
                      <Select
                        showSearch={true}
                        value={filteredCity}
                        onChange={selectedCityName =>
                          setFilteredCity(selectedCityName)
                        }
                      >
                        {citylist.map(citys => (
                          <Option value={citys} key={citys}>
                            {citys}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className='col-4 col-md-3 col-xl'>
                    <div className='cmnSelect-form'>
                      <Select
                        showSearch={true}
                        value={filteredCountry}
                        onChange={selectedCountryName =>
                          setFilteredCountry(selectedCountryName)
                        }
                      >
                        {countrylist.map(country => (
                          <Option value={country} key={country}>
                            {country}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className='col-4 col-xl'>
                    <div className='cmnSelect-form'>
                      <Select
                        value={filteredStatus}
                        onChange={e => setFilteredStatus(e)}
                      >
                        {statusList.map(st => (
                          <Option value={st.id} key={st.id}>
                            {st.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className='col-4 col-md-auto'>
                    <Button
                      type='primary'
                      className='adduserbtn'
                      onClick={resetFilter}
                      title='Reset filters'
                    >
                      <img src='images/sa-clearicon.svg'></img>
                    </Button>
                  </div>
                  <div className='col-4 col-md-auto ml-auto'>
                    <Button className='cmnBtn' onClick={() => showModal()}>
                      Add Hotel
                    </Button>
                  </div>
                </div>
              </div>

              <div className='table-wrp'>
                <Table
                  columns={columns}
                  dataSource={filteredHotels}
                  pagination={false}
                  scroll={{ y: 602 }}
                  loading={loadingData}
                  rowKey='id'
                ></Table>
              </div>
            </div>
            <div className='col-12 col-md-12 col-lg-4'>
              <CountGreenCard no={hotels.length} />
              <SubscriptionCard
                total={hotels.length}
                active={activeHotelCount}
              />
            </div>
          </div>
        </div>
      </section>

      <Modal
        title={`${editingHotel ? 'Edit' : 'Add'} Hotel`}
        visible={isModalVisible}
        onOk={onFinish}
        onCancel={handleCancel}
        className='addHotelmodal cmnModal'
        footer={null}
        centered
      >
        <div className='imageUpload-wrp'>
          <figure>
            <div className='upload-figin'>
              <img src={hotelLogoUrl ?? 'images/cam.png'}></img>
            </div>
            {hotelLogoUrl && (
              <button className='removebtn' onClick={clearProfileImage}>
                <img src='images/close.svg'></img>
              </button>
            )}
          </figure>
          <div className='uploadbtn-wrp'>
            <Upload
              accept='.png, .jpeg, .jpg'
              beforeUpload={validateProfileImage}
              showUploadList={false}
            >
              <button>Upload Photo</button>
            </Upload>

            {hotelLogoError ? (
              <Alert message={hotelLogoError} type='error' showIcon />
            ) : null}
            <p>Image should be in PNG or JPEG file with maximum of size 1mb</p>
            <p>{`It should be ${allowedRatios.join(' or ')}`}</p>
          </div>
        </div>
        <Form
          layout='vertical'
          initialValues={{
            prefix: countryCode,
            departmentCount: 6,
            staffDepartmentCount: 15,
            intergationType: eIntergationType.NONE,
            enableGuestAppLogoSetting,
            enablePseudoBrowserCheckSetting,
            enableDepartmentCustomImage,
            enableRecurringTaskScheduler,
            enableIva,
          }}
          onFinish={onFinish}
          form={form}
          validateTrigger
        >
          <div className='row'>
            <div className='col-12 col-sm-6 col-md-4'>
              <div className='form-group cmn-input group-input'>
                <Form.Item
                  label='Group'
                  name='hotelGroupId'
                  rules={[
                    {
                      required: true,
                      message: 'Please select the hotel group',
                    },
                    () => ({
                      validator(_, value) {
                        if (editingHotel) {
                          if (value !== hotelData.groupId) {
                            return Promise.reject(
                              'You cannot change the group but you can rename.'
                            )
                          }
                        }
                        return Promise.resolve()
                      },
                    }),
                  ]}
                >
                  <Select
                    disabled={editingHotel}
                    value={hotelGroupId}
                    onChange={hotelGroupId => {
                      setHotelGroupId(hotelGroupId)
                      form.setFieldsValue({ hotelGroup: hotelGroupId })
                    }}
                    className='editButonnSelect'
                    dropdownClassName='editButonn-dropdown'
                  >
                    {hotelGroupModalData.length > 0
                      ? hotelGroupModalData.map((hotelGroup, idx) => (
                          <Option value={hotelGroup.id} key={idx}>
                            <em>{hotelGroup.name}</em>
                            <div className='option-actionsBtn'>
                              <button
                                onClick={e => {
                                  e.preventDefault()
                                  setHotelGroupModal({
                                    status: true,
                                    data: hotelGroup,
                                    type: modalTypeConstant.EditHotelGroup,
                                  })
                                }}
                              >
                                <EditOutlined />
                              </button>
                            </div>
                          </Option>
                        ))
                      : null}
                  </Select>
                </Form.Item>
                <Button
                  className='plusBtn'
                  onClick={e => {
                    e.preventDefault()
                    setHotelGroupModal({
                      status: true,
                      data: null,
                      type: modalTypeConstant.AddHotelGroup,
                    })
                  }}
                >
                  <PlusOutlined />
                </Button>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-sm-6 col-md-4'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label='Hotel Name'
                  name='hotelName'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the hotel name',
                    },
                    fieldProps =>
                      validateAlphaNumeric(
                        fieldProps,
                        'Please enter valid hotel name'
                      ),
                  ]}
                >
                  <Input
                    disabled={editingHotel}
                    maxLength={50}
                    value={hotelName}
                    onChange={e => setHotelName(e.target.value)}
                  />
                </Form.Item>
              </div>
            </div>
            <div className='col-12 col-sm-6 col-md-4'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label='Hotel Website'
                  name='website'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the hotel website',
                    },
                    () => ({
                      validator(_, value) {
                        if (!value) return Promise.resolve()

                        if (isValidUrl(value)) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error(`Please enter valid hotel website url`)
                        )
                      },
                    }),
                  ]}
                >
                  <Input
                    placeholder='www.domain.com'
                    value={website}
                    onChange={e => setWebsite(e.target.value)}
                  />
                </Form.Item>
              </div>
            </div>
            <div className='col-12 col-sm-6 col-md-4'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label='Hotel Email ID'
                  name='emailId'
                  rules={[
                    {
                      type: 'email',
                      message:
                        'Hotel Email ID must be in the format name@domain.com',
                    },
                  ]}
                >
                  <Input
                    value={emailId}
                    onChange={e => setEmailId(e.target.value)}
                  />
                </Form.Item>
              </div>
            </div>
            <div className='col-12 col-sm-6 col-md-4'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label='Hotel Admin Email'
                  name='adminEmail'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the hotel admin Email ID',
                    },
                    {
                      type: 'email',
                      message:
                        'Your Email ID must be in the format name@domain.com',
                    },
                    () => ({
                      validator(_, value) {
                        if (!value) return Promise.resolve()

                        const tempAdmin = hotels.find(
                          h => h.adminEmail === value
                        )
                        if (tempAdmin && tempAdmin.groupId !== hotelGroupId) {
                          return Promise.reject(
                            new Error(
                              `Hotel Admin Email Id already exists and belongs to another group!`
                            )
                          )
                        } else {
                          return Promise.resolve()
                        }
                      },
                    }),
                  ]}
                >
                  <Input
                    value={adminEmail}
                    placeholder='name@domain.com'
                    disabled={editingHotel}
                    onChange={handleAdminEmailChange}
                  />
                </Form.Item>
              </div>
            </div>
            <div className='col-12 col-sm-6 col-md-4'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label='Hotel Admin Name'
                  name='adminName'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the hotel admin name',
                    },
                  ]}
                >
                  <Input
                    value={adminName}
                    disabled={editingHotel || existingHotelAdmin}
                    onChange={e => setAdminName(e.target.value)}
                  />
                </Form.Item>
              </div>
            </div>
            {!existingHotelAdmin && (
              <div className='col-12 col-sm-6 col-md-4'>
                <div className='form-group cmn-input'>
                  <Form.Item
                    label='Hotel Admin Password'
                    name='adminPassword'
                    rules={[
                      {
                        required: existingHotelAdmin === null ? true : false,
                        message: 'Please enter the hotel admin password',
                      },
                      {
                        pattern: patterns.password.regex,
                        message: patterns.password.message,
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder='Password'
                      disabled={editingHotel || existingHotelAdmin}
                      value={adminPassword}
                      onChange={e => setAdminPassword(e.target.value)}
                    />
                  </Form.Item>
                </div>
              </div>
            )}
            <div className='col-12 col-sm-6 col-md-4'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label='Country'
                  name='countryName'
                  rules={[
                    {
                      required: true,
                      message: 'Please select the country',
                    },
                  ]}
                >
                  <Select
                    showSearch={true}
                    value={countryName}
                    onChange={selectedCountryName => {
                      setCountry(selectedCountryName)
                      setState('')
                      form.setFieldsValue({ stateName: '' })
                    }}
                  >
                    {countryList.map(country => (
                      <Option value={country.name} key={country.name}>
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className='col-12 col-sm-6 col-md-4'>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <div className='form-group cmn-input'>
                    <Form.Item
                      label='State'
                      name='stateName'
                      rules={[
                        {
                          required: filteredState[0] ? true : false,
                          message: 'Please select the state',
                        },
                      ]}
                    >
                      <Select
                        showSearch={true}
                        disabled={!filteredState[0] ? true : false}
                        value={stateName}
                        onChange={selectedStateName => {
                          setState(selectedStateName)
                        }}
                      >
                        {filteredState.map(state => (
                          <Option value={state} key={state}>
                            {state}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='form-group cmn-input'>
                    <Form.Item
                      label='City'
                      name='city'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter the city name',
                        },
                        {
                          pattern: /^[a-zA-Z',.\s-]{1,50}$/,
                          message: 'Please enter a valid city name',
                        },
                      ]}
                    >
                      <Input
                        value={city}
                        onChange={e => setCity(e.target.value)}
                        maxLength={50}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-sm-6 col-md-4'>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <div className='form-group cmn-input'>
                    <Form.Item
                      label='Class'
                      name='className'
                      rules={[
                        {
                          required: true,
                          message: 'Please select the class',
                        },
                      ]}
                    >
                      <Select
                        value={className}
                        onChange={selectedClassname =>
                          setClass(selectedClassname)
                        }
                      >
                        {classList.map(objClassName => (
                          <Option value={objClassName.id} key={objClassName.id}>
                            {objClassName.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='form-group cmn-input'>
                    <Form.Item
                      label='Rooms'
                      name='roomCount'
                      rules={numericValidation}
                    >
                      <Input
                        value={roomCount}
                        onChange={e => setRoomCount(e.target.value)}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-sm-6 col-md-4'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label='Hotel Address'
                  name='address'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the hotel address ',
                    },
                  ]}
                >
                  <Input
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                  />
                </Form.Item>
              </div>
            </div>
            <div className='col-12 col-sm-6 col-md-4'>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <div className='form-group contact-input'>
                    <Form.Item
                      label='Telephone'
                      name='contactNumber'
                      rules={[
                        {
                          min: 6,
                          message:
                            'Telephone should be minimum of 6 Characters long',
                        },
                      ]}
                    >
                      <Input
                        maxLength={10}
                        addonBefore={prefixSelector}
                        value={contactNumber}
                        onChange={handleContactChange}
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className='col-12 col-md-6'>
                  <div className='form-group cmn-input'>
                    <Form.Item
                      label='Subscription'
                      name='subscription'
                      rules={[
                        {
                          required: true,
                          message: 'Please select subscription type',
                        },
                      ]}
                    >
                      <Select
                        value={subscription}
                        onChange={s => setSubscription(s)}
                      >
                        {subscriptionList.map(s => (
                          <Option value={s.id} key={s.id}>
                            {s.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-sm-6 col-md-4'>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <div className='form-group cmn-input'>
                    <Form.Item
                      label='Staff Departments'
                      name='staffDepartmentCount'
                      rules={[
                        {
                          required: true,
                          message: 'Please select the staff department',
                        },
                      ]}
                    >
                      <DepartmentList
                        data={staffDepartmentList}
                        staffDepartment={true}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='form-group cmn-input'>
                    <Form.Item
                      label='Guest Departments'
                      name='departmentCount'
                      rules={[
                        {
                          required: true,
                          message: 'Please select the department',
                        },
                      ]}
                    >
                      <DepartmentList
                        data={departmentList}
                        staffDepartment={false}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-6 col-sm-6 col-md-2'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label='Custom Departments'
                  name='custDeptNo'
                  rules={[
                    fieldProps =>
                      CustomDepartmentNumberValidation(
                        fieldProps,
                        hotelData.custDeptNo ? hotelData.custDeptNo : 0,
                        `New value is lower than the existing custom department value. Please choose a value higher than ${hotelData.custDeptNo}.`
                      ),
                  ]}
                >
                  <Input
                    type='number'
                    value={custDeptNo}
                    onKeyDown={evt =>
                      (evt.key === 'e' || evt.key === '.') &&
                      evt.preventDefault()
                    }
                    onChange={e => setCustDeptNo(e.target.value)}
                  />
                </Form.Item>
              </div>
            </div>
            <div className='col-6 col-sm-6 col-md-2'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label='Recurring Task Services'
                  name='NoOfRecurringTask'
                  //initialValue={1}
                >
                  <Input
                    type='number'
                    //min={1}
                    value={noOfRecurringTask}
                    onChange={e => setNoOfRecurringTask(Number(e.target.value))}
                    onKeyDown={evt =>
                      (evt.key === 'e' || evt.key === '.') &&
                      evt.preventDefault()
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div className='col-6 col-sm-6 col-md-2'>
              <div className='form-group cmn-input'>
                <Form.Item label='Location Types' name='locationTypes'>
                  <LocationTypeCount
                    defaultLocationTypes={defaultLocationTypes}
                  />
                </Form.Item>
              </div>
            </div>
            <div className='col-12 col-md-2'>
              <div className='form-group cmn-input'>
                <Form.Item label='Intergation Type' name='intergationType'>
                  <Select
                    value={integrationType}
                    onChange={s => {
                      setIntegrationType(s)
                      form.setFieldsValue({ integrationType: s })
                    }}
                  >
                    {Object.values(eIntergationType).map((type, idx) => (
                      <Option value={type} key={idx}>
                        {type}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className='col-12 col-md-2'>
              <div className='form-group cmn-input'>
                <Form.Item label='PMS Type' name='pmsType'>
                  <Select
                    value={pmsType}
                    onChange={s => {
                      setPmsType(s)
                      form.setFieldsValue({ pmsType: s })
                    }}
                  >
                    {Object.values(ePmsType).map((type, idx) => (
                      <Option value={type} key={idx}>
                        {type}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className='col-12 col-md-2'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label='Gateway IP'
                  name='gatewayIP'
                  rules={[
                    {
                      required: integrationType !== eIntergationType.NONE,
                      message: 'Please enter the Gateway IP',
                    },
                    {
                      pattern:
                        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
                      message: 'Please enter a valid Gateway IP',
                    },
                  ]}
                >
                  <Input
                    disabled={integrationType === eIntergationType.NONE}
                    maxLength={50}
                    value={gatewayIP}
                    onChange={e => setGatewayIP(e.target.value)}
                  />
                </Form.Item>
              </div>
            </div>
            <div className='col-12 col-md-2'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label='API Key'
                  name='apiKey'
                  rules={[
                    {
                      required: integrationType !== eIntergationType.NONE,
                      message: 'Please enter the API key',
                    },
                  ]}
                >
                  <Input
                    maxLength={50}
                    value={apiKey}
                    disabled={integrationType === eIntergationType.NONE}
                    onChange={e => setApiKey(e.target.value)}
                  />
                </Form.Item>
              </div>
            </div>
            <div className='col-12 col-md-2'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label='Property Id'
                  name='propertyId'
                  rules={[
                    {
                      required: integrationType !== eIntergationType.NONE,
                      message: 'Please enter the Property Id',
                    },
                  ]}
                >
                  <Input
                    maxLength={50}
                    value={propertyId}
                    disabled={integrationType === eIntergationType.NONE}
                    onChange={e => setPropertyId(e.target.value)}
                  />
                </Form.Item>
              </div>
            </div>
            {editingHotel && (
              <div className='col-12 col-sm-6 col-md-4'>
                <div className='form-group cmn-input'>
                  <Form.Item label='Hotel Domain'>
                    <Input value={hotelDomain} disabled />
                  </Form.Item>
                </div>
              </div>
            )}
            <div className='col-12 col-sm-6 col-md-6'>
              <div
                className='form-group cmn-input customCheckbox checkbox-margin'
                style={{ marginTop: '30px', alignItems: 'center' }}
              >
                <Form.Item name='enablePseudoBrowserCheckSetting'>
                  <Checkbox
                    checked={enablePseudoBrowserCheckSetting}
                    onChange={e => {
                      form.setFieldsValue({
                        enablePseudoBrowserCheckSetting: e.target.checked,
                      })
                      setEnablePseudoBrowserCheckSetting(e.target.checked)
                    }}
                    style={{ alignItems: 'center' }}
                  >
                    Enable Pseudo Browser Check
                  </Checkbox>
                </Form.Item>
              </div>
            </div>

            <div className='col-12 col-sm-6 col-md-6'>
              <div
                className='form-group cmn-input customCheckbox checkbox-margin'
                style={{ marginTop: '30px', alignItems: 'center' }}
              >
                <Form.Item name='enableLogoSetting'>
                  <Checkbox
                    checked={enableGuestAppLogoSetting}
                    onChange={e => {
                      form.setFieldsValue({
                        enableGuestAppLogoSetting: e.target.checked,
                      })
                      setEnableGuestAppLogoSetting(e.target.checked)
                    }}
                    style={{ alignItems: 'center' }}
                  >
                    Enable Hotel Logo in GUEST by iNPLASS
                  </Checkbox>
                </Form.Item>
              </div>
            </div>
            <div className='col-12 col-sm-6 col-md-6'>
              <div
                className='form-group cmn-input customCheckbox checkbox-margin'
                style={{ marginTop: '30px', alignItems: 'center' }}
              >
                <Form.Item name='enableDepartmentCustomImage'>
                  <Checkbox
                    checked={enableDepartmentCustomImage}
                    onChange={e => {
                      form.setFieldsValue({
                        enableDepartmentCustomImage: e.target.checked,
                      })
                      setEnableDepartmentCustomImage(e.target.checked)
                    }}
                    style={{ alignItems: 'center' }}
                  >
                    Enable Department Custom Images
                  </Checkbox>
                </Form.Item>
              </div>
            </div>
            <div className='col-12 col-sm-6 col-md-6'>
              <div
                className='form-group cmn-input customCheckbox checkbox-margin'
                style={{ marginTop: '30px', alignItems: 'center' }}
              >
                <Form.Item name='enableRecurringTaskScheduler'>
                  <Checkbox
                    checked={enableRecurringTaskScheduler}
                    onChange={e => {
                      form.setFieldsValue({
                        enableRecurringTaskScheduler: e.target.checked,
                      })
                      setEnableRecurringTaskScheduler(e.target.checked)
                    }}
                    style={{ alignItems: 'center' }}
                  >
                    Enable Recurring Task Scheduler
                  </Checkbox>
                </Form.Item>
              </div>
            </div>
            <div className='col-12 col-sm-6 col-md-6'>
              <div
                className='form-group cmn-input customCheckbox checkbox-margin'
                style={{ marginTop: '30px', alignItems: 'center' }}
              >
                <Form.Item name='enableIva'>
                  <Checkbox
                    checked={enableIva}
                    onChange={e => {
                      form.setFieldsValue({
                        enableIva: e.target.checked,
                      })
                      setEnableIva(e.target.checked)
                    }}
                    style={{ alignItems: 'center' }}
                  >
                    Enable iNPLASS Virtual Assistant
                  </Checkbox>
                </Form.Item>
              </div>
            </div>
          </div>

          {createUserError && (
            <Alert message={createUserError} type='error' showIcon />
          )}

          <div className='modalFooter'>
            <Button className='grayBtn' key='back' onClick={handleCancel}>
              Cancel
            </Button>

            <Button
              className='blueBtn ml-3 ml-lg-4'
              key='submit'
              htmlType='submit'
              loading={showLoader}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal>

      <AddEditHotelGroup {...hotelGroupModaData} />
      <SuccessModal
        title={getSuccessModalMessage()}
        showSuccessModal={showSuccessModal}
        setShowSuccessModal={() => setShowSuccessModal(false)}
      />
      <DeleteModal
        onOk={deleteHotelProfile}
        onCancel={hideDeleteConfirmation}
        visible={showDeleteConfirmation}
      />
      <Modal
        title={GetCustomDepartmentModalTitle(hotelCustomDepartments)}
        visible={showCustomDeptList}
        onOk={hideDeptList}
        onCancel={hideDeptList}
        className='addSubscriptionModal cmnModal'
        footer={null}
      >
        {Ternary(
          loadingHotelCustomDepartments,
          <Skeleton />,
          <CustomDepartmentList />
        )}
      </Modal>
    </>
  )
}

export default HotelManagement
