import { Skeleton } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, BrowserRouter, Redirect } from 'react-router-dom'
import { MaintenanceListerner } from '../../services/maintenance'
import { useHistory } from 'react-router'
import { auth } from '../../config/firebase'
import {
  GetCurrentUser,
  GetLoggedUserDetailsFromEmail,
} from '../../services/user'
import { routeList } from './routeConstant'
import Maintanance from '../../components/Pages/Maintanance/Maintanance'
import ProductionDetailsToast from '../../components/Common/ProductionDetailsToast/ProductionDetailsToast'
import { SuperAdminRole } from '../../config/constants'

export const isLoggedIn = GetCurrentUser

function CommonRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={routeProps => {
        if (GetCurrentUser() && rest?.isUserAuthLogin) {
          return <Redirect to={'/Dashboard'} />
        } else {
          return (
            <>
              <ProductionDetailsToast visible={false} />
              <Component {...rest} {...routeProps}></Component>
            </>
          )
        }
      }}
    />
  )
}

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={routeProps => {
        if (GetCurrentUser() && rest?.isUserAuthLogin) {
          return (
            <>
              <ProductionDetailsToast visible={false} />
              <Component {...rest} {...routeProps}></Component>
            </>
          )
        } else {
          return <Redirect to={'/SignIn'} />
        }
      }}
    />
  )
}

function loadRoutConfig({ loading, userInfo }) {
  let isUserAuthLogin =
    userInfo?.isSuperUser || userInfo?.roles?.includes(SuperAdminRole) || false
  return routeList.map((route, idx) => {
    let commonRouteProps = { ...route, isUserAuthLogin }
    if (route.isAuthenticated) {
      return <PrivateRoute {...commonRouteProps} key={idx} />
    } else {
      return <CommonRoute {...commonRouteProps} key={idx} />
    }
  })
}

const Router = () => {
  const history = useHistory()
  const { production, loading, userInfo, appStatus } = useSelector(
    state => state
  )
  const { showMaintenancePage } = production
  const dispatch = useDispatch()
  const [firebaseConnected, setFirebaseConnected] = useState(false)

  const authChange = useCallback(async () => {
    auth.onAuthStateChanged(() => {
      GetLoggedUserDetailsFromEmail(dispatch)

      setFirebaseConnected(true)
    })
  }, [dispatch])

  useEffect(() => authChange(), [authChange])

  useEffect(() => {
    MaintenanceListerner(dispatch)
  }, [dispatch])

  if (!firebaseConnected || appStatus) return <Skeleton />
  if (showMaintenancePage) return <Maintanance />
  return (
    <BrowserRouter history={history}>
      {loadRoutConfig({ loading, userInfo })}
    </BrowserRouter>
  )
}

export default Router
