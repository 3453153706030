import ForgotPassword from '../../components/Pages/Authentication/ForgotPassword/ForgotPassword'
import LinkExpired from '../../components/Pages/Authentication/LinkExpired/LinkExpired'
import ResetPassword from '../../components/Pages/Authentication/ResetPassword/ResetPassword'
import SignIn from '../../components/Pages/Authentication/SignIn/SignIn'
import Dashboard from '../../components/Pages/Dashboard/Dashboard'
import Departments from '../../components/Pages/Departments/Departments'
import HotelManagement from '../../components/Pages/HotelManagement/HotelManagement'
import MyProfile from '../../components/Pages/MyProfile/MyProfile'
import Subscriptions from '../../components/Pages/Subscriptions/Subscriptions'
import Users from '../../components/Pages/Users/Users'
import SubscriptionsList from '../../components/Pages/Subscriptions/SubscriptionsList'
import Maintanance from '../../components/Pages/Maintanance/Maintanance'
import DefaultLocationTypeList from '../../components/Pages/LocationTypes/LocationTypesList'
import OverAllFeedbackQuestions from '../../components/Pages/OverAllFeedbackQuestions/OverAllFeedbackQuestions'
import Settings from '../../components/Pages/Settings'
import AddFaq from '../../components/Pages/AddFaq'
import TranslationDictionary from '../../components/Pages/Translation/TranslationDictionary'
export const accessType = { PUBLIC: 'public' }

export const routeList = [
  {
    exact: true,
    path: ['/maintenance'],
    component: Maintanance,
    isAuthenticated: false,
  },
  {
    exact: true,
    path: ['/', '/SignIn'],
    component: SignIn,
    isAuthenticated: false,
  },
  {
    exact: true,
    path: '/ForgotPassword',
    component: ForgotPassword,
    isAuthenticated: false,
  },
  {
    exact: true,
    path: '/ResetPassword',
    component: ResetPassword,
    isAuthenticated: false,
  },
  {
    exact: true,
    path: '/LinkExpired',
    component: LinkExpired,
    isAuthenticated: false,
  },
  {
    exact: true,
    path: '/HotelManagement',
    component: HotelManagement,
    isAuthenticated: true,
  },
  {
    exact: true,
    path: '/Users',
    component: Users,
    isAuthenticated: true,
  },
  {
    exact: true,
    path: '/Subscriptions',
    component: Subscriptions,
    isAuthenticated: true,
  },
  {
    exact: true,
    path: '/SubscriptionsList',
    component: SubscriptionsList,
    isAuthenticated: true,
  },
  {
    exact: true,
    path: '/Departments',
    component: Departments,
    isAuthenticated: true,
  },
  {
    exact: true,
    path: '/LocationTypes',
    component: DefaultLocationTypeList,
    isAuthenticated: true,
  },
  {
    exact: true,
    path: '/MyProfile',
    component: MyProfile,
    isAuthenticated: true,
  },
  {
    exact: true,
    path: '/Dashboard',
    component: Dashboard,
    isAuthenticated: true,
  },
  {
    exact: true,
    path: '/OverAllFeedbackQuestions',
    component: OverAllFeedbackQuestions,
    isAuthenticated: true,
  },
  {
    exact: true,
    path: '/Settings*',
    component: Settings,
    isAuthenticated: true,
  },
  {
    exact: true,
    path: '/AddFaq',
    component: AddFaq,
    isAuthenticated: true,
  },
  {
    exact: true,
    path: '/TranslationDictionary',
    component: TranslationDictionary,
    isAuthenticated: true,
  },
  // {
  //   path: '/*',
  //   component: () => <h1>Not Found</h1>,
  //   isAuthenticated: false,
  //   accessType: accessType.PUBLIC,
  // },
]
