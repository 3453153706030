import React from 'react'
import DeleteModalContent from './DeleteModalContent'
import { Modal, Button } from 'antd'

const DeleteModal = ({
  onCancel,
  onOk,
  title = 'Confirm Delete',
  message = 'Do you really want to delete ?',
  visible = false,
}) => {
  return (
    <>
      <Modal
        onOk={onOk}
        onCancel={onCancel}
        className='deleteModal cmnModal'
        footer={null}
        centered
        visible={visible}
      >
        <DeleteModalContent
          title={title ?? 'Confirm Delete'}
          message={message ?? 'Do you really want to delete ?'}
        />

        <div className='modalFooter'>
          <Button className='grayBtn' key='back' onClick={onCancel}>
            Cancel
          </Button>

          <Button className='blueBtn ml-3 ml-lg-4' key='submit' onClick={onOk}>
            Delete
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default DeleteModal
