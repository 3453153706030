/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react'
import { Layout, Menu } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { Link, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from '../../../app/store'
import { navConfig } from '../../../config/utils'
import { useHistory } from 'react-router'

const { Sider } = Layout

const SideMenu = () => {
  const dispatch = useDispatch()
  const { sideMenuSelectedKey } = useSelector(state => state)
  const route = useRouteMatch()

  useEffect(() => {
    const find = navConfig?.find(nav => nav?.url === route?.path)
    if (find) {
      dispatch(actions.setSideMenuSelectedKey(String(find?.index ?? '')))
    }
  }, [dispatch, route.path])

  function changeSideMenuSelectKey(e) {
    dispatch(actions.setSideMenuSelectedKey(String(e.key)))
  }

  const history = useHistory()
  return (
    <>
      <section className='sideMenu'>
        <button className='menuclosebtn d-md-none'>
          <CloseOutlined />
        </button>
        <Sider>
          <Menu selectedKeys={sideMenuSelectedKey} mode='inline'>
            {navConfig.map(nav => (
              <Menu.Item
                key={nav.index}
                disabled={nav.disabled}
                icon={nav.icon}
                onClick={changeSideMenuSelectKey}
              >
                <Link
                  to={'#'}
                  onClick={e => {
                    e.preventDefault()
                    history.push(nav.url)
                  }}
                >
                  {nav.title}
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
      </section>
    </>
  )
}

export default SideMenu
