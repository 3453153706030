import axios from 'axios'
import { transliterateLanguageIds } from '../config/constants'

const GetTransliterateUrl = (word, lang) =>
  `https://inputtools.google.com/request?text=${word.replace(
    '&',
    'and'
  )}&itc=${lang}-t-i0-und&num=13&cp=0&cs=1&ie=utf-8&oe=utf-8&app=demopage`

const commonCatch = err => err?.message || ''

const translationUrl = `https://translation.googleapis.com/language/translate/v2`

export const GetTranslationData = async (languageDictionary, text) => {
  try {
    const languagePromises = []
    const translateIds = languageDictionary
      .filter(l => !transliterateLanguageIds.includes(l.id) && l.id !== 'en')
      .map(l => l.id)

    const params = {
      source: 'en',
      key: process.env.REACT_APP_GOOGLE_TRANSLATOR_KEY,
      format: 'text',
      q: text,
    }

    translateIds.forEach(languageId => {
      const languagePromise = axios
        .post(translationUrl, null, {
          params: { ...params, target: languageId },
        })
        .catch(commonCatch)
      languagePromises.push(languagePromise)
    })

    let promiseResponse = await Promise.all(languagePromises)

    const response = { en: text }
    promiseResponse.forEach((d, index) => {
      const translatedText =
        d?.data?.data?.translations[0]?.translatedText || text
      response[translateIds[index]] = translatedText
    })

    return response
  } catch (error) {
    console.log(error)
    return {}
  }
}

export const GetTranslatiterationData = async text => {
  try {
    const languagePromises = []

    transliterateLanguageIds.forEach(langId => {
      const tranliterateUrl = GetTransliterateUrl(text, langId)
      const languagePromise = fetch(tranliterateUrl).catch(commonCatch)
      languagePromises.push(languagePromise)
    })

    let promiseResponse = await Promise.all(languagePromises)
    promiseResponse = await Promise.all(promiseResponse.map(d => d.json()))
    const response = { en: text }

    promiseResponse.forEach((responseData, index) => {
      const translationArray = responseData[1] || []
      const translation = translationArray[0]?.[1]?.[0] || text
      response[transliterateLanguageIds[index]] = translation
    })

    return response
  } catch (error) {
    console.log(error)
    return {}
  }
}
