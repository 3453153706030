import React, { useState, useEffect } from 'react'
import { Input, Select, Modal, Button, Collapse, Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import Header from '../../Common/Header/Header'
import SideMenu from '../../Common/Sidemenu/Sidemenu'
import PageNamecard from '../../Common/PageNameCard/Pagenamecard'
import SubscriptionCard from '../../Common/SubscriptionCard/SubscriptionCard'
import SubscriptionBox from '../../Common/SubscriptionBox/SubscriptionBox'
import ReadMore from '../../Common/ReadMore/ReadMore'
import {
  AddSubscriptionListener,
  DeleteSubscription,
  SaveSubscription,
} from '../../../services/subscription'
import { FetchDepartments } from '../../../services/department'
import CustomAlert from '../../CustomAlert'
import { formatPrice, SetAutoClearProp, Ternary } from '../../../config/utils'
import { GetHotelListener } from '../../../services/hotel'
import DeleteModal from '../../Common/Modals/DeleteModal'
import Department from '../../Common/Department/Department'

const { Option } = Select

const currencyList = ['$']

const IsNotDuplicate = (name, list, selectedSubscription) => {
  if (!name) return true

  let names = list.map(l => l.name.toLowerCase())
  if (selectedSubscription?.id)
    names = names.filter(n => n !== selectedSubscription.name.toLowerCase())
  return !names.includes(name.toLowerCase())
}

const Subscriptions = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [localDepartments, setLocalDepartments] = useState([])
  const [openSubServiceKeys, setOpenSubServiceKeys] = useState([])
  const [selectedSubscription, setSelectedSubscription] = useState({})
  const [viewSelectedSubscription, setViewSelectedSubscription] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [currency, setCurrency] = useState(currencyList[0])
  const [price, setPrice] = useState('')
  const [loading, setLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [error, setError] = useState('')
  const [deleteError, setDeleteError] = useState('')

  const { subscription, subscriptionListenerAdded, departments, hotels, hotelListenerAdded } =
    useSelector(state => state)
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  useEffect(() => {
    if (+price < 0) {
      setPrice(0)
      form.setFieldsValue({ price: 0 })
    }
  }, [form, price])

  useEffect(() => {
    AddSubscriptionListener(dispatch, subscriptionListenerAdded)
  }, [dispatch, subscriptionListenerAdded])

  useEffect(() => {
    GetHotelListener(dispatch, hotelListenerAdded)
  }, [dispatch, hotelListenerAdded])

  useEffect(() => {
    FetchDepartments(departments, dispatch)
    let departmentsCopy = [...departments]
    departmentsCopy = departmentsCopy.map(d => {
      let deptCopy = { ...d, checked: true }
      if (deptCopy.services) {
        deptCopy.services = deptCopy.services.map(s => {
          let serviceCopy = { ...s, checked: true }
          if (serviceCopy.services) {
            serviceCopy.services = serviceCopy.services.map(s1 => ({ ...s1, checked: true }))
          }
          return serviceCopy
        })
      }
      return deptCopy
    })
    setLocalDepartments(departmentsCopy)
  }, [departments, dispatch])

  const resetSubscriptiomModal = () => {
    setName('')
    setPrice('')
    setDescription('')
    setCurrency(currency[0])
    form.resetFields()
  }

  const showModal = () => {
    resetSubscriptiomModal()
    setIsModalVisible(true)
  }

  const hideModal = () => {
    resetSubscriptiomModal()
    setIsModalVisible(false)
    setSelectedSubscription({})
  }

  const hideKnowMore = () => setViewSelectedSubscription(false)

  const prefixSelector = (
    <Form.Item name='prefix' noStyle>
      <Select value={currency} style={{ width: 70 }} onChange={e => setCurrency(e)}>
        {currencyList.map(c => (
          <Option value={c}>{c}</Option>
        ))}
      </Select>
    </Form.Item>
  )

  const handleServiceChecked = ({ e, departmentId, serviceId, subServiceId }) => {
    e.stopPropagation()

    const checked = e.target.checked

    const departmentsCopy = [...localDepartments]
    const department = departmentsCopy.find(d => d.id === departmentId)
    const service = department.services.find(s => s.id === serviceId)

    if (subServiceId) {
      const subService = service.services.find(s => s.id === subServiceId)
      subService.checked = checked
      if (service.services.every(s => s.checked === checked)) {
        service.checked = checked
      }
    } else {
      service.checked = checked
      service.services?.forEach(s => (s.checked = checked))
    }

    if (department.services.every(s => s.checked === checked)) {
      department.checked = checked
    }

    setLocalDepartments(departmentsCopy)
  }

  const handleDepartmentChecked = (e, deptId) => {
    e.stopPropagation()

    const checked = e.target.checked

    const departmentsCopy = [...localDepartments]
    const deptIndex = departmentsCopy.findIndex(d => d.id === deptId)

    const department = departmentsCopy[deptIndex]
    department?.services?.forEach(s => {
      s.checked = checked
      s?.services?.forEach(s1 => (s1.checked = checked))
    })
    departmentsCopy[deptIndex] = { ...department, checked }
    setLocalDepartments(departmentsCopy)
  }

  const saveSubscription = async () => {
    if (loading) return

    setLoading(true)

    const data = {
      name,
      price,
      currency,
      description,
      index: Ternary(
        selectedSubscription?.index,
        selectedSubscription.index,
        Math.max(...subscription.map(s => s.index)) + 1
      ),
    }

    const { success, message } = await SaveSubscription(data, selectedSubscription?.id)
    if (success) {
      hideModal()
      SetAutoClearProp(setSuccessMessage, message)
    } else {
      SetAutoClearProp(setError, message)
    }

    setLoading(false)
  }

  const handleDeleteSubscription = async () => {
    if (loading) return

    setShowDeleteConfirmation(false)
    setLoading(true)

    const { success, message } = await DeleteSubscription(selectedSubscription.id, hotels)
    if (success) {
      SetAutoClearProp(setSuccessMessage, message)
      setSelectedSubscription({})
    } else {
      setDeleteError(message)
    }

    setLoading(false)
  }

  const handleDeleteIconClick = s => {
    setSelectedSubscription(s)
    setShowDeleteConfirmation(true)
  }

  return (
    <>
      <Header />
      <SideMenu />
      <section className='mainContent'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNamecard title='Subscription' breadcrumb={['Super Admin', 'Subscription']} />
            </div>

            <div className='col-12 col-md-12 col-lg-8'>
              <div className='tablefilter-wrp'>
                <div className='form-row justify-content-end'>
                  <div className='col-4 col-md-auto'>
                    <Button
                      className='cmnBtn'
                      onClick={() => {
                        setSelectedSubscription({})
                        showModal()
                      }}
                    >
                      Add Subscription
                    </Button>
                  </div>
                </div>
              </div>
              <CustomAlert
                visible={successMessage}
                message={successMessage}
                type='success'
                showIcon={true}
                classNames='mb-3'
              />
              <CustomAlert
                visible={deleteError}
                message={deleteError}
                type='error'
                showIcon={true}
                classNames='mb-3'
              />
              <div className='subscriptionBox-wrp'>
                <div className='row'>
                  {subscription.map(s => (
                    <div className='col-12 col-md-6 col-lg-4' key={s.id}>
                      <SubscriptionBox
                        isDefault={s.default}
                        styleName='trialBox'
                        headtext={s.name}
                        price={s.price}
                        currency={s.currency}
                        onClick={() => {
                          setSelectedSubscription(s)
                          setViewSelectedSubscription(true)
                        }}
                        onDeleteClick={() => handleDeleteIconClick(s)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='col-12 col-md-12 col-lg-4'>
              <SubscriptionCard />
            </div>
          </div>
        </div>
      </section>

      <Modal
        title={Ternary(selectedSubscription.id, 'Edit Subscription', 'Add Subscription')}
        visible={isModalVisible}
        onOk={hideModal}
        onCancel={hideModal}
        className='addSubscriptionModal cmnModal'
        footer={null}
      >
        <Form
          layout='vertical'
          initialValues={{
            prefix: currencyList[0],
          }}
          onFinish={saveSubscription}
          form={form}
          validateTrigger
        >
          <div className='row'>
            <div className='col-12 col-md-6'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label='Subscription Name'
                  name='name'
                  value={name}
                  rules={[
                    {
                      min: 1,
                      required: true,
                      message: 'Please enter subscription name',
                    },
                    () => ({
                      validator(_, value) {
                        if (IsNotDuplicate(value?.trim(), subscription, selectedSubscription)) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error(`Subscription with name '${value}' already exists!`)
                        )
                      },
                    }),
                  ]}
                >
                  <Input value={name} maxLength={15} onChange={e => setName(e.target.value)} />
                </Form.Item>
              </div>
            </div>

            <div className='col-12 col-md-6'>
              <div className='form-group contact-input'>
                <Form.Item
                  label='Price'
                  name='price'
                  value={price}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (+value !== 0) {
                          return Promise.resolve()
                        }
                        return Promise.reject(new Error(`Please enter the price`))
                      },
                    }),
                  ]}
                >
                  <Input
                    type='number'
                    min={0}
                    maxLength={10}
                    addonBefore={prefixSelector}
                    value={price}
                    onChange={e => setPrice(+e.target.value)}
                  />
                </Form.Item>
              </div>
            </div>
            <div className='col-12'>
              <div className='form-group cmn-input'>
                <Form.Item label='Subscription Details' name='description' value={description}>
                  <Input value={description} onChange={e => setDescription(e.target.value)} />
                </Form.Item>
              </div>
            </div>
            <div className='col-12'>
              <div className='departmentCollapse-wrp cmnCollapse-wrp'>
                <Collapse>
                  {localDepartments.map(d => (
                    <Department
                      data={d}
                      key={d.id}
                      onCheckboxClick={handleDepartmentChecked}
                      onServiceCheckboxClick={handleServiceChecked}
                      openSubServiceKeys={openSubServiceKeys}
                      setOpenSubServiceKeys={setOpenSubServiceKeys}
                    />
                  ))}
                </Collapse>
              </div>
            </div>
          </div>
          <CustomAlert
            visible={error}
            message={error}
            type='error'
            showIcon={true}
            classNames='mt-3'
          />
          <div className='modalFooter'>
            <Button className='grayBtn' key='back' onClick={hideModal}>
              Cancel
            </Button>

            <Button className='blueBtn ml-4' key='submit' htmlType='submit' loading={loading}>
              Submit
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        onOk={hideKnowMore}
        onCancel={hideKnowMore}
        className='viewplansModal cmnModal'
        footer={null}
        closable={false}
        visible={viewSelectedSubscription}
      >
        <h4>{selectedSubscription?.name}</h4>
        <h5>
          {selectedSubscription?.currency} {formatPrice(selectedSubscription?.price)} / Year
        </h5>
        <ul className='list-unstyled tickList'>
          <li>
            <img src='images/checked.svg' alt=''></img>

            <ReadMore
              text={selectedSubscription?.description || ' No description'}
              charLength={250}
            />
          </li>
        </ul>

        <div className='modalFooter'>
          <Button className='grayBtn' key='back' onClick={hideKnowMore}>
            Cancel
          </Button>

          <Button
            className='blueBtn ml-4'
            key='submit'
            onClick={() => {
              hideKnowMore()
              showModal()

              const name1 = selectedSubscription.name
              const description1 = selectedSubscription.description
              const currency1 = selectedSubscription.currency
              const price1 = selectedSubscription.price

              setName(name1)
              setDescription(description1)
              setCurrency(currency1)
              setPrice(price1)

              form.setFieldsValue({
                name: name1,
                description: description1,
                currency: currency1,
                price: price1,
              })
            }}
          >
            Edit Data
          </Button>
        </div>
      </Modal>

      <DeleteModal
        onOk={handleDeleteSubscription}
        onCancel={() => {
          setShowDeleteConfirmation(false)
          setSelectedSubscription({})
        }}
        visible={showDeleteConfirmation}
        message={Ternary(
          hotels.some(h => h.subscription === selectedSubscription.id),
          <>
            <p> There are hotels availing this subscription.</p>
            <br />
            <p> Do you still wish to continue ?</p>
          </>,
          'Do you wish to delete the subscription ?'
        )}
      />
    </>
  )
}

export default Subscriptions
