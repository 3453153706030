import React, { useState, useEffect } from 'react'
import { db } from '../../../config/firebase'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { Collapse, Input, Button, Modal, Form, notification, Spin } from 'antd'
import 'antd/dist/antd.css'
import Header from '../../Common/Header/Header'
import SideMenu from '../../Common/Sidemenu/Sidemenu'
import PageNamecard from '../../Common/PageNameCard/Pagenamecard'
import {
  transliterateLanguageIds,
  languageIdToDocName,
} from '../../../config/constants'
import {
  GetTranslationData,
  GetTranslatiterationData,
} from '../../../utility/translationUtils'
import TranslatedModal from '../../Common/TranslateModal/TranslateModal'
import { sanitizeFieldPath } from '../../../config/utils'

const { Panel } = Collapse
const { Search } = Input

const TranslationDictionary = () => {
  const [dictionary, setDictionary] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [editableTranslations, setEditableTranslations] = useState({})
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isFetchingTranslations, setIsFetchingTranslations] = useState(false)
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false)
  const [newWord, setNewWord] = useState('')
  const [newWordTranslations, setNewWordTranslations] = useState({})
  const [form] = Form.useForm()

  useEffect(() => {
    const unsubscribe = db.collection('languagedictionary').onSnapshot(
      querySnapshot => {
        const languageList = []
        let flatLanguageData = {}
        querySnapshot.forEach(doc => {
          languageList.push(doc.data())
          flatLanguageData = {
            ...flatLanguageData,
            [doc.data().id]: {
              ...flatLanguageData[doc.data().id],
              translation: { ...doc.data().data },
            },
          }
        })

        const wordTranslations = {}
        Object.entries(flatLanguageData).forEach(([language, data]) => {
          Object.entries(data.translation).forEach(([word, translation]) => {
            if (!wordTranslations[word]) {
              wordTranslations[word] = {}
            }
            wordTranslations[word][language] = translation
          })
        })
        setDictionary(wordTranslations)
        setLoading(false)
      },
      err => {
        setError(err.message)
        setLoading(false)
      }
    )
    return () => unsubscribe()
  }, [])

  const isValidWord = word => {
    return !(word.startsWith('.') || word.endsWith('.') || word.includes('..'))
  }

  const handleSearch = e => {
    setSearchTerm(e.target.value.toLowerCase())
  }

  const handleInputChange = (word, language, value) => {
    setEditableTranslations({
      ...editableTranslations,
      [word]: {
        ...editableTranslations[word],
        [language]: value,
      },
    })
  }

  const handleSave = async word => {
    const translations = editableTranslations[word]
    if (translations) {
      const batch = db.batch()

      for (const [language, newTranslation] of Object.entries(translations)) {
        if (newTranslation !== undefined) {
          const docName = languageIdToDocName[language]
          if (docName) {
            const docRef = db.collection('languagedictionary').doc(docName)
            const docSnapshot = await docRef.get()

            if (docSnapshot.exists) {
              batch.update(docRef, {
                [`data.${sanitizeFieldPath(word)}`]: newTranslation,
              })
            } else {
              console.warn(`Document for ${docName} does not exist.`)
            }
          } else {
            console.error(`No document name found for language ID: ${language}`)
          }
        }
      }

      try {
        await batch.commit()

        const updatedDictionary = {
          ...dictionary,
          [word]: {
            ...dictionary[word],
            ...translations,
          },
        }
        setDictionary(updatedDictionary)
        setEditableTranslations({
          ...editableTranslations,
          [word]: {},
        })
        notification.success({
          message: 'Translations Saved',
          description: `The translations for "${word}" have been saved successfully.`,
        })
      } catch (err) {
        setError(err.message)
      }
    }
  }

  const handleDelete = async word => {
    const batch = db.batch()

    for (const languageId of Object.keys(dictionary[word])) {
      const docName = languageIdToDocName[languageId]
      if (docName) {
        const docRef = db.collection('languagedictionary').doc(docName)
        const docSnapshot = await docRef.get()

        if (docSnapshot.exists) {
          batch.update(docRef, {
            [`data.${word}`]: firebase.firestore.FieldValue.delete(),
          })
        } else {
          console.warn(`Document for ${docName} does not exist.`)
        }
      } else {
        console.error(`No document name found for language ID: ${languageId}`)
      }
    }

    try {
      await batch.commit()

      const updatedDictionary = { ...dictionary }
      delete updatedDictionary[word]
      setDictionary(updatedDictionary)

      notification.success({
        message: 'Word Deleted',
        description: `The word "${word}" has been deleted successfully.`,
      })
    } catch (err) {
      console.error('Error deleting word:', err)
      setError(err.message)
    }
  }

  const handleAddWord = async () => {
    try {
      const values = await form.validateFields()

      const word = sanitizeFieldPath(values.word)
      if (!isValidWord(word)) {
        notification.error({
          message: 'Invalid Word',
          description:
            'The word cannot start or end with a "." and cannot contain "..".',
        })
        return
      }

      setNewWord(word)
      setIsFetchingTranslations(true)
      const translations = await GetTranslationData(
        transliterateLanguageIds.map(id => ({ id })),
        word
      )
      const transliterations = await GetTranslatiterationData(word)
      setIsFetchingTranslations(false)

      const filteredTranslations = Object.keys(translations).reduce(
        (filtered, language) => {
          if (transliterateLanguageIds.includes(language)) {
            filtered[language] = translations[language]
          }
          return filtered
        },
        {}
      )
      const combinedTranslations = {
        ...filteredTranslations,
        ...transliterations,
      }
      setNewWordTranslations(combinedTranslations)
      setIsConfirmationVisible(true)
    } catch (error) {
      console.error('Error adding word:', error)
      notification.error({
        message: 'Error Adding Word',
        description: error.message,
      })
    } finally {
      setIsFetchingTranslations(false)
    }
  }

  const handleConfirmAddWord = async () => {
    try {
      if (!isValidWord(newWord)) {
        notification.error({
          message: 'Invalid Word',
          description:
            'The word cannot start or end with a "." and cannot contain "..".',
        })
        return
      }
      const batch = db.batch()
      setIsFetchingTranslations(true)

      for (const languageId of Object.keys(newWordTranslations)) {
        if (transliterateLanguageIds.includes(languageId)) {
          const docName = languageIdToDocName[languageId]
          if (docName) {
            const docRef = db.collection('languagedictionary').doc(docName)
            const docSnapshot = await docRef.get()

            if (docSnapshot.exists) {
              batch.update(docRef, {
                [`data.${sanitizeFieldPath(newWord)}`]:
                  newWordTranslations[languageId],
              })
            } else {
              batch.set(docRef, {
                data: {
                  [sanitizeFieldPath(newWord)]: newWordTranslations[languageId],
                },
                id: languageId,
                name: docName.charAt(0).toUpperCase() + docName.slice(1),
                googleLangCode: languageId,
              })
            }
          } else {
            console.error(
              `No document name found for language ID: ${languageId}`
            )
          }
        }
      }

      await batch.commit()

      const updatedTranslations = Object.keys(newWordTranslations)
        .filter(languageId => transliterateLanguageIds.includes(languageId))
        .reduce((obj, key) => {
          obj[key] = newWordTranslations[key]
          return obj
        }, {})

      setDictionary({
        ...dictionary,
        [newWord]: updatedTranslations,
      })

      notification.success({
        message: 'Word Added',
        description: `The word "${newWord}" has been added successfully.`,
      })

      setIsConfirmationVisible(false)
      setIsModalVisible(false)
      form.resetFields()
    } catch (error) {
      console.error('Error confirming word addition:', error)
      notification.error({
        message: 'Error Confirming Word Addition',
        description: error.message,
      })
    } finally {
      setIsFetchingTranslations(false)
    }
  }

  const handleModalInputChange = (language, value) => {
    setNewWordTranslations({
      ...newWordTranslations,
      [language]: value,
    })
  }

  const filteredAndSortedDictionary = Object.keys(dictionary)
    .filter(word => word.toLowerCase().includes(searchTerm))
    .sort((a, b) => a.localeCompare(b))
    .reduce((res, key) => ((res[key] = dictionary[key]), res), {})

  return (
    <>
      <Header />
      <SideMenu />
      <section className='mainContent department-wrp'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNamecard
                title='Translation Dictionary'
                breadcrumb={['Super Admin', 'TranslationDictionary']}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 20,
            }}
          >
            <Search
              placeholder='Search words'
              onChange={handleSearch}
              style={{ width: '80%' }}
            />
            <Button className='cmnBtn' onClick={() => setIsModalVisible(true)}>
              Add Word
            </Button>
          </div>
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <Collapse>
              {Object.entries(filteredAndSortedDictionary).map(
                ([word, translations]) => (
                  <Panel header={word} key={word}>
                    <ul>
                      {Object.entries(translations).map(
                        ([language, translation]) => (
                          <li
                            key={language}
                            style={{ marginLeft: 10, marginBottom: 10 }}
                          >
                            <strong>{language}:</strong>
                            <Input
                              value={
                                editableTranslations[word]?.[language] ??
                                translation
                              }
                              onChange={e =>
                                handleInputChange(
                                  word,
                                  language,
                                  e.target.value
                                )
                              }
                              style={{
                                width: 'auto',
                                marginLeft: 10,
                                marginRight: 10,
                              }}
                            />
                          </li>
                        )
                      )}
                    </ul>
                    <Button
                      type='danger'
                      onClick={() => handleDelete(word)}
                      style={{ margin: 10 }}
                    >
                      Delete
                    </Button>
                    <Button
                      className='cmnBtn'
                      onClick={() => handleSave(word)}
                      disabled={
                        !editableTranslations[word] ||
                        Object.keys(editableTranslations[word]).length === 0
                      }
                      style={{ marginTop: 10 }}
                    >
                      Save
                    </Button>
                  </Panel>
                )
              )}
            </Collapse>
          )}
        </div>
      </section>
      <Modal
        title='Add New Word'
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleAddWord}
        okButtonProps={{ disabled: isFetchingTranslations }}
      >
        <Form form={form} layout='vertical'>
          <Form.Item
            name='word'
            label='Word'
            rules={[{ required: true, message: 'Please enter the word' }]}
          >
            <Input placeholder='Enter the word' />
          </Form.Item>
        </Form>
        {isFetchingTranslations && <Spin />}
      </Modal>
      <TranslatedModal
        isVisible={isConfirmationVisible}
        translations={newWordTranslations}
        onInputChange={handleModalInputChange}
        onCancel={() => setIsConfirmationVisible(false)}
        onConfirm={handleConfirmAddWord}
        isFetchingTranslations={isFetchingTranslations}
      />
    </>
  )
}

export default TranslationDictionary
