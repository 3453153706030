/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import ActivityCard from '../../Common/ActivityCard/ActivityCard'
import ProgressBar from '../../Common/ProgressBar/ProgressBar'
import { AddSubscriptionListener } from '../../../services/subscription'
import { GetHotelListener } from '../../../services/hotel'

const SubscriptionCard = ({ total = 0, active = 0 }) => {
  const { subscriptionCountData, subscriptionListenerAdded, hotelListenerAdded } = useSelector(
    state => state
  )
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    AddSubscriptionListener(dispatch, subscriptionListenerAdded)
  }, [dispatch, subscriptionListenerAdded])

  useEffect(() => {
    GetHotelListener(dispatch, hotelListenerAdded)
  }, [dispatch, hotelListenerAdded])

  return (
    <>
      <div className='SubscriptionCard-wrp cmnCard-wrp'>
        <div>
          <div className='cardHead'>
            <h3>Subscriptions</h3>
            {history.location.pathname !== '/Subscriptions' &&
              <a
                className='viewall-link'
                href='#'
                onClick={e => {
                  e.preventDefault()
                  history.push('/Subscriptions')
                }}
              >
                VIEW ALL
              </a>}
          </div>
          <div className="progressbarContainer">
            {subscriptionCountData.map(s => (
              <ProgressBar name={s.name.toUpperCase()} percentage={s.percentage} key={s.id} />
            ))}
          </div>
        </div>
        <ActivityCard total={total} active={active}></ActivityCard>
      </div>
    </>
  )
}

export default SubscriptionCard
