/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { Form, Input, Button, Alert } from 'antd'
import { patterns, secondsToShowAlert } from '../../../../config/constants'
import { ChangePasswordWithOld } from '../../../../services/user'
import { SetAutoClearProp } from '../../../../config/utils'

const ChangePasswordComp = props => {
  const [oldpassword, setOldPassword] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [disabledBtn, setDisabledBtn] = useState(false)
  const [error, setError] = useState('')
  const [showLoader, setShowLoader] = useState(false)

  const successCallback = () => {
    setShowLoader(false)
    setError('')
    setDisabledBtn(true)
    SetAutoClearProp(props.setShowModal, {
      name: 'Success',
      status: true,
      title: 'Password changed sucessfully',
    })
  }

  const errorCallback = err => {
    setShowLoader(false)
    setError(err)
    setDisabledBtn(false)
    // setShowSuccessMessage(false)
    setTimeout(() => setError(''), secondsToShowAlert)
  }
  const onFinish = () => {
    setDisabledBtn(true)
    setShowLoader(true)
    ChangePasswordWithOld(
      oldpassword,
      confirmPassword,
      successCallback,
      errorCallback
    )
  }

  return (
    <>
      <div className='form-wrap'>
        <Form layout='vertical' validateTrigger='' onFinish={onFinish}>
          <div className='row'>
            <div className='col-12'>
              <div className='form-group sign-field'>
                <Form.Item
                  placeholder='Current Password'
                  name='oldpassword'
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your current password',
                    },
                  ]}
                >
                  <Input.Password
                    placeholder='Current Password'
                    value={oldpassword}
                    onChange={e => setOldPassword(e.target.value)}
                    maxLength='50'
                  />
                </Form.Item>
              </div>
            </div>
            <div className='col-12'>
              <div className='form-group sign-field'>
                <Form.Item
                  placeholder='New Password'
                  name='password'
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your new password',
                    },
                    {
                      pattern: patterns.password.regex,
                      message: patterns.password.message,
                    },
                  ]}
                >
                  <Input.Password
                    placeholder='New Password'
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    maxLength='50'
                  />
                </Form.Item>
              </div>
            </div>
            <div className='col-12'>
              <div className='form-group sign-field'>
                <Form.Item
                  placeholder='Confirm your password'
                  name='confirm'
                  //required
                  dependencies={['password']}
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error('The password does not match')
                        )
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder='Confirm your password'
                    name='confirmPassword'
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <Button
            htmlType='submit'
            className='continuebtn'
            block
            disabled={disabledBtn}
            loading={showLoader}
          >
            Confirm
          </Button>
        </Form>
        {error && (
          <Alert message={error} type='error' showIcon className='mt-2' />
        )}
      </div>
    </>
  )
}

export default ChangePasswordComp
