/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { Select } from 'antd'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { countryListAlpha3 } from '../../../config/countryList'
import { getWeeks } from '../../../config/date'
const { Option } = Select

const overViewType = {
  TotalOverView: 'Total Overview',
  CountryView: 'Country View',
}

const overViewTypeArray = Object.values(overViewType).map((item, idx) => {
  return { label: item, value: Object.keys(overViewType)[idx] }
})

const Months = [
  { name: 'January', value: 0, short_name: 'Jan' },
  { name: 'February', value: 1, short_name: 'Feb' },
  { name: 'March', value: 2, short_name: 'Mar' },
  { name: 'April', value: 3, short_name: 'Apr' },
  { name: 'May', value: 4, short_name: 'May' },
  { name: 'June', value: 5, short_name: 'Jun' },
  { name: 'July', value: 6, short_name: 'Jul' },
  { name: 'August', value: 7, short_name: 'Aug' },
  { name: 'September', value: 8, short_name: 'Sep' },
  { name: 'October', value: 9, short_name: 'Oct' },
  { name: 'November', value: 10, short_name: 'Nov' },
  { name: 'December', value: 11, short_name: 'Dec' },
]

export const Days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

export const shortMonth = Months.map(item => item.short_name)
let dummyGraphData = {
  labels: shortMonth,
  datasets: [
    {
      label: 'No of Hotels',
      data: [50, 200, 1000, 150, 100, 80, 200, 300, 150, 100, 80],
      backgroundColor: '#642D90',
      barPercentage: 1.0,
      categoryPercentage: 1.0,
    },
    {
      label: 'No of Rooms',
      data: [200, 300, 50, 50, 100, 150, 200, 300, 50, 50, 100, 150],
      backgroundColor: '#E9DCF5',
      barPercentage: 1.0,
      categoryPercentage: 1.0,
    },
  ],
}

function getAllYear({ hotels }) {
  let yearDataList = hotels.reduce((outSubs, currentValue) => {
    let year = moment(currentValue.createdAt.toDate()).format('YYYY')
    if (!outSubs['years']) {
      outSubs['years'] = []
    }
    outSubs['years'] = [...new Set([...outSubs['years'], +year])]
    return outSubs
  }, {})

  yearDataList['years'] = yearDataList?.years?.sort((a, b) => b - a)

  return { ...yearDataList }
}

function calcGraphDataTotalOverView({ hotels, selectedYear, selectedMonth }) {
  let objGraphData = { ...dummyGraphData }

  let arr = Array.from(Array(12).keys()).map(_item => [0, 0])

  let filterHotels = hotels.filter(hotel => {
    return +moment(hotel.createdAt.toDate()).format('YYYY') === +selectedYear
  })

  objGraphData.labels = shortMonth

  let currWeek

  if (selectedMonth !== -1) {
    filterHotels = filterHotels.filter(hotel => {
      return moment(hotel.createdAt.toDate()).format('M') - 1 === selectedMonth
    })
    currWeek = getWeeks(+selectedYear, selectedMonth)
    arr = Array.from(Array(currWeek?.weekStartEndData.length).keys()).map(
      _item => [0, 0]
    )
    objGraphData.labels = currWeek?.weekStartEndData.map(
      (_item, idx) => `Week ${idx}`
    )
  }

  let monthWiseCount = filterHotels.reduce((outSubs, currentValue) => {
    let monthIndex = moment(currentValue.createdAt.toDate()).format('M') - 1

    if (selectedMonth > -1) {
      let currDate = moment(currentValue.createdAt.toDate())
      const weekIndex = currWeek?.weekStartEndData?.findIndex(
        ([start, end]) =>
          moment(start).isSameOrBefore(currDate) &&
          moment(end).isSameOrAfter(currDate)
      )
      monthIndex = weekIndex
    }

    if (!outSubs['counts']) {
      outSubs['counts'] = []
    }

    if (!outSubs['counts'][monthIndex]) {
      outSubs['counts'][monthIndex] = [0, 0]
    }

    outSubs['counts'][monthIndex][1] += +currentValue.roomCount
    outSubs['counts'][monthIndex][0]++
    return outSubs
  }, {})

  let indxArr = Object.keys(monthWiseCount?.counts ?? [])

  arr = arr.map((item, idx) => {
    if (indxArr.includes(String(idx))) {
      return monthWiseCount?.counts[idx]
    }
    return item
  })

  let hotelsByMonth = arr.map(item => item[0])
  let roomsByMonth = arr.map(item => item[1])

  objGraphData.datasets[0].data = hotelsByMonth
  objGraphData.datasets[1].data = roomsByMonth

  return { ...monthWiseCount, hotelsByMonth, roomsByMonth, objGraphData }
}

function calcGraphDataCountryView({ hotels, selectedYear, selectedMonth }) {
  let objGraphData = { ...dummyGraphData }

  let filterHotels = hotels.filter(hotel => {
    return +moment(hotel.createdAt.toDate()).format('YYYY') === +selectedYear
  })

  if (selectedMonth > -1) {
    filterHotels = filterHotels.filter(
      hotel =>
        moment(hotel.createdAt.toDate()).format('M') - 1 === selectedMonth
    )
    console.log('selectedMonth', filterHotels.length)
  }

  let countryWiseHotelCount = filterHotels.reduce((acc, currHotel) => {
    if (!acc['countries']) {
      acc['countries'] = []
    }

    if (!acc['countries'][currHotel.countryName]) {
      acc['countries'][currHotel.countryName] = [0, 0]
    }

    acc['countries'][currHotel.countryName][0]++
    acc['countries'][currHotel.countryName][1] += +currHotel.roomCount
    return acc
  }, {})

  let countryData = Object.keys(countryWiseHotelCount?.countries ?? []).map(
    item => {
      let findIndex = Object.values(countryListAlpha3)?.findIndex(
        item1 => item1 === item
      )
      if (findIndex > -1) {
        return Object.keys(countryListAlpha3)[findIndex]
      }
    }
  )
  objGraphData.labels = countryData

  let hotelsByMonth = []
  let roomsByMonth = []

  let indxArr1 = Object.keys(countryWiseHotelCount?.countries ?? [])
  indxArr1.forEach(item => {
    let [hotelCount, roomCount] = countryWiseHotelCount.countries[String(item)]
    hotelsByMonth.push(hotelCount)
    roomsByMonth.push(roomCount)
  })

  objGraphData.datasets[0].data = hotelsByMonth
  objGraphData.datasets[1].data = roomsByMonth

  return {
    ...countryWiseHotelCount,
    hotelsByMonth,
    roomsByMonth,
    objGraphData,
  }
}

const GetStepSize = value => {
  if (!isFinite(value)) return 1

  if (value < 10) return 1

  let steps = value / 10
  steps -= steps % 10.0
  return Math.ceil(steps)
}

const OverviewCard = () => {
  const { hotels } = useSelector(state => state)
  const [overview, setOverView] = useState(overViewTypeArray[0].value)
  const [graphData, setGraphData] = useState(dummyGraphData)
  const [yearArray, setYearArray] = useState([new Date().getFullYear()])
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const [selectedMonth, setSelectedMonth] = useState(-1)
  const [stepSize, setStepSize] = useState()

  useEffect(() => {
    let { years } = getAllYear({ hotels })
    setYearArray(years)
  }, [hotels])

  useEffect(() => {
    let currGraphData
    if (overViewType[overview] === overViewType.CountryView) {
      let { objGraphData } = calcGraphDataCountryView({
        hotels,
        selectedYear: selectedYear === -1 ? yearArray[0] : selectedYear,
        selectedMonth,
      })
      currGraphData = objGraphData
    } else {
      let { objGraphData } = calcGraphDataTotalOverView({
        hotels,
        selectedYear,
        selectedMonth,
      })
      currGraphData = objGraphData
    }
    setGraphData({ ...currGraphData })
    const maxYAxisValue = Math.max(
      ...currGraphData.datasets[0].data,
      ...currGraphData.datasets[1].data
    )
    setStepSize(GetStepSize(maxYAxisValue))
  }, [hotels, overview, selectedYear, selectedMonth])

  useEffect(() => {
    if (selectedYear < 0) {
      if (yearArray?.length) {
        setSelectedYear(yearArray[0])
      }
    }
  }, [yearArray, selectedYear])

  return (
    <>
      <div className='bookingoverviewCard-wrp cmnCard-wrp'>
        <div className='cardHead'>
          <h3>Overview </h3>
          <div className='graphfilter d-flex flex-wrap'>
            <div className='filterSelect'>
              <Select
                value={selectedYear}
                onChange={year => {
                  setSelectedYear(year)
                }}
              >
                {yearArray?.map(item => (
                  <Option value={item}>{item}</Option>
                ))}
              </Select>
            </div>
            <div className='filterSelect'>
              <Select
                value={selectedMonth}
                onChange={monthIdx => {
                  setSelectedMonth(monthIdx)
                }}
                style={{ width: 55 }}
              >
                <Option value={-1}>All</Option>
                {Months.map(m => (
                  <Option value={m.value}>{m.short_name}</Option>
                ))}
              </Select>
            </div>
            <div className='filterSelect'>
              <Select
                value={overview}
                onChange={(...args) => {
                  setOverView(args[0])
                }}
              >
                {overViewTypeArray.map(item => (
                  <Option value={item.value}>{item.label}</Option>
                ))}
              </Select>
            </div>
          </div>
        </div>

        <div className='cardBody'>
          <div className='barchart-wrp'>
            <Bar
              data={graphData}
              options={{
                title: {
                  display: false,
                  fontSize: 12,
                },
                legend: {
                  labels: {
                    fontColor: '#31303099',
                  },
                  align: 'end',
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        stepSize,
                        beginAtZero: true,
                        fontColor: '#31303099',
                      },
                    },
                  ],
                  xAxes: [
                    {
                      ticks: {
                        fontColor: '#31303099',
                      },
                    },
                  ],
                },
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                  backgroundColor: '#1B96DC',
                },
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default OverviewCard
