/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { Table, Button, message } from 'antd'

import Header from '../../Common/Header/Header'
import SideMenu from '../../Common/Sidemenu/Sidemenu'
import PageNamecard from '../../Common/PageNameCard/Pagenamecard'

import SubscriptionCard from '../../Common/SubscriptionCard/SubscriptionCard'
import UserModal from './UserModal'
import {
  DeleteUserProfile,
  GetCurrentUser,
  UserListListerner,
} from '../../../services/user'
import { useDispatch, useSelector } from 'react-redux'
import { secondsToShowAlert } from '../../../config/constants'
import DeleteModal from '../../Common/Modals/DeleteModal'
import SuccessModal from '../../Common/Modals/SuccessModal'

const Users = () => {
  const { userInfo } = useSelector(state => state)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [userToDelete, setUserToDelete] = useState(null)
  const [loadingData, setLoadingData] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  let userColumns = [
    {
      title: 'Admin Staff',
      dataIndex: 'name',
      width: 160,
      render: (name, _row) => {
        return (
          <div className='tableuser sa-tableuser'>
            {/* <figure>
              <img className='img-fluid' src='images/hotelLogo.svg'></img>
            </figure> */}
            <span>{name ?? ''} </span>
          </div>
        )
      },
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      width: 100,
    },
    {
      title: 'Email Id',
      dataIndex: 'email',
      width: 170,
    },
    {
      title: 'Phone No',
      dataIndex: 'contactNumber',
      width: 110,
      render: (_name, row) => {
        return `${row?.contactNumberPrefix ?? ''} ${row?.contactNumber ?? ''}`
      },
    },
  ]

  if (userInfo?.isSuperUser) {
    userColumns = [
      ...userColumns,
      {
        title: 'Action',
        dataIndex: 'Action',
        width: 80,
        render: (_key, row) => {
          return (
            <div className='tableactionbtn-grp'>
              <button
                onClick={() => {
                  setShowDeleteConfirmation(true)
                  setUserToDelete(row)
                }}
                disabled={
                  !(
                    row.id !== GetCurrentUser().uid &&
                    userInfo?.isSuperUser &&
                    !row?.isSuperUser
                  )
                }
              >
                <img src='/images/sa-tdelete.svg'></img>
              </button>
            </div>
          )
        },
      },
    ]
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const dispatch = useDispatch()
  const { userListListenerAdded, superAdminUsers } = useSelector(state => state)

  useEffect(() => {
    UserListListerner(dispatch, userListListenerAdded)
  }, [dispatch, userListListenerAdded])

  const hideDeleteConfirmation = () => setShowDeleteConfirmation(false)

  const deleteUserProfile = async () => {
    try {
      setShowDeleteConfirmation(false)
      setLoadingData(true)
      const { success, message: deleteUserProfileMessage } =
        await DeleteUserProfile(userToDelete.id)
      setLoadingData(false)
      if (success) {
        setSuccessMessage('Deleted successfully')
        setShowSuccessModal(true)
        setTimeout(() => {
          setShowSuccessModal(false)
          setSuccessMessage('')
        }, secondsToShowAlert)
      } else {
        message.error(deleteUserProfileMessage)
      }
    } catch (error) {
      console.log(error)
      message.error('Something went wrong! Please try again!')
    }
  }

  return (
    <>
      <Header></Header>
      <SideMenu></SideMenu>
      <section className='mainContent'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNamecard
                title='Users'
                breadcrumb={['Super Admin', 'Users']}
              />
            </div>

            <div className='col-12 col-md-12 col-lg-8'>
              <div className='tablefilter-wrp'>
                <div className='form-row justify-content-end'>
                  <div className='col-4 col-md-auto'>
                    <Button className='cmnBtn' onClick={showModal}>
                      Add User
                    </Button>
                  </div>
                </div>
              </div>

              <div className='table-wrp'>
                <Table
                  columns={userColumns}
                  dataSource={superAdminUsers}
                  pagination={false}
                  scroll={{ y: 496 }}
                  loading={loadingData}
                />
              </div>
            </div>
            <div className='col-12 col-md-12 col-lg-4'>
              <SubscriptionCard></SubscriptionCard>
            </div>
          </div>
        </div>
      </section>
      {isModalVisible && (
        <UserModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          loadingData={loadingData}
          setLoadingData={setLoadingData}
          title='Add User'
          setShowSuccessModal={setShowSuccessModal}
          closeModal={() => setIsModalVisible(false)}
          setSuccessMessage={setSuccessMessage}
          superAdminUsers={superAdminUsers}
        ></UserModal>
      )}

      <DeleteModal
        onOk={deleteUserProfile}
        onCancel={hideDeleteConfirmation}
        className='deleteModal cmnModal'
        footer={null}
        centered
        visible={showDeleteConfirmation}
        title='Confirm Delete'
        message='Do you really want to delete ?'
      />

      {showSuccessModal && (
        <SuccessModal
          showSuccessModal={showSuccessModal}
          title={successMessage}
          setShowSuccessModal={() => setShowSuccessModal(false)}
        ></SuccessModal>
      )}
    </>
  )
}

export default Users
