import { actions } from '../app/store'
import { Collections } from '../config/constants'
import { db } from '../config/firebase'
import { resType } from '../config/utils'
import { creationData, updationData } from './common'

export async function saveHotelGroup(
  data,
  { setResMessage, setShowLoader, closeHotelGroupModal }
) {
  try {
    let hotelGroupRef = db.collection(Collections.HOTEL_GROUPS)
    let response = {
      type: resType.success,
      message: 'Save Hotel Group Successfully.',
    }

    if (data?.id) {
      let id = data?.id
      delete data['id']
      await hotelGroupRef.doc(id).update({ ...data, ...updationData() })
      response.message = 'Update Hotel Group Successfully.'
    } else {
      await hotelGroupRef.doc().set({ ...data, ...creationData() })
    }

    setResMessage(response)
    closeHotelGroupModal()
  } catch (error) {
    console.log({ error })
    console.log(error?.message)
    setResMessage({ type: resType.error, message: 'Error' })
  } finally {
    setShowLoader(false)
  }
}

let unsubMaintenanceListner = null
export const hotelGroupListerner = dispatch => {
  try {
    if (unsubMaintenanceListner) return
    unsubMaintenanceListner = db
      .collection(Collections.HOTEL_GROUPS)
      .onSnapshot(snapshot => {
        let hotelGroupByName = {}
        let hotelGroupById = {}
        for (const doc of snapshot.docs) {
          let data = { id: doc.id, ...doc.data() }
          hotelGroupByName[data.name.toLowerCase()] = data
          hotelGroupById[data.id] = data
        }
        dispatch(actions.setHotelGroups({ hotelGroupById, hotelGroupByName }))
      })
  } catch (error) {
    console.log({ error })
  }
}
