import React, { useContext, useState } from 'react'
import { Button, Modal, Select, Input, Form } from 'antd'
import { SettingsState } from '../model'
const { Option } = Select

export default function HotelSettings() {
  const {
    openModal,
    setOpenModal,
    hotelList,
    submitPassword,
    passwordUpdating,
  } = useContext(SettingsState)

  const [title, setTitle] = useState(null)

  const [form] = Form.useForm()

  const buttonClick = type => {
    if (type === 'byhotel') {
      setTitle('Change Staff Password By Hotel')
      setOpenModal(!openModal)
    }
  }

  const cancelButtonClick = () => {
    setTitle(null)
    setOpenModal(!openModal)
  }

  const passwordSubmitHandler = async values => {
    await submitPassword(values, form, cancelButtonClick)
  }

  return (
    <>
      <div className='col-12 col-xl-12'>
        <div className='row'>
          <Button
            type='primary'
            size='large'
            shape='round'
            onClick={() => {
              buttonClick('byhotel')
            }}
          >
            Change Staff Password By Hotel
          </Button>
        </div>
      </div>
      {openModal && (
        <Modal
          title={title}
          visible={openModal}
          onCancel={cancelButtonClick}
          className='addUsermodal cmnModal'
          footer={null}
          centered
        >
          <Form
            layout='vertical'
            onFinish={passwordSubmitHandler}
            form={form}
            validateTrigger
          >
            <div className='row'>
              <div className='col-12'>
                <div className='form-group cmn-input'>
                  <Form.Item
                    label='Password'
                    name='passwordText'
                    rules={[
                      {
                        required: true,
                        message: 'Please enter password ',
                      },
                    ]}
                  >
                    <Input placeholder='Password' />
                  </Form.Item>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-12'>
                <div className='form-group cmn-input'>
                  <Form.Item
                    label='Hotel'
                    name='hotel'
                    rules={[
                      {
                        required: true,
                        message: 'Please select hotel',
                      },
                    ]}
                  >
                    <Select
                      showSearch={true}
                      optionFilterProp='children'
                      placeholder='Select Hotel'
                    >
                      {hotelList.map(hot => (
                        <Option value={hot.id} key={hot.id}>
                          {hot.hotelName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className='modalFooter'>
              <Button
                className='grayBtn'
                key='back'
                onClick={cancelButtonClick}
              >
                Cancel
              </Button>

              <Button
                className='blueBtn ml-3 ml-lg-4'
                key='submit'
                htmlType='submit'
                loading={passwordUpdating}
              >
                Submit
              </Button>
            </div>
          </Form>
        </Modal>
      )}
    </>
  )
}
