import { actions } from '../app/store'
import { Collections, unsubscribeList } from '../config/constants'
import { db } from '../config/firebase'
import { Sort } from '../config/utils'
import { isLoggedIn } from './user'

export const DefaultLocationTypeListerner = dispatch => {
  if (!isLoggedIn()) return
  if (!unsubscribeList[Collections.USERS]) {
    try {
      const unsub = db
        .collection(Collections.DEFAULTLOCATIONTYPES)
        .where('isDelete', '==', false)
        .onSnapshot(locationTypeSnapshot => {
          let locationTypes = []
          locationTypeSnapshot.forEach(doc => {
            locationTypes.push({ id: doc.id, ...doc.data() })
          })
          locationTypes = Sort(locationTypes, 'name')
          dispatch(actions.setDefaultLocationTypes(locationTypes))
        })

      unsubscribeList[Collections.DEFAULTLOCATIONTYPES] = unsub
    } catch (error) {
      console.log({ error })
    }
  }
}
