/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react'

function CountCard(props) {
  const { cardColorClassname, title, desc, image } = props

  return (
    <div className={`countcard-wrp Gr-${cardColorClassname}`}>
      <div>
        <h4>{title}</h4>
        <h6>{desc}</h6>
      </div>
      <figure>
        <img src={image}></img>
      </figure>
    </div>
  )
}

CountCard.defaulProps = {
  cardColorClassname: 'brown',
  title: '1',
  desc: 'Description',
  image: 'images/count-rquest-br.svg',
}

export default CountCard
