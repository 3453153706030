import React from 'react'
import { Modal, Input } from 'antd'
import { Spin } from 'antd'

const TranslatedModal = ({
  isVisible,
  translations,
  onInputChange,
  onCancel,
  onConfirm,
  isFetchingTranslations,
}) => {
  const languageCodeToName = {
    en: 'English',
    fr: 'French',
    hi: 'Hindi',
    ur: 'Urdu',
    ar: 'Arabic',
    ml: 'Malayalam',
    ru: 'Russian',
    zh: 'Chinese',
    de: 'German',
    it: 'Italian',
    es: 'Spanish',
    tl: 'Tagalog',
  }

  return (
    <Modal
      title='Confirm Translations'
      visible={isVisible}
      onCancel={onCancel}
      onOk={onConfirm}
    >
      <p>Confirm the translations and transliterations for the new word:</p>
      <div className='row'>
        {Object.entries(translations).map(([language, translation]) => (
          <div
            key={language}
            style={{ marginBottom: 10 }}
            className='form-group cmn-input col-6'
          >
            {languageCodeToName[language]}
            <Input
              value={translation}
              onChange={e => onInputChange(language, e.target.value)}
              style={{ marginLeft: 10 }}
            />
          </div>
        ))}
      </div>
      {isFetchingTranslations && <Spin />}
    </Modal>
  )
}

export default TranslatedModal
