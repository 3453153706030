/* eslint-disable array-callback-return */
import axios from 'axios'

import { db } from '../config/firebase'
import { Encrypt, GetAxiosHeaders, Sort } from '../config/utils'
import { DeleteFile, UploadFile, creationData, updationData } from './common'
import { APIs, Collections, unsubscribeList } from '../config/constants'
import { actions } from '../app/store'
import { isLoggedIn } from './user'

export const GetHotels = async () => {
  let hotels = []
  try {
    const hotelsSnapshot = await db
      .collection(Collections.HOTELS)
      .where('isDelete', '==', false)
      .get()
    if (hotelsSnapshot) {
      console.log('Hotels Count : ', hotelsSnapshot.size)
      for (const hotel of hotelsSnapshot.docs) {
        hotels.push({ id: hotel.id, ...hotel.data() })
      }
    }
  } catch (error) {
    console.log({ error })
  }
  return hotels
}

export const GetCountryList = hotels => {
  let cntry = []

  hotels.map(hotel => cntry.push(hotel.countryName))

  return cntry.filter(function (elem, pos) {
    return cntry.indexOf(elem) === pos
  })
}

export const GetCityList = hotels => {
  let city = []

  hotels.map(hotel => city.push(hotel.city))

  return city.filter(function (elem, pos) {
    return city.indexOf(elem) === pos
  })
}

async function copyDefaultDepartmentsToHotel({
  hotelId,
  checkBatchLimit,
  batchArray,
  batchIndex,
}) {
  // copy defaultDepartments to departments collection
  const departmentsRef = db.collection(Collections.DEPARTMENTS)
  const defaultDepartmentsRef = db.collection(Collections.DEFAULTDEPARTMENTS)
  const defaultDepartmentsSnapshot = await defaultDepartmentsRef
    // .where(defaultKey, '==', true) // assigning all the available default+predefined to every hotel
    .get()

  const requiredTime = '00:30'

  const mobieDashboardRef = db.collection(Collections.MOBILE_DASHBOARD)
  const hotelDocRef = mobieDashboardRef.doc(hotelId)

  const departmentRequestStatRef = hotelDocRef.collection(
    Collections.DEPARTMENTREQUESTSTAT
  )

  batchArray[batchIndex].set(hotelDocRef, {
    hotelId,
    overall_completed: 0,
    overall_pending: 0,
    overall_request: 0,
    overall_rejected: 0,
    overall_defer: 0,
  })
  checkBatchLimit()

  // saving services & sub-services for department
  async function saveServiceAndSubService({ dRef, defaultDocRef }) {
    const deptReqStatDoc = departmentRequestStatRef.doc(dRef.id)
    const deptRef = deptReqStatDoc.collection(Collections.DEPARTMENTREQUEST)
    const guestRef = deptReqStatDoc.collection(Collections.GUESTREQUESTS)

    // services
    const defaultServicesSnapshot = await defaultDocRef.ref
      .collection(Collections.SERVICES)
      .get()
    for (const serviceDoc of defaultServicesSnapshot.docs) {
      const serviceId = serviceDoc.id
      let { name, key = '' } = serviceDoc.data()

      const serviceObj = {
        count: 0,
        id: serviceId,
        name,
        isSubService: false,
        subServiceName: '',
        subServiceid: '',
        key,
      }
      const serviceData = {
        ...serviceDoc.data(),
        refDefaultId: serviceId,
        requiredTime,
        hotelId,
        ...creationData(),
      }
      const serviceRef = dRef.collection(Collections.SERVICES).doc()

      batchArray[batchIndex].set(serviceRef, serviceData)
      checkBatchLimit()

      // sub services
      const defaultSubServicesSnapshot = await serviceDoc.ref
        .collection(Collections.SERVICES)
        .get()
      if (defaultSubServicesSnapshot.size > 0) {
        for (const subServiceDoc of defaultSubServicesSnapshot.docs) {
          const subServiceData = {
            ...subServiceDoc.data(),
            refDefaultId: subServiceDoc.id,
            requiredTime,
            hotelId,
            ...creationData(),
          }
          const subServiceRef = serviceRef
            .collection(Collections.SERVICES)
            .doc()

          batchArray[batchIndex].set(subServiceRef, subServiceData)
          checkBatchLimit()

          const defaultServiceCounter = {
            ...serviceObj,
            isSubService: true,
            subServiceName: subServiceData.name,
            subServiceid: subServiceRef.id,
            key: subServiceData.key || '',
          }
          batchArray[batchIndex].set(
            deptRef.doc(subServiceRef.id),
            defaultServiceCounter
          )
          checkBatchLimit()

          batchArray[batchIndex].set(
            guestRef.doc(subServiceRef.id),
            defaultServiceCounter
          )
          checkBatchLimit()
        }
      } else {
        batchArray[batchIndex].set(deptRef.doc(serviceRef.id), serviceObj)
        checkBatchLimit()

        batchArray[batchIndex].set(guestRef.doc(serviceRef.id), serviceObj)
        checkBatchLimit()
      }
    }
  }

  const promises = []
  for (const defaultDepartmentDoc of defaultDepartmentsSnapshot.docs) {
    const departmentId = defaultDepartmentDoc.id
    const newDepartmentRef = departmentsRef.doc()
    const data = {
      ...defaultDepartmentDoc.data(),
      refDefaultId: departmentId,
      hotelId,
      requiredTime,
      ...creationData(),
    }

    batchArray[batchIndex].set(newDepartmentRef, data)
    checkBatchLimit()

    promises.push(
      saveServiceAndSubService({
        dRef: newDepartmentRef,
        defaultDocRef: defaultDepartmentDoc,
      })
    )
  }
  await Promise.all(promises)
  return defaultDepartmentsSnapshot.size
}

async function copyDefaultOverAllQuestionsToHotel({
  hotelId,
  checkBatchLimit,
  batchArray,
  batchIndex,
}) {
  const defaultQuestions = await db
    .collection(Collections.DEFAULTOVERALLFEEDBACKQUESTION)
    .get()

  const defaultQuestionsRef = db
    .collection(Collections.OVERALLFEEDBACKQUESTIONS)
    .doc(hotelId)
    .collection(Collections.HOTELOVERALLFEEDBACKQUESTIONS)

  for (const defaultLocationType of defaultQuestions.docs) {
    batchArray[batchIndex].set(defaultQuestionsRef.doc(), {
      ...defaultLocationType.data(),
      hotelId,
      ...creationData(),
    })
    checkBatchLimit()
  }
}

async function copyDefaultLocationTypesToHotel({
  hotelId,
  checkBatchLimit,
  batchArray,
  batchIndex,
}) {
  const defaultLocationTypeSnap = await db
    .collection(Collections.DEFAULTLOCATIONTYPES)
    .get()

  const locationTypesRef = db
    .collection(Collections.LOCATIONTYPES)
    .doc(hotelId)
    .collection(Collections.HOTELLOCATIONTYPES)

  for (const defaultLocationType of defaultLocationTypeSnap.docs) {
    batchArray[batchIndex].set(locationTypesRef.doc(), {
      ...defaultLocationType.data(),
      hotelId,
      ...creationData(),
    })
    checkBatchLimit()
  }
}

async function copyDefaultServicesToHotel({
  hotelId,
  checkBatchLimit,
  batchArray,
  batchIndex,
}) {
  const defaultServicesSnap = await db
    .collection(Collections.DEFAULTSERVICES)
    .get()
  const servicesRef = db.collection(Collections.SERVICES)
  for (const defaultService of defaultServicesSnap.docs) {
    const serviceRef = servicesRef.doc()

    batchArray[batchIndex].set(serviceRef, {
      ...defaultService.data(),
      hotelId,
      ...creationData(),
    })
    checkBatchLimit()
  }
}

async function copyDefaultCuisinesToHotel({
  hotelId,
  checkBatchLimit,
  batchArray,
  batchIndex,
}) {
  const defaultCuisinesRef = await db
    .collection(Collections.DEFAULTCUISINES)
    .get()
  let defaultCuisineList = []
  for (const defaultCuisine of defaultCuisinesRef.docs) {
    const cuisine = { ...defaultCuisine.data() }
    defaultCuisineList.push(cuisine)
  }
  defaultCuisineList = Sort(defaultCuisineList, 'index')

  const cuisinesRef = db.collection(Collections.CUISINES)
  defaultCuisineList.map(defaultCuisine => {
    const cuisineRef = cuisinesRef.doc()

    batchArray[batchIndex].set(cuisineRef, {
      ...defaultCuisine,
      hotelId,
      ...creationData(),
    })
    checkBatchLimit()
  })
}

async function copyDefaultDataToHotel({
  hotelId,
  checkBatchLimit,
  batchArray,
  batchIndex,
}) {
  await Promise.all([
    copyDefaultDepartmentsToHotel({
      hotelId,
      checkBatchLimit,
      batchArray,
      batchIndex,
    }),
    copyDefaultServicesToHotel({
      hotelId,
      checkBatchLimit,
      batchArray,
      batchIndex,
    }),
    copyDefaultCuisinesToHotel({
      hotelId,
      checkBatchLimit,
      batchArray,
      batchIndex,
    }),
    copyDefaultLocationTypesToHotel({
      hotelId,
      checkBatchLimit,
      batchArray,
      batchIndex,
    }),
    copyDefaultOverAllQuestionsToHotel({
      hotelId,
      checkBatchLimit,
      batchArray,
      batchIndex,
    }),
  ])
}

export const AddNewHotel = async (
  email,
  password,
  hotelLogo,
  newHotel,
  newUser,
  newUserHotelDetails
) => {
  const batchArray = []
  batchArray.push(db.batch())
  let totalOperations = 0
  let operationCounter = 0
  let batchIndex = 0

  const checkBatchLimit = () => {
    totalOperations++
    operationCounter++
    if (operationCounter === 499) {
      batchArray.push(db.batch())
      batchIndex++
      operationCounter = 0
    }
  }

  let newUserId = null
  let newHotelId = null
  let newUserCreated = false
  const headers = await GetAxiosHeaders()
  try {
    // create new user in firebase auth
    const encryptedPassword = Encrypt(password)

    //is user already exists
    const hotelsRef = db.collection(Collections.HOTELS).doc()
    const hotelId = hotelsRef.id
    newHotelId = hotelId
    const { groupId, groupName } = newHotel
    const body = { email, password: encryptedPassword, hotelId, groupId }

    // response - existing tha ya nhi tha true or false, userid will group id
    let responseObj = await axios.post(APIs.CREATE_USER, body, { headers })

    const {
      data: { data: createUserData },
    } = responseObj

    newUserCreated = true

    // upload hotel logo
    let hotelLogoUrl = ''
    let hotelLogoImageName = ''
    if (hotelLogo) {
      const {
        success: uploadFileSuccess,
        downloadUrl,
        message: uploadFileMessage,
        profileImageName: imageName,
      } = await UploadFile(hotelLogo, 'profileImages')
      if (!uploadFileSuccess)
        return { success: false, message: uploadFileMessage }
      hotelLogoUrl = downloadUrl
      hotelLogoImageName = imageName
    }

    // add new hotel to hotels collection
    batchArray[batchIndex].set(hotelsRef, {
      ...newHotel,
      hotelLogo: hotelLogoUrl,
      hotelLogoImageName: hotelLogoImageName,
      ...creationData(),
    })
    checkBatchLimit()

    // add hotel admin to users collection
    const usersRef = db.collection(Collections.USERS).doc(createUserData.uid)

    let {
      profileImage = '',
      profileImageName = '',
      uid: userId,
    } = createUserData

    newUserId = userId

    let userData = {
      ...newUser,
      userId,
      profileImage,
      profileImageName,
      username: newUser.email,
      isEmailUser: true,
    }

    const userHotelRef = usersRef
      .collection(Collections.USERHOTELS)
      .doc(hotelId)

    batchArray[batchIndex].set(userHotelRef, {
      ...userData,
      ...newUserHotelDetails,
      hotelId,
      level: 0,
      isEmailUser: true,
      ...creationData(),
    })
    checkBatchLimit()

    await copyDefaultDataToHotel({
      hotelId,
      checkBatchLimit,
      batchArray,
      batchIndex,
    })

    // save sub-domain name
    const subDomain = await GetSubDomain(
      newHotel.hotelName,
      newHotel.city,
      hotelId
    )
    const subDomainRef = db.collection(Collections.DOMAIN).doc(hotelId)

    batchArray[batchIndex].set(subDomainRef, {
      subDomain,
      hotelLogo: hotelLogoUrl,
      hotelName: newHotel.hotelName,
      groupId,
      groupName,
      ...creationData(),
    })
    checkBatchLimit()

    const hotelAdminDashboardDoc = {
      occupiedRooms: 0,
      reservationCompleted: 0,
      reservationInProgress: 0,
      reservationPending: 0,
      reservationRejected: 0,
      roomCount: +newHotel.roomCount,
      staffActiveCount: 0,
      staffInactiveCount: 0,
      totalDepartments: 0,
      totalBooking: 0,
    }

    batchArray[batchIndex].set(
      db.collection(Collections.HOTELADMINDASHBOARD).doc(hotelId),
      hotelAdminDashboardDoc
    )
    checkBatchLimit()

    if (totalOperations) {
      console.log('✔ Writing hotel data...')
      console.log('✔ Total counter ', operationCounter)
      let batchCommitCounter = 0
      for (const batch of batchArray) {
        // eslint-disable-next-line
        batchCommitCounter++
        await batch.commit()
      }
    } else {
      console.log('✔ NOTHING TO Add')
    }
    return {
      success: true,
      message: 'Hotel added successfully',
      subDomain: subDomain,
    }
  } catch (error) {
    console.log({ error })
    console.log(error?.message)
    console.log(error?.response)
    if (newUserCreated) await hardDeleteUser(newUserId, newHotelId)
    return {
      success: false,
      message: error?.response?.data?.message || error?.message,
    }
  }
}

export const EditHotel = async (
  profileImage,
  hotel,
  profileImageUrl,
  hotelImageName,
  deleteProfileImage,
  hotelId
) => {
  try {
    // delete old profile image
    let response
    let newHotelImageUrl = profileImageUrl
    let newHotelImageName = hotelImageName
    if (hotelImageName && (deleteProfileImage || profileImage)) {
      newHotelImageUrl = ''
      newHotelImageName = ''
      response = await DeleteFile(hotelImageName)
      if (!response.success) {
        return { success: false, message: response.message }
      }
    }

    // update new profile Image
    if (profileImage) {
      const {
        success,
        downloadUrl,
        message,
        profileImageName: imageName,
      } = await UploadFile(profileImage, 'profileImages')
      if (!success) return { success: false, message }
      newHotelImageUrl = downloadUrl
      newHotelImageName = imageName
    }

    const batch = db.batch()

    // update profile data
    const hotelRef = db.collection(Collections.HOTELS).doc(hotelId)
    batch.update(hotelRef, {
      ...hotel,
      hotelLogo: newHotelImageUrl,
      hotelLogoImageName: newHotelImageName,
      ...updationData(),
    })

    const subDomain = await GetSubDomain(hotel.hotelName, hotel.city, hotelId)
    const subDomainRef = db.collection(Collections.DOMAIN).doc(hotelId)
    batch.update(subDomainRef, {
      subDomain,
      hotelLogo: newHotelImageUrl,
      hotelName: hotel.hotelName,
      ...updationData(),
    })

    batch.set(
      db.collection(Collections.HOTELADMINDASHBOARD).doc(hotelId),
      { roomCount: +hotel.roomCount },
      { merge: true }
    )

    await batch.commit()

    return { success: true, message: 'Hotel updated successfully' }
  } catch (error) {
    console.log({ error })
    console.log(error?.message)
    return { success: false, message: error?.message }
  }
}

const hardDeleteUser = async (userId, newHotelId) => {
  try {
    const headers = await GetAxiosHeaders()
    const encryptedUserId = Encrypt(userId)
    const body = { userId: encryptedUserId, hotelId: newHotelId }
    await axios.post(APIs.HARD_DELETE_USER, body, { headers })
  } catch (error) {
    console.log({ error })
    console.log(error?.message)
  }
}

export const UpdateHotel = async (id, hotel) => {
  try {
    await db
      .collection(Collections.HOTELS)
      .doc(id)
      .update({ ...hotel, ...updationData() })
    await db
      .collection(Collections.DOMAIN)
      .doc(id)
      .update({ status: hotel.status, ...updationData() })
    return { success: true, message: '' }
  } catch (error) {
    console.log({ error })
    console.log(error?.message)
    return { success: false, message: 'Problem updating user!' }
  }
}

export const DeleteHotelProfile = async hotelId => {
  try {
    const headers = await GetAxiosHeaders()
    const body = { hotelId: Encrypt(hotelId) }
    await axios.post(APIs.DELETE_HOTEL, body, { headers })
    return { success: true, message: 'Hotel deleted successfully' }
  } catch (error) {
    console.log({ error })
    console.log(error?.message)
    console.log(error?.response)
    return { success: false, message: error?.message }
  }
}

export const GetActiveHotelsCount = async () => {
  try {
    const hotelsSnapshot = await db
      .collection(Collections.HOTELS)
      .where('status', '==', 'active')
      .where('isDelete', '==', false)
      .get()
    return hotelsSnapshot.size
  } catch (error) {
    console.log({ error })
  }
  return 0
}

export const filteredHotelByStatus = (hotels, value) => {
  let filteredHotel = []
  try {
    filteredHotel = hotels.filter(hotel => hotel.status === value)
  } catch (error) {
    console.log({ error })
  }
  return filteredHotel
}

export const filteredHotelByName = (hotels, value) => {
  let filteredHotel = []
  try {
    filteredHotel = hotels.filter(hotel =>
      hotel.hotelName.toLowerCase().includes(value.toLowerCase())
    )
  } catch (error) {
    console.log({ error })
  }
  return filteredHotel
}

export const filteredHotelByCountry = (hotels, value) => {
  let filteredHotel = []
  try {
    filteredHotel = hotels.filter(hotel =>
      hotel.countryName.toLowerCase().includes(value.toLowerCase())
    )
  } catch (error) {
    console.log({ error })
  }
  return filteredHotel
}

export const filteredHotelByCity = (hotels, value) => {
  let filteredHotel = []
  try {
    filteredHotel = hotels.filter(hotel =>
      hotel.city.toLowerCase().includes(value.toLowerCase())
    )
  } catch (error) {
    console.log({ error })
  }
  return filteredHotel
}

export const filteredHotelByGroup = (hotels, value) => {
  let filteredHotel = []
  try {
    filteredHotel = hotels.filter(
      hotel => hotel?.groupName?.toLowerCase() === value?.toLowerCase()
    )
  } catch (error) {
    console.log({ error })
  }
  return filteredHotel
}

export const GetSubDomain = async (hotelName, city, hotelId) => {
  try {
    const hotelSnapshot = await db.collection(Collections.DOMAIN).get()
    const subDomains = []
    for (const hotel of hotelSnapshot.docs) {
      const subDomainData = { id: hotel.id, ...hotel.data() }
      if (subDomainData.id !== hotelId) subDomains.push(subDomainData.subDomain)
    }

    let newSubDomain = hotelName
      .trim()
      .replace(/[^0-9a-z ]/gi, '')
      .replace(/ /gi, '-')
      .toLowerCase()
    newSubDomain +=
      '-' +
      city
        .trim()
        .replace(/[^0-9a-z ]/gi, '')
        .replace(/ /gi, '')
        .toLowerCase()
        .slice(0, 3)

    let counter = 1
    let tempSubDomain = newSubDomain
    let subDomainExists = subDomains.includes(tempSubDomain)
    while (subDomainExists) {
      tempSubDomain = `${newSubDomain}${counter}`
      subDomainExists = subDomains.includes(tempSubDomain)
      counter++
    }
    newSubDomain = tempSubDomain
    while (newSubDomain.indexOf('--') >= 0) {
      newSubDomain = newSubDomain.replace('--', '-')
    }
    return newSubDomain
  } catch (error) {
    console.log({ error })
  }
}

export const GetHotelListener = (dispatch, hotelListenerAdded) => {
  if (!isLoggedIn()) return
  try {
    if (!hotelListenerAdded && !unsubscribeList[Collections.HOTELS]) {
      dispatch(actions.setHotelListenerAdded(true))

      const unsub = db
        .collection(Collections.HOTELS)
        .where('isDelete', '==', false)
        .onSnapshot(hotelSnapshot => {
          let hotels = []
          for (const hotel of hotelSnapshot.docs) {
            hotels.push({ id: hotel.id, ...hotel.data() })
          }

          hotels = Sort(hotels, 'hotelName')

          let count = 0
          let totalRoomCount = hotels.reduce((totalValue, currentValue) => {
            let cv = isNaN(parseInt(currentValue.roomCount))
              ? 0
              : parseInt(currentValue.roomCount)
            return totalValue + cv
          }, count)

          const uniqueCountries = [
            ...new Set(hotels.map(data => data.countryName)),
          ]
          const totalCountryCount = uniqueCountries.length

          let totalHotelCount = hotels.length
          let activeHotelCount = hotels.filter(
            item => item.status === 'active'
          ).length
          let activeHotelPercentage = Math.floor(
            (activeHotelCount / totalHotelCount) * 100
          )
          let inActiveHotelPercentage = 100 - activeHotelPercentage

          let totalSubscription = hotels.reduce((outSubs, currentValue) => {
            if (!outSubs[currentValue.subscription]) {
              outSubs[currentValue.subscription] = 0
            }
            outSubs[currentValue.subscription]++
            return outSubs
          }, {})

          let totalSubscriptionCount = Object.entries(totalSubscription)

          dispatch(
            actions.setHotels({
              hotels,
              totalHotelCount,
              totalRoomCount,
              totalCountryCount,
              activeHotelCount,
              activeHotelPercentage,
              inActiveHotelPercentage,
              totalSubscriptionCount,
            })
          )
        })
      unsubscribeList[Collections.HOTELS] = unsub
    }
  } catch (error) {
    console.log({ error })
  }
}

export const defaultOverallFeedbackQuestionListener = dispatch => {
  if (!isLoggedIn()) return
  if (!unsubscribeList[Collections.DEFAULTOVERALLFEEDBACKQUESTION]) {
    try {
      const unsub = db
        .collection(Collections.DEFAULTOVERALLFEEDBACKQUESTION)
        .where('isDelete', '==', false)
        .onSnapshot(snapShot => {
          let array = []
          snapShot.forEach(doc => {
            array.push({ id: doc.id, ...doc.data() })
          })
          array = Sort(array, 'index')
          dispatch(actions.setDefaultOverallFeedbackQuestions(array))
        })

      unsubscribeList[Collections.DEFAULTLOCATIONTYPES] = unsub
    } catch (error) {
      console.log({ error })
    }
  }
}

export const GetHotelDomain = async hotelId => {
  try {
    const subDomainRef = await db
      .collection(Collections.DOMAIN)
      .doc(hotelId)
      .get()
    const res = subDomainRef.data()
    return res?.subDomain
  } catch (error) {
    console.log({ error })
  }
}
