import { actions } from '../app/store'
import { Collections, unsubscribeList } from '../config/constants'
import { db } from '../config/firebase'
import { Sort } from '../config/utils'
import { isLoggedIn } from './user'

export const GuestSettingsListener = dispatch => {
  if (!isLoggedIn()) return
  if (!unsubscribeList[Collections.SUPERADMINSETTINGS]) {
    try {
      const unsub = db
        .collectionGroup(Collections.SUPERADMIN_GUESTSETTINGS)
        .onSnapshot(settingsSanapshot => {
          let guestSettings = []
          settingsSanapshot.forEach(doc => {
            guestSettings.push({ id: doc.id, ...doc.data() })
          })
          guestSettings = Sort(guestSettings, 'order')
          dispatch(actions.setGuestSettingsList(guestSettings))
        })

      unsubscribeList[Collections.DEFAULTLOCATIONTYPES] = unsub
    } catch (error) {
      console.log({ error })
    }
  }
}
