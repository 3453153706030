import React from 'react'
import { Breadcrumb } from 'antd'

const PageNamecard = ({ title, breadcrumb }) => {
  return (
    <>
      <div className='Pagenamecard-wrp'>
        <div>
          <h1>{title}</h1>
          <Breadcrumb>
            {Array.isArray(breadcrumb)
              ? breadcrumb.map((item, idx) => {
                  return (
                    <Breadcrumb.Item key={idx}>
                      {typeof item === 'object' ? item.hotelName : item}
                    </Breadcrumb.Item>
                  )
                })
              : null}
          </Breadcrumb>
        </div>
      </div>
    </>
  )
}

export default PageNamecard
