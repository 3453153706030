import React, { useContext } from 'react'
import { Button, Select, Upload, Alert } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { SettingsState } from '../model'
const { Option } = Select

export default function GuestBulkUpload() {
  const {
    selectedHotel,
    setSelectedHotel,
    hotelList,
    submitBulkData,
    uploading,
    fileProps,
  } = useContext(SettingsState)

  return (
    <>
      <div className='col-12 col-xl-12'>
        <div className='row'>
          <div className='col-12 col-xl-12'>
            <div className='tablefilter-wrp'>
              <div className='form-row justify-content-end'>
                <div className='col-12'>
                  <Alert
                    message='Upload Bulk Guest Data To Hotel : Please confirm the excel format before uploading'
                    type='warning'
                  />
                </div>
              </div>
            </div>
            <div className='tablefilter-wrp'>
              <div className='form-row '>
                <div className='col-4'>
                  <div className='cmnSelect-form'>
                    <Select
                      showSearch={true}
                      value={selectedHotel}
                      onChange={hot => setSelectedHotel(hot)}
                      optionFilterProp='children'
                      placeholder='Select Hotel'
                    >
                      {hotelList.map(hot => (
                        <Option value={hot.id} key={hot.id}>
                          {hot.hotelName}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            </div>
            <div className='tablefilter-wrp'>
              <div className='form-row '>
                <div className='col-4'>
                  <div className='cmnSelect-form'>
                    <div className='uploadbtn-wrp'>
                      <Upload {...fileProps} accept='.xlsx' maxCount={1}>
                        <Button
                          icon={<UploadOutlined />}
                          style={{ width: '344px' }}
                        >
                          Select File
                        </Button>
                      </Upload>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='tablefilter-wrp'>
              <div className='form-row '>
                <div className='col-12'>
                  <Button
                    className='cmnBtn'
                    style={{ width: '344px' }}
                    onClick={submitBulkData}
                    loading={uploading}
                  >
                    Start Upload
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
