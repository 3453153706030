import { Alert, Button, Form, Input, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { modalTypeConstant, resType, Ternary } from '../../../config/utils'
import { saveHotelGroup } from '../../../services/hotelGroup'
import SuccessModal from '../../Common/Modals/SuccessModal'

function AddEditHotelGroup(props) {
  const {
    status,
    data,
    type,
    closeHotelGroupModal,
    showLoader,
    setShowLoader,
  } = props
  const [form] = Form.useForm()
  const [hotelGroupName, setHotelGroupName] = useState('')
  const [resMessage, setResMessage] = useState({
    type: '',
    message: '',
  })
  const { hotelGroups } = useSelector(state => state)

  useEffect(() => {
    if (type && status) {
      let hGName = ''

      if (type === modalTypeConstant.EditHotelGroup) {
        hGName = data.name
      }

      setHotelGroupName(hGName)
      form.setFieldsValue({ hotelGroupName: hGName })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, status, type])

  useEffect(() => {
    if (resMessage.type) {
      setTimeout(() => {
        setResMessage({ type: '', message: '' })
      }, 2000)
    }
  }, [resMessage])

  const onFinish = () => {
    setShowLoader(true)

    let validHotelGroupName = false
    let nhotelGroupName = hotelGroupName.trim()
    let hotelGroupNames = Object.keys(hotelGroups.hotelGroupByName)
    if (hotelGroupNames.length) {
      if (hotelGroupNames.includes(nhotelGroupName.toLowerCase())) {
        validHotelGroupName = true
      }

      if (validHotelGroupName) {
        setResMessage({
          type: resType.error,
          message: 'Hotel Group Name Already Exist.',
        })
        setShowLoader(false)
        return
      }
    }

    saveHotelGroup(
      { ...data, name: nhotelGroupName },
      { setResMessage, setShowLoader, closeHotelGroupModal }
    )
  }

  if (resMessage.type === resType.success) {
    return (
      <SuccessModal
        title={resMessage.message}
        showSuccessModal={true}
        setShowSuccessModal={() => setResMessage({ type: '', message: '' })}
      />
    )
  }

  return (
    type &&
    status && (
      <>
        <Modal
          title={`${Ternary(
            type === modalTypeConstant.EditHotelGroup,
            'Edit',
            'Add'
          )} Hotel Group`}
          visible={status}
          onOk={onFinish}
          onCancel={closeHotelGroupModal}
          className="cmnModal"
          footer={null}
        >
          <Form
            layout="vertical"
            initialValues={{ hotelGroupName: '' }}
            onFinish={onFinish}
            form={form}
            validateTrigger
          >
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <div className="form-group cmn-input">
                  <Form.Item
                    label="Hotel Group Name"
                    name="hotelGroupName"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter the hotel group name',
                      },
                    ]}
                  >
                    <Input
                      value={hotelGroupName}
                      maxLength={50}
                      onChange={e => setHotelGroupName(e.target.value)}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>

            {resMessage.message && resMessage.type && (
              <Alert
                message={resMessage.message}
                type={resMessage.type}
                showIcon
              />
            )}

            <div className="modalFooter">
              <Button
                className="grayBtn"
                key="back"
                onClick={closeHotelGroupModal}
                disabled={showLoader}
              >
                Cancel
              </Button>

              <Button
                className="blueBtn ml-3 ml-lg-4"
                key="submit"
                htmlType="submit"
                loading={showLoader}
                disabled={showLoader || data?.name === hotelGroupName}
              >
                Submit
              </Button>
            </div>
          </Form>
        </Modal>
      </>
    )
  )
}

AddEditHotelGroup.defaultProps = {
  status: false,
  data: null,
  type: '',
  closeHotelGroupModal: () => {
    console.log('closeHotelGroupModal')
  },
  showLoader: false,
}

export default AddEditHotelGroup
