import React, { useState, createContext, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { APIs, Collections } from '../../../config/constants'
import { db } from '../../../config/firebase'
import { GuestSettingsListener } from '../../../services/guest'
import { GetHotelListener } from '../../../services/hotel'
import axios from 'axios'
import { GetAxiosHeaders, resType } from '../../../config/utils'
import SuccessModal from '../../Common/Modals/SuccessModal'

export const SettingsState = createContext()

export const SettingsStateProvider = props => {
  const { guestSettingsList, hotels, hotelListenerAdded } = useSelector(
    state => state
  )
  const dispatch = useDispatch()
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const selectedTab = urlParams.get('tab') || '1'
  const [tabKey, setTabKey] = useState(selectedTab)
  const [successMessage, setSuccessMessage] = useState('')
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [guestSettingsSaving, setGuestSettingsSaving] = useState(false)
  const [editingGuestSettings, setEditingGuestSettings] = useState(false)
  const [hotelList, setHotelList] = useState([])
  const [selectedHotel, setSelectedHotel] = useState(null)
  const [fileList, setFileList] = useState([])
  const [uploading, setUploading] = useState(false)
  const [workBookData, setWorkBookData] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [passwordUpdating, setPasswordUpdating] = useState(false)
  const [resMessage, setResMessage] = useState({
    type: '',
    message: '',
  })
  const editMode = useMemo(
    () => guestSettingsList?.length > 0,
    [guestSettingsList]
  )

  useEffect(() => {
    GuestSettingsListener(dispatch)
    setEditingGuestSettings(editMode)
    GetHotelListener(dispatch, hotelListenerAdded)
    // eslint-disable-next-line
  }, [dispatch, editMode])

  useEffect(() => {
    if (hotels) {
      // console.log('hotels', hotels)
      // console.log('selectedHotel', selectedHotel)

      setHotelList(hotels.filter(item => item.isDelete === false))
    }
  }, [tabKey, hotels, selectedHotel])

  const handleTabChange = key => {
    setTabKey(key)
    const newUrlParams = new URLSearchParams(location.search)
    newUrlParams.set('tab', key)
    const newUrl = `${location.pathname}?${newUrlParams.toString()}`
    window.history.pushState({ path: newUrl }, '', newUrl)
  }

  useEffect(() => {
    setTabKey(selectedTab)
    // eslint-disable-next-line
  }, [location])

  useEffect(() => {
    if (resMessage.type) {
      setTimeout(() => {
        setResMessage({ type: '', message: '' })
      }, 2000)
    }
  }, [resMessage])

  const manageGuestOnboardingSettings = async values => {
    setGuestSettingsSaving(true)

    if (guestSettingsList?.length > 0) {
      const settingsRef = db.collectionGroup(
        Collections.SUPERADMIN_GUESTSETTINGS
      )

      try {
        const querySnapshot = await settingsRef.get()
        querySnapshot.forEach(async doc => {
          await doc.ref.delete()
        })
      } catch (error) {
        console.log(error?.message)
      }
    }
    const promises = []
    const newsettingsRef = db
      .collection(Collections.SUPERADMINSETTINGS)
      .doc()
      .collection(Collections.SUPERADMIN_GUESTSETTINGS)
    values.forEach(element => {
      promises.push(
        newsettingsRef
          .add(element)
          .then(docRef => {
            // console.log('Document written with ID: ', docRef.id)
          })
          .catch(error => {
            console.error('Error adding document: ', error)
          })
      )
    })
    await Promise.all(promises)
    setSuccessMessage('Guest Settings Saved Successfully')
    setShowSuccessModal(true)
    setGuestSettingsSaving(false)
  }

  const getSuccessModalMessage = () => {
    if (successMessage) return successMessage
    return 'Changes saved sucessfully'
  }

  const fileProps = {
    onRemove: file => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
      setWorkBookData(null)
    },
    beforeUpload: file => {
      handleFileUpload(file)
      setFileList([...fileList, file])
      return false
    },
  }

  const handleFileUpload = async file => {
    // Create a FormData object to send the file to the server.
    const formData = new FormData()
    formData.append('excelFile', file)
    const headers = await GetAxiosHeaders()
    const headerNew = {
      ...headers,
      'Access-Control-Allow-Origin': window.location.host,
      'Access-Control-Allow-Credentials': true,
      'Access-Control-Allow-Methods': '*',
    }
    const responseObj = await axios.post(APIs.EXCEL_FILE_FORMATTER, formData, {
      headerNew,
    })
    const { status } = responseObj
    if (status === 200) {
      setWorkBookData(responseObj?.data)
    } else {
      setWorkBookData(null)
    }
  }

  const submitBulkData = async () => {
    setUploading(true)
    if (selectedHotel && workBookData) {
      const headers = await GetAxiosHeaders()
      const body = { workbookData: workBookData, hotelId: selectedHotel }
      let responseObj = await axios.post(APIs.GUEST_BULK_UPLOAD, body, {
        headers,
      })
      console.log('responseObj', responseObj)

      setResMessage({
        type: resType.success,
        message: 'Guest bulk upload success',
      })
      setUploading(false)
    }
  }

  if (resMessage.type === resType.success) {
    return (
      <SuccessModal
        title={resMessage.message}
        showSuccessModal={true}
        setShowSuccessModal={() => setResMessage({ type: '', message: '' })}
      />
    )
  }

  const submitPassword = async (values, form, cancelButtonClick) => {
    try {
      setPasswordUpdating(true)
      let headers = await GetAxiosHeaders()
      axios
        .post(
          APIs.UPDATE_STAFF_PASSWORD,
          {
            hotelId: values.hotel,
            password: values.passwordText,
          },
          { headers }
        )
        .then(response => {
          const { status } = response
          if (status === 200) {
            form.resetFields()
            const {
              data: { data },
            } = response

            const { message } = data
            setResMessage({
              type: resType.success,
              message: message,
            })
            cancelButtonClick()
          }
        })
      setPasswordUpdating(false)
    } catch (error) {
      setResMessage({
        type: resType.error,
        message: error.message,
      })
    }
  }

  return (
    <SettingsState.Provider
      value={{
        ...{
          tabKey,
          setTabKey,
          handleTabChange,
          manageGuestOnboardingSettings,
          successMessage,
          setSuccessMessage,
          getSuccessModalMessage,
          showSuccessModal,
          setShowSuccessModal,
          guestSettingsSaving,
          setGuestSettingsSaving,
          editingGuestSettings,
          setEditingGuestSettings,
          guestSettingsList,
          hotelList,
          setHotelList,
          selectedHotel,
          setSelectedHotel,
          hotels,
          fileList,
          setFileList,
          uploading,
          setUploading,
          workBookData,
          setWorkBookData,
          submitBulkData,
          fileProps,
          openModal,
          setOpenModal,
          submitPassword,
          passwordUpdating,
          setPasswordUpdating,
        },
      }}
    >
      {props.children}
    </SettingsState.Provider>
  )
}
