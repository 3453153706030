/* eslint-disable jsx-a11y/alt-text */
import React from 'react'

const CountGreenCard = ({ no = 0 }) => {
  return (
    <>
      <div className='countcard-wrp Gr-green'>
        <div>
          <h4>{no}</h4>
          <h6>Total Hotels </h6>
        </div>
        <figure>
          <img src='images/count-departments.svg'></img>
        </figure>
      </div>
    </>
  )
}

export default CountGreenCard
