/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React from 'react'
import { Table } from 'antd'

import Header from '../../Common/Header/Header'
import SideMenu from '../../Common/Sidemenu/Sidemenu'
import PageNamecard from '../../Common/PageNameCard/Pagenamecard'
import { useSelector } from 'react-redux'

const defaultColumns = [
  {
    title: '#',
    width: 10,
    render: (...args) => ++args[2],
  },
  {
    title: 'Question',
    dataIndex: 'question',
    width: 160,
    render: question => question,
  },
]

const OverAllFeedbackQuestions = () => {
  const { defaultOverallFeedbackQuestions } = useSelector(state => state)

  return (
    <>
      <Header></Header>
      <SideMenu></SideMenu>
      <section className='mainContent'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNamecard
                title='Overall Feedback Questions'
                breadcrumb={['Super Admin', 'Overall Feedback Questions']}
              />
            </div>

            <div className='col-12 col-md-12 col-lg-12'>
              <div className='table-wrp'>
                <Table
                  columns={defaultColumns}
                  dataSource={defaultOverallFeedbackQuestions}
                  pagination={false}
                  scroll={{ y: 496 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default OverAllFeedbackQuestions
