import unique from 'uniqid'

import { storageRef, storage, timestamp } from '../config/firebase'
import { GetCurrentUser } from './user'

export const UploadFile = async (file, directory = 'profileImages') => {
  try {
    const uniqueName = unique()
    const fileExtension = file.name.split('.').pop()
    const profileImageName = `${uniqueName}.${fileExtension}`
    const fileRef = storageRef.child(`${directory}/${profileImageName}`)
    const uploadTaskSnapshot = await fileRef.put(file)
    const downloadUrl = await uploadTaskSnapshot.ref.getDownloadURL()
    return { success: true, downloadUrl, message: '', profileImageName }
  } catch (error) {
    console.log({ error })
    return { success: false, downloadUrl: '', message: error?.message }
  }
}

export const FileExists = async (fileName, directory = 'profileImages') => {
  try {
    const file = `${directory}/${fileName}`
    await storage.ref(file).getDownloadURL()
    return { success: true, message: '' }
  } catch (error) {
    console.log({ error })
    return { success: false, message: error?.message }
  }
}

export const DeleteFile = async (fileName, directory = 'profileImages') => {
  try {
    const { success } = await FileExists(fileName, directory)
    if (success) await storageRef.child(`${directory}/${fileName}`).delete()
    return { success: true, message: '' }
  } catch (error) {
    console.log({ error })
    return { success: false, message: error?.message }
  }
}

export const creationData = (includeIsDelete = true) => {
  const uid = GetCurrentUser().uid

  let data = {
    createdBy: uid,
    createdAt: timestamp,
    updatedBy: uid,
    updatedAt: timestamp,
  }

  if (includeIsDelete === true || includeIsDelete === undefined) {
    data['isDelete'] = false
  }

  return data
}

export const updationData = () => {
  const updatedBy = GetCurrentUser().uid
  return {
    updatedBy,
    updatedAt: timestamp,
  }
}
