import React, { useEffect } from 'react'

import Header from '../../Common/Header/Header'
import SideMenu from '../../Common/Sidemenu/Sidemenu'
import PageNamecard from '../../Common/PageNameCard/Pagenamecard'
import CountCard from '../../Common/CountCard'
import SubscriptionCard from '../../Common/SubscriptionCard/SubscriptionCard'
import OverviewCard from '../../Common/OverviewCard/OverviewCard'
import { GetHotelListener } from '../../../services/hotel'
import { useDispatch, useSelector } from 'react-redux'
import { AddSubscriptionListener } from '../../../services/subscription'

const Dashboard = () => {
  const {
    hotelListenerAdded,
    totalHotelCount,
    totalRoomCount,
    totalCountryCount,
    subscriptionListenerAdded,
    subscription,
  } = useSelector(state => state)

  const dispatch = useDispatch()

  useEffect(() => {
    AddSubscriptionListener(dispatch, subscriptionListenerAdded)
    GetHotelListener(dispatch, hotelListenerAdded)
  }, [dispatch, hotelListenerAdded, subscription, subscriptionListenerAdded])

  return (
    <>
      <Header></Header>
      <SideMenu></SideMenu>
      <section className='mainContent'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNamecard title='Dashboard' breadcrumb={['Super Admin', 'Dashboard']} />
            </div>

            <div className='col-12 col-md-12 col-lg-8'>
              <div className='row'>
                <div className='col-12 col-md-4'>
                  <CountCard
                    title={totalCountryCount}
                    desc='Total Countries'
                    cardColorClassname='blue'
                    image='images/count-countries.svg'
                  ></CountCard>
                </div>
                <div className='col-12 col-md-4'>
                  <CountCard
                    title={totalHotelCount}
                    desc='Total Hotels'
                    cardColorClassname='brown'
                    image='images/count-hotel.svg'
                  ></CountCard>
                </div>
                <div className='col-12 col-md-4'>
                  <CountCard
                    title={totalRoomCount}
                    desc='Total Rooms'
                    cardColorClassname='green'
                    image='images/count-rooms.svg'
                  ></CountCard>
                </div>
              </div>
              <OverviewCard />
            </div>
            <div className='col-12 col-md-12 col-lg-4'>
              <SubscriptionCard />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Dashboard
