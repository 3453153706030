import { actions } from '../app/store'
import { Collections, unsubscribeList } from '../config/constants'
import { db } from '../config/firebase'
import { isLoggedIn } from './user'

export const AddServicesListener = (dispatch, servicesListenerAdded) => {
  if (!isLoggedIn()) return

  try {
    if (
      !servicesListenerAdded &&
      !unsubscribeList[Collections.DEFAULTSERVICES]
    ) {
      dispatch(actions.setLoadingServices(true))
      dispatch(actions.setServicesListenerAdded(true))

      const unsub = db
        .collection(Collections.DEFAULTSERVICES)
        .onSnapshot(servicesSnapshot => {
          const services = []
          for (const service of servicesSnapshot.docs) {
            services.push({ id: service.id, ...service.data() })
          }
          dispatch(actions.setServices(services))
          dispatch(actions.setLoadingServices(false))
        })
      unsubscribeList[Collections.DEFAULTSERVICES] = unsub
    }
  } catch (error) {
    console.log({ error })
  }
}
