import axios from 'axios'
import { actions } from '../app/store'
import {
  APIs,
  Collections,
  SuperAdminRole,
  unsubscribeList,
} from '../config/constants'
import { auth, db, firebase } from '../config/firebase'
import { Encrypt, GetAxiosHeaders, Sort } from '../config/utils'
import { creationData, updationData } from './common'

export const GetCurrentUser = () => auth.currentUser
export const isLoggedIn = GetCurrentUser

export const GetCurrentUserToken = async () => auth.currentUser.getIdToken()

export const Login = (rememberMe, email, password, success, error) => {
  const persistenceType = rememberMe
    ? firebase.auth.Auth.Persistence.LOCAL
    : firebase.auth.Auth.Persistence.SESSION
  auth
    .setPersistence(persistenceType)
    .then(() => {
      auth
        .signInWithEmailAndPassword(email, password)
        .then(res => {
          if (success) success(res)
        })
        .catch(err => {
          if (error) error(err)
        })
    })
    .catch(error)
}

export const Logout = (success = () => {}, error = () => {}) => {
  auth
    .signOut()
    .then(res => {
      if (success) {
        success?.(res)
      }
    })
    .catch(err => {
      if (error) error(err)
    })
}

export const ChangePassword = (oobCode, newPassword, success, error) => {
  auth
    .confirmPasswordReset(oobCode, newPassword)
    .then(res => {
      if (success) success(res)
    })
    .catch(err => {
      if (error) error(err)
    })
}

export const ChangePasswordWithOld = async (
  oldPassword,
  newPassword,
  success,
  error
) => {
  try {
    let user = GetCurrentUser()
    let credentials = firebase.auth.EmailAuthProvider.credential(
      user.email,
      oldPassword
    )
    await user
      .reauthenticateWithCredential(credentials)
      .then(_res => {
        user
          .updatePassword(newPassword)
          .then(function (_res1) {
            success()
          })
          .catch(function (err) {
            console.log('err', err)
            error(err?.message)
          })
      })
      .catch(err => {
        console.log('err', err)
        error(err?.message)
      })
  } catch (err) {
    if (error) error(err)
  }
}

export const SendResetPasswordEmail = async email => {
  try {
    await axios.post(APIs.RESET_PASSWORD, { email, isSuperAdmin: true })
    return { success: true, message: '' }
  } catch (error) {
    console.log(error)
    return {
      success: false,
      message: 'Please enter a valid Email ID to receive reset link',
    }
  }
}

export const CheckIfResetPasswordCodeIsNotExpired = async code => {
  try {
    await auth.verifyPasswordResetCode(code)
    return { success: true, message: '' }
  } catch (error) {
    return { success: false, message: 'Link has been expired' }
  }
}

export const GetUserUidFromEmail = async email => {
  try {
    const querySnapshot = await db
      .collection(Collections.USERS)
      .where('email', '==', email)
      .get()
    if (querySnapshot.empty) {
      return null
    } else {
      return querySnapshot.docs[0].id
    }
  } catch (error) {
    console.log({ error })
    console.log(error?.message)
    throw error
  }
}

export const GetSuperAdminDetailsFromUid = async uid => {
  try {
    const userDoc = await db.collection(Collections.USERS).doc(uid).get()
    return userDoc.data()
  } catch (error) {
    console.log({ error })
    console.log(error?.message)
  }
}

export const CreateNewUser = async newUser => {
  try {
    const headers = await GetAxiosHeaders()
    let user = {
      ...newUser,
      superAdmin: true,
      password: Encrypt(newUser.password),
    }
    const res = await axios.post(APIs.CREATE_USER, user, { headers })

    if (res?.data?.statusCode === 200) {
      user = {
        contactNumber: newUser.contactNumber,
        contactNumberPrefix: newUser.contactNumberPrefix,
        email: newUser.email,
        name: newUser.name,
        designation: newUser.designation,
        roles: [SuperAdminRole],
        username: newUser.email,
      }
      await db
        .collection(Collections.USERS)
        .doc(res?.data?.data?.uid)
        .set({ ...user, ...creationData() })
    }

    return { success: true, message: 'User added successfully.' }
  } catch (error) {
    console.log({ error })
    console.log(error?.message)
    return {
      success: false,
      message: error?.response?.data?.message || error?.message,
    }
  }
}

export const EditUserProfile = async (user, userid) => {
  try {
    let objPayload = { ...user, ...updationData() }
    if (user.password) {
      let newPassword = Encrypt(user.password)
      const headers = await GetAxiosHeaders()
      const data = {
        newPassword: newPassword,
        uid: userid,
      }
      const res = await axios.post(APIs.UPDATE_PASSWORD, data, {
        headers,
      })
      objPayload.password = newPassword
      console.log(res)
    }

    await db.collection(Collections.USERS).doc(userid).update(objPayload)

    return { success: true, message: 'Profile Updated successfully' }
  } catch (error) {
    console.log(error?.message)
    return { success: false, message: error?.message }
  }
}

export const DeleteUserProfile = async userId => {
  try {
    const headers = await GetAxiosHeaders()
    const encryptedUserId = Encrypt(userId)
    const body = { userId: encryptedUserId }
    await axios.post(APIs.HARD_DELETE_USER, body, { headers })
    return { success: true, message: 'User deleted successfully' }
  } catch (error) {
    console.log({ error })
    console.log(error?.message)
    console.log(error.response)
    return {
      success: false,
      message: error?.response?.data?.message || error?.message,
    }
  }
}

export const UpdateUser = async (id, user) => {
  try {
    const userRef = db.collection(Collections.USERS).doc(id)
    await userRef.update({ ...user, ...updationData(user.username) })
    return { success: true, message: '' }
  } catch (error) {
    console.log({ error })
    console.log(error?.message)
    return { success: false, message: 'Problem updating user!' }
  }
}

export const UserListListerner = (dispatch, isUserListListenerStatus) => {
  if (!isLoggedIn()) return
  if (!isUserListListenerStatus && !unsubscribeList[Collections.USERS]) {
    dispatch(actions.setUserListListenerStatus(true))
    try {
      const unsub = db
        .collection(Collections.USERS)
        .where('roles', 'array-contains', SuperAdminRole)
        .where('isDelete', '==', false)
        .onSnapshot(usersSnapshot => {
          let users = []
          usersSnapshot.forEach(doc => {
            const user = doc.data()
            users.push({ id: doc.id, ...user })
          })
          users = Sort(users, 'name')
          dispatch(actions.setSuperAdminUser(users))
        })
      unsubscribeList[Collections.USERS] = unsub
    } catch (error) {
      console.log({ error })
    }
  }
}

export const GetLoggedUserDetailsFromEmail = dispatch => {
  try {
    if (!auth.currentUser?.uid || unsubscribeList['USER']) {
      dispatch(actions.setAppStatus(false))
      return
    }
    const unsub = db
      .collection(Collections.USERS)
      .doc(auth.currentUser.uid)
      .onSnapshot(userSnapshot => {
        const uid = auth.currentUser?.uid || null
        const userInfo = { ...userSnapshot.data(), uid } || null
        dispatch(actions.setUserInfo(userInfo))
        dispatch(actions.setAppStatus(false))
      })
    unsubscribeList['USER'] = unsub
  } catch (error) {
    console.log({ error })
    console.log(error?.message)
  }
}
