import React from 'react'

const SuccessModalContent = ({ title }) => {
  return (
    <>
      <div className='successtext-wrp'>
        <svg
          id='checkedsuccess'
          xmlns='https://www.w3.org/2000/svg'
          width='52.317'
          height='52.317'
          viewBox='0 0 52.317 52.317'
        >
          <g id='Group_12365' data-name='Group 12365' transform='translate(12.422 6.573)'>
            <g id='Group_12364' data-name='Group 12364'>
              <path
                className='bgthemecolor'
                id='Path_31902'
                data-name='Path 31902'
                d='M159.967,64.907a1.971,1.971,0,0,0-2.788,0L133.525,88.5l-8.534-9.269a1.972,1.972,0,0,0-2.9,2.671l9.925,10.778a1.969,1.969,0,0,0,1.408.636h.042a1.974,1.974,0,0,0,1.392-.575L159.963,67.7A1.971,1.971,0,0,0,159.967,64.907Z'
                transform='translate(-121.568 -64.327)'
                fill='#1480cd'
              />
            </g>
          </g>
          <g id='Group_12367' data-name='Group 12367'>
            <g id='Group_12366' data-name='Group 12366'>
              <path
                className='bgthemecolor'
                id='Path_31903'
                data-name='Path 31903'
                d='M50.345,24.187a1.972,1.972,0,0,0-1.972,1.972A22.215,22.215,0,1,1,26.159,3.944a1.972,1.972,0,1,0,0-3.944A26.159,26.159,0,1,0,52.317,26.159,1.972,1.972,0,0,0,50.345,24.187Z'
                fill='#1480cd'
              />
            </g>
          </g>
        </svg>

        <h6>{title}</h6>
      </div>
    </>
  )
}

export default SuccessModalContent
