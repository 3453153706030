/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Alert } from 'antd'
import { getParameterByName } from '../../../Common/Functions/reusable'
import { useHistory } from 'react-router'
import {
  ChangePassword,
  CheckIfResetPasswordCodeIsNotExpired,
} from '../../../../services/user'
import { patterns } from '../../../../config/constants'

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [disabledBtn, setDisabledBtn] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [oobCode, setOobCode] = useState('')
  const [showLoader, setShowLoader] = useState(false)
  const history = useHistory()

  useEffect(() => {
    let code = getParameterByName('oobCode')
    setOobCode(code)
    if (!code) {
      history.push('/')
      return
    }
    CheckCode(code)
  }, [])

  const CheckCode = async code => {
    const { success } = await CheckIfResetPasswordCodeIsNotExpired(code)
    if (!success) history.push('/LinkExpired')
  }

  const resetPassword = async () => {
    setDisabledBtn(true)
    setShowLoader(true)

    const successCallback = () => {
      setShowLoader(false)
      setErrorMessage('')
      setDisabledBtn(true)
      setShowSuccessMessage(true)
      setTimeout(() => history.push('/'), 3000)
    }

    const errorCallback = error => {
      setShowLoader(false)
      let currErrorMessage =
        error.message ||
        'Something went wrong while updating new password! Please try again'
      if (currErrorMessage.indexOf('expired'))
        currErrorMessage = 'Link has been expired. Please resend email'
      setErrorMessage(currErrorMessage)
      setDisabledBtn(false)
      setShowSuccessMessage(false)
      setTimeout(() => setErrorMessage(''), 3000)
    }

    ChangePassword(oobCode, newPassword, successCallback, errorCallback)
  }
  return (
    <>
      <div className='signin-wrp'>
        <div className='bgPart'>
          <figure>
            <img
              className='img-fluid'
              src='../../../../images/inplasslogo.svg'
            ></img>
          </figure>
        </div>
        <div className='formPart'>
          <h1>Confirm New Password</h1>

          <Form layout='vertical' validateTrigger='' onFinish={resetPassword}>
            <div className='row'>
              <div className='col-12'>
                <div className='form-group sign-field'>
                  <Form.Item
                    name='password'
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Enter your password',
                      },
                      {
                        pattern: patterns.password.regex,
                        message: patterns.password.message,
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder='New Password'
                      name='newPassword'
                      onChange={e => setNewPassword(e.target.value)}
                      value={newPassword}
                      maxLength='50'
                    />
                  </Form.Item>
                </div>
              </div>
              <div className='col-12'>
                <div className='form-group sign-field'>
                  <Form.Item
                    name='confirm'
                    dependencies={['password']}
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve()
                          }
                          return Promise.reject(
                            new Error(`The password entered doesn't match`)
                          )
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      placeholder='Confirm your password'
                      name='confirmPassword'
                      onChange={e => setConfirmPassword(e.target.value)}
                      value={confirmPassword}
                      maxLength='50'
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <Button
              htmlType='submit'
              className='continuebtn'
              block
              disabled={disabledBtn}
              loading={showLoader}
            >
              CONFIRM
            </Button>
          </Form>
          {showSuccessMessage && (
            <Alert
              message='Your Password has been changed successfully. Redirecting to sign-in'
              type='success'
              showIcon
              className='mt-2'
            />
          )}
          {errorMessage && (
            <Alert
              message={errorMessage}
              type='error'
              showIcon
              className='mt-2'
            />
          )}
        </div>
      </div>
    </>
  )
}

export default ResetPassword
