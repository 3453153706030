import React, { useState, useEffect } from 'react'
import { Button, Menu, Modal, Form, Input, message, Select } from 'antd'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import { db, storageRef, timestamp } from '../../config/firebase'
import Header from '../Common/Header/Header'
import SideMenu from '../Common/Sidemenu/Sidemenu'
import PageNamecard from '../Common/PageNameCard/Pagenamecard'
import 'react-accessible-accordion/dist/fancy-example.css'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

const { confirm } = Modal
const { Option } = Select

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  }
}

const FAQ = () => {
  const [selectedOption, setSelectedOption] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [question, setQuestion] = useState('')
  const [answer, setAnswer] = useState('')
  const [imageFile, setImageFile] = useState(null)
  const [selectedDepartment, setSelectedDepartment] = useState(null)
  const [loading, setLoading] = useState(false)
  const [faqData, setFaqData] = useState({})

  useEffect(() => {
    const unsubscribe = db.collection('faqRequests').onSnapshot(snapshot => {
      const faqData = {}
      snapshot.forEach(doc => {
        const data = doc.data()
        const { department } = data
        if (!faqData[department]) {
          faqData[department] = []
        }
        faqData[department].push({ id: doc.id, ...data })
      })
      setFaqData(faqData)
    })

    return () => {
      unsubscribe()
    }
  }, [])

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleAddFAQ = async () => {
    if (loading) return

    setLoading(true)

    try {
      let imageUrl = ''
      if (imageFile) {
        const imageFileName = `${Date.now()}_${imageFile.name}`
        const imageRef = storageRef.child(`faqImages/${imageFileName}`)
        const snapshot = await imageRef.put(imageFile)
        imageUrl = await snapshot.ref.getDownloadURL()
      }
      await db.collection('faqRequests').add({
        question,
        answer,
        imageUrl,
        department: selectedDepartment,
        timestamp: timestamp,
      })

      setQuestion('')
      setAnswer('')
      setImageFile(null)
      setSelectedDepartment(null)

      setIsModalVisible(false)
      setLoading(false)
      message.success('FAQ request submitted successfully!')
    } catch (error) {
      setLoading(false)
      message.error('Error submitting FAQ request: ' + error.message)
    }
  }

  const showDeleteConfirm = id => {
    confirm({
      title: 'Are you sure you want to delete this FAQ?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteFAQ(id)
      },
    })
  }

  const handleDeleteFAQ = async id => {
    try {
      await db.collection('faqRequests').doc(id).delete()
      message.success('FAQ deleted successfully!')
    } catch (error) {
      console.error('Error deleting FAQ:', error)
      message.error('Failed to delete FAQ')
    }
  }

  const handleDepartmentChange = value => {
    setSelectedDepartment(value)
  }

  const items = [
    // getItem('Front Desk', 'frontdesk'),
    // getItem('Room Service', 'roomservice'),
    // getItem('Call on Doctor', 'calldoctor'),
    getItem('DashBoard', 'dashboard'),
    getItem('Hotel Details', 'hoteldetails'),
    getItem('Departments', 'departments'),
    getItem('Guest Info', 'guestinfo'),
    getItem('Service Requests', 'servicerequests'),
    getItem('Reservations', 'reservations'),
    getItem('Dining', 'dining'),
    getItem('HouseKeeping', 'houseKeeping'),
    getItem('Locations', 'locations'),
    getItem('Title & Permissions', 'titles'),
    getItem('Staff', 'staff'),
    getItem('Reports', 'report'),
    getItem('Promotions', 'promotions'),
    getItem('T&C & Privacy Policys', 't&c'),
    getItem('Staff APP', 'staffapp'),
    getItem('Guest APP', 'guestapp'),
  ]

  const handleOptionClick = option => {
    setSelectedOption(option)
  }

  return (
    <>
      <Header />
      <SideMenu />
      <section className='mainContent department-wrp'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNamecard
                title='iNPLASS FAQ'
                breadcrumb={['Super Admin', 'FAQ']}
              />
            </div>
          </div>
          <div>
            <div
              className='faq-container'
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <Menu
                style={{
                  width: 200,
                }}
                defaultSelectedKeys={['frontdesk']}
                defaultOpenKeys={['frontdesk']}
                items={items}
                onClick={({ key }) => handleOptionClick(key)}
              />
              <div
                className='faq-content'
                style={{
                  flex: 1,
                  padding: '20px',
                  borderLeft: '1px solid #ccc',
                }}
              >
                <Accordion
                  allowMultipleExpanded={true}
                  allowZeroExpanded={true}
                >
                  {selectedOption &&
                    faqData &&
                    faqData[selectedOption] &&
                    faqData[selectedOption].map((faq, index) => (
                      <AccordionItem key={index}>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            {faq.question}
                            <DeleteOutlined
                              style={{
                                float: 'right',
                                cursor: 'pointer',
                              }}
                              onClick={() => showDeleteConfirm(faq.id)}
                            />
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>{faq.answer}</p>
                          {faq.imageUrl && (
                            <img
                              src={faq.imageUrl} 
                              alt=''                  
                              style={{ height: '200px', width: '400px' }}
                            />
                          )}
                        </AccordionItemPanel>
                      </AccordionItem>
                    ))}
                </Accordion>
              </div>

              <div className='tablefilter-wrp'>
                <div className='form-row justify-content-end'>
                  <div className='col-4 col-md-auto'>
                    <Button className='cmnBtn' onClick={showModal}>
                      Add FAQ
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        title='Add FAQ'
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button className='grayBtn' key='back' onClick={handleCancel}>
            Cancel
          </Button>,

          <Button
            className='blueBtn ml-4'
            key='submit'
            htmlType='submit'
            onClick={handleAddFAQ}
            loading={loading}
          >
            Submit
          </Button>,
        ]}
      >
        <Form layout='vertical'>
          <Form.Item label='Question'>
            <Input
              value={question}
              onChange={e => setQuestion(e.target.value)}
            />
          </Form.Item>
          <Form.Item label='Answer'>
            <Input.TextArea
              value={answer}
              onChange={e => setAnswer(e.target.value)}
            />
          </Form.Item>
          <Form.Item label='Upload Image'>
            <input
              type='file'
              accept='image/*'
              onChange={e => {
                const image = e.target.files[0]
                setImageFile(image)
              }}
            />
            <Button>Upload</Button>

            {imageFile && (
              <div id='faqimg'>
                <img
                  src={URL.createObjectURL(imageFile)}
                  alt='Uploaded Image Preview'
                  style={{ maxWidth: '100%', marginTop: '10px' }}
                />
              </div>
            )}
          </Form.Item>
          <Form.Item label='Department'>
            <Select
              style={{ width: '100%' }}
              placeholder='Select department'
              value={selectedDepartment}
              onChange={handleDepartmentChange}
            >
              <Option value='dashboard'>DashBoard</Option>
              <Option value='hoteldetails'>Hotel Details</Option>
              <Option value='departments'>Departments</Option>
              <Option value='guestinfo'>Guest Info</Option>
              <Option value='servicerequests'>Service Requests</Option>
              <Option value='dining'>Dining</Option>
              <Option value='locations'>Locations</Option>
              <Option value='reservations'>Reservations</Option>
              <Option value='titles'>Titles</Option>
              <Option value='staff'>Staff</Option>
              <Option value='report'>Report</Option>
              <Option value='promotions'>Promotions</Option>
              <Option value='houseKeeping'>HouseKeeping</Option>
              <Option value='t&c'>T&C & Privacy Policys</Option>
              <Option value='staffapp'>Staff APP</Option>
              <Option value='guestapp'>Guest APP</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default FAQ
