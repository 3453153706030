import React, { useEffect, useState } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { useSelector } from 'react-redux'

const DefaultState = {
  labels: ['', ''],
  datasets: [
    {
      label: 'Rainfall',
      backgroundColor: ['#E7E7E7', '#0BAE36'],
      hoverBackgroundColor: ['#E7E7E7', '#0BAE36'],
      data: [21, 79],
      borderWidth: 0,
    },
  ],
}

const DefaultState2 = {
  labels: ['', ''],
  datasets: [
    {
      label: 'Rainfall',
      backgroundColor: ['#E7E7E7', '#FF1616'],
      hoverBackgroundColor: ['#E7E7E7', '#FF1616'],
      data: [79, 21],
      borderWidth: 0,
    },
  ],
}

const GetData = data => {
  const { activeHotelPercentage, inActiveHotelPercentage } = data

  const st = { ...DefaultState }
  st.datasets[0].data = [inActiveHotelPercentage, activeHotelPercentage]

  const st2 = { ...DefaultState2 }
  st2.datasets[0].data = [activeHotelPercentage, inActiveHotelPercentage]

  return [st, st2]
}

const ActivityCard = () => {
  const { activeHotelPercentage, inActiveHotelPercentage } = useSelector(state => state)

  const [state1, setState1] = useState(null)
  const [state2, setState2] = useState(null)

  useEffect(() => {
    const [st, st2] = GetData({
      activeHotelPercentage,
      inActiveHotelPercentage,
    })
    setState1(st)
    setState2(st2)
  }, [activeHotelPercentage, inActiveHotelPercentage])

  if (!state1 || !state2) return null

  return (
    <>

      <div className='ActivityCard-wrp cmnCard-wrp'>
        <div className='cardHead'>
          <h3>Hotels</h3>
        </div>
        <div className='cardBody'>
          <div className='row'>
            <div className='col-12 col-sm-6 text-center'>
              <div className='chart-wrp '>
                <Doughnut
                  data={state1}
                  options={{
                    title: {
                      display: false,
                      fontSize: 14,
                    },
                    legend: {
                      display: false,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    cutoutPercentage: 70,
                  }}
                />
                <span>{isNaN(activeHotelPercentage) ? 0 : activeHotelPercentage}%</span>
              </div>
              <h6>Active hotels</h6>
            </div>
            <div className='col-12 col-sm-6 text-center'>
              <div className='chart-wrp'>
                <Doughnut
                  data={state2}
                  options={{
                    title: {
                      display: false,
                      fontSize: 14,
                    },
                    legend: {
                      display: false,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    cutoutPercentage: 70,
                  }}
                />
                <span>{isNaN(inActiveHotelPercentage) ? 0 : inActiveHotelPercentage}%</span>
              </div>
              <h6>Inactive hotels</h6>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ActivityCard
