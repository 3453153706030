/* eslint-disable jsx-a11y/alt-text */
import { Button } from 'antd'
import React from 'react'
import { useHistory } from 'react-router'

const LinkExpired = () => {
  const history = useHistory()

  const handleButtonClick = () => {
    history.push('/')
  }

  return (
    <>
      <div className='formLayout'>
        <div className='signin-wrp forgotpassword-wrp'>
          <div className='bgPart'>
            <figure>
              <img className='img-fluid' src='../../../../images/inplasslogo.svg'></img>
            </figure>
          </div>
          <div className='formPart'>
            <h3>Sorry :(</h3>
            <h6 style={{ marginBottom: '25px' }}>
              <p>The password link has expired</p>
            </h6>
            <Button
              htmlType='button'
              className='continuebtn'
              onClick={handleButtonClick}
              style={{ width: '190px' }}
            >
              Go Back To Sign In
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default LinkExpired
