import { actions } from '../app/store'
import { Collections } from '../config/constants'
import { db } from '../config/firebase'

let unsubMaintenanceListner = null
export const MaintenanceListerner = dispatch => {
  try {
    if (unsubMaintenanceListner) return
    unsubMaintenanceListner = db
      .collection(Collections.RELEASE)
      .onSnapshot(snapshot => {
        let enableStickyBar = false
        let showMaintenancePage = false
        let fromDate = null,
          toDate = null
        for (const doc of snapshot.docs) {
          const data = doc.data()
          if (data.enableStickyBar) enableStickyBar = true
          if (data.showMaintenancePage) showMaintenancePage = true
          if (enableStickyBar || showMaintenancePage) {
            fromDate = data.fromDate.toDate()
            toDate = data.toDate.toDate()
            break
          }
        }
        dispatch(
          actions.setProduction({
            enableStickyBar,
            showMaintenancePage,
            fromDate,
            toDate,
          })
        )
      })
  } catch (error) {
    console.log({ error })
  }
}
