/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react'
import { Logout } from '../../../services/user'

const Maintanance = () => {
  useEffect(() => {
    Logout()
  }, [])

  return (
    <>
      <div className='maintenance-wrp'>
        <div>
          <figure>
            <img className='img-fluid' src='images/maintenance.svg'></img>
          </figure>
          <h1>Website under maintenance</h1>
          <h3>
            Inplass web and mobile applications will not be available during
            this time. Our apology for the inconvenience.
          </h3>
        </div>
      </div>
    </>
  )
}

export default Maintanance
